import { Formik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useMemo } from 'react';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { useTeam } from '@/app/team/context/TeamContext';
import { getDisplayError } from '../../../utils/get-display-error';
import { InputField } from '../../../components/input/InputField';
import { Button } from '../../../components/button/Button';
import { LanguageSelectField } from '../../../components/LanguageSelect';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type {
  BodyType as UpdateTeamBodyType,
  ResponseType as UpdateTeamResponseType,
} from '../endpoints/UpdateTeamEndpoint';

const updateTeamSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Required').required('Required'),
});

export const TeamSettingsPage = () => {
  const { team, refreshTeam } = useTeam();

  const initialValues = useMemo(() => {
    return {
      name: team.name,
      language: team.language,
    };
  }, [team]);

  return (
    <div className="page-content">
      <PageHeader title="Team Settings" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Settings',
              to: '..',
            },
            {
              name: 'Team Settings',
            },
          ]}
        />
      </div>

      <div className="card">
        <Formik
          initialValues={initialValues}
          validationSchema={updateTeamSchema}
          onSubmit={async (values) => {
            try {
              const payload: UpdateTeamBodyType = {
                teamId: team.id,
                data: {
                  name: values.name,
                  language: values.language,
                },
              };
              await fetchEndpointData<UpdateTeamResponseType>(`/api/v1/team/update`, {
                method: 'POST',
                body: payload,
              });
              refreshTeam();
              toast.success('Team updated');
            } catch (err: any) {
              toast.error('Could not update team: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <InputField labelText="Name" type="text" name="name" />

              <LanguageSelectField labelText="Language" name="language" />

              <Button type="submit" variant="primary" isDisabled={isSubmitting} isLoading={isSubmitting}>
                Update Team
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
