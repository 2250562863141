import { Formik } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useEffect, useId, useState } from 'react';
import useSWR from 'swr';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { Button } from '../../../components/button/Button';
import { InputField } from '../../../components/input/InputField';
import { PageHeader } from '../../../components/PageHeader';
import { getDisplayError } from '../../../utils/get-display-error';
import { useTeam } from '@/app/team/context/TeamContext';
import { Tag } from '../../../components/Tag';
import { LabeledCheckbox } from '../../../components/checkbox/LabeledCheckbox';
import { SpinnerBlock } from '../../../components/Spinner';
import { numberAwareTextSort } from '../../../utils/text';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { ResponseType as EvernoteIntegrationResponseType } from '../endpoints/evernote/EvernoteIntegrationEndpoint';
import { BodyType as UpdateSyncedEvernoteNotebooksPayload } from '../endpoints/evernote/UpdateSyncedEvernoteNotebooksEndpoint';
import { BodyType as RefreshEvernotePayload } from '../endpoints/evernote/RefreshEvernoteEndpoint';
import { BodyType as ConnectEvernotePayload } from '../endpoints/evernote/ConnectEvernoteEndpoint';

const linkEvernoteSchema = Yup.object().shape({
  noteStoreUrl: Yup.string().min(1, 'Required').required('Required'),
  shardId: Yup.string().min(1, 'Required').required('Required'),
  developerToken: Yup.string().min(1, 'Required').required('Required'),
});

export const EvernoteIntegrationPage = () => {
  const { team } = useTeam();
  const { data, isLoading, mutate } = useSWR<EvernoteIntegrationResponseType>(
    `/api/v1/integration/evernote/data/${team.id}`,
    fetchEndpointData,
  );
  const [isUpdatingSyncedNotebooks, setIsUpdatingSyncedNotebooks] = useState(false);

  const evernoteIntegration = data?.evernoteIntegration;
  const [syncedNotebooks, setSyncedNotebooks] = useState<Set<string>>(
    new Set(evernoteIntegration?.notebooks.filter((n) => n.isSynced).map((n) => n.id) ?? []),
  );

  useEffect(() => {
    if (evernoteIntegration) {
      setSyncedNotebooks(new Set(evernoteIntegration.notebooks.filter((n) => n.isSynced).map((n) => n.id)));
    }
  }, [evernoteIntegration]);

  const checkboxRootId = useId();

  if (!evernoteIntegration && isLoading) {
    return <SpinnerBlock message="Loading..." />;
  }

  return (
    <div className="page-content">
      <PageHeader title="Setup evernote integration" />

      <div>
        <div className="flex flex-wrap justify-between items-center mb-4">
          <Breadcrumb
            items={[
              {
                name: 'Integrations',
                to: '..',
              },
              {
                name: 'Evernote Integration',
              },
            ]}
          />

          <div>
            <Tag color={evernoteIntegration ? 'green' : 'red'}>{evernoteIntegration ? 'Linked' : 'Not Linked'}</Tag>
          </div>
        </div>

        <div className="card">
          <Formik
            initialValues={{
              noteStoreUrl: evernoteIntegration?.noteStoreUrl ?? '',
              shardId: evernoteIntegration?.shardId ?? '',
              developerToken: '',
            }}
            validationSchema={linkEvernoteSchema}
            onSubmit={async (values) => {
              try {
                const payload: ConnectEvernotePayload = {
                  noteStoreUrl: values.noteStoreUrl,
                  developerToken: values.developerToken,
                  shardId: values.shardId,
                  teamId: team.id,
                };
                await fetchEndpointData(`/api/v1/integration/evernote/connect`, {
                  method: 'POST',
                  body: payload,
                });
                mutate();
                toast.success('Evernote linked');
              } catch (err: any) {
                toast.error('Could not link evernote: ' + getDisplayError(err));
              }
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <InputField labelText="NoteStore URL" type="text" name="noteStoreUrl" />

                <InputField labelText="Shard id" type="text" name="shardId" />

                <InputField labelText="Developer Token" type="password" name="developerToken" />

                <Button type="submit" variant="primary" isDisabled={isSubmitting} isLoading={isSubmitting}>
                  {evernoteIntegration ? 'Update Evernote Connection' : 'Link Evernote'}
                </Button>
              </form>
            )}
          </Formik>
        </div>

        {evernoteIntegration && (
          <div className="card my-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="heading-two">Synced Notebooks</h2>

              <Button
                onTrigger={async () => {
                  setIsUpdatingSyncedNotebooks(true);
                  try {
                    const payload: RefreshEvernotePayload = {
                      teamId: team.id,
                    };
                    await fetchEndpointData(`/api/v1/integration/evernote/refresh`, {
                      method: 'POST',
                      body: payload,
                    });
                    mutate();
                    toast.success('Updated evernote notebooks');
                  } catch (err) {
                    toast.error('Could not refresh synced notebooks: ' + getDisplayError(err));
                  }
                  setIsUpdatingSyncedNotebooks(false);
                }}
                isLoading={isUpdatingSyncedNotebooks}
              >
                Refresh Notebooks
              </Button>
            </div>

            <div className="grid lg:grid-cols-3">
              {evernoteIntegration.notebooks
                .sort((a, b) => numberAwareTextSort(a.name, b.name))
                .map((notebook) => {
                  return (
                    <LabeledCheckbox
                      id={`${checkboxRootId}-${notebook.id}`}
                      labelText={notebook.name}
                      isChecked={syncedNotebooks.has(notebook.id)}
                      onChange={(v) => {
                        if (v) {
                          setSyncedNotebooks((prev) => {
                            prev.add(notebook.id);
                            return new Set(prev);
                          });
                        } else {
                          setSyncedNotebooks((prev) => {
                            prev.delete(notebook.id);
                            return new Set(prev);
                          });
                        }
                      }}
                    />
                  );
                })}
            </div>

            <div className="mt-4">
              <Button
                onTrigger={async () => {
                  setIsUpdatingSyncedNotebooks(true);
                  try {
                    const payload: UpdateSyncedEvernoteNotebooksPayload = {
                      teamId: team.id,
                      notebookIds: Array.from(syncedNotebooks),
                    };
                    await fetchEndpointData(`/api/v1/integration/evernote/update-synced-notebooks`, {
                      method: 'POST',
                      body: payload,
                    });
                    mutate();
                    toast.success('Synced notebooks updated');
                  } catch (err) {
                    toast.error('Could not update synced notebooks: ' + getDisplayError(err));
                  }
                  setIsUpdatingSyncedNotebooks(false);
                }}
                isLoading={isUpdatingSyncedNotebooks}
              >
                Update Synced Notebooks
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
