import React from 'react';

import { FormDialog } from '../../../components/dialog/FormDialog';
import { InputField } from '../../../components/input/InputField';
import { CategorySelectField } from '../components/CategorySelect';

interface ICategoryFormValues {
  name: string;
  parentCategoryId: string | null;
}

interface ICategoryFormProps {
  isOpen?: boolean;
  setIsOpen?: (newIsOpen: boolean) => void;
  title: string;
  submitText: string;
  onSubmit: (value: ICategoryFormValues) => Promise<void>;
  excludedCategoryIds?: string[];
  initialValues?: {
    name: string;
    parentCategoryId: string | null;
  };
}

export const CategoryFormDialog: React.FC<ICategoryFormProps> = (props) => {
  const { isOpen, setIsOpen, title, submitText, excludedCategoryIds, initialValues, onSubmit } = props;

  return (
    <FormDialog
      open={isOpen}
      setOpen={setIsOpen}
      showTrigger={false}
      triggerText=""
      title={title}
      submitText={submitText}
      onSubmit={onSubmit}
      initialValues={
        initialValues ?? {
          name: '',
          parentCategoryId: null,
        }
      }
    >
      <CategorySelectField
        labelText="Parent category"
        name="parentCategoryId"
        excludedCategoryIds={excludedCategoryIds}
      />

      <InputField type="text" labelText="Name" name="name" />
    </FormDialog>
  );
};
