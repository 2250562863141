import * as Yup from 'yup';
import { Formik } from 'formik';
import { useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { Button } from '../../../components/button/Button';
import { useTeam } from '@/app/team/context/TeamContext';
import { getDisplayError } from '../../../utils/get-display-error';
import { InputField } from '../../../components/input/InputField';
import { useExplorerTree } from '../../explorerTree/contexts/ExplorerContext';
import type {
  ResponseType as CreateDocumentCollectionResponseType,
  BodyType as CreateDocumentCollectionPayload,
} from '../endpoints/CreateDocumentCollectionEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';

const createDocumentCollectionSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
});

export const CreateDocumentCollectionPage = () => {
  const navigate = useNavigate();
  const { team } = useTeam();
  const { collectionId } = useParams<{ collectionId: string }>();
  const { tree: explorerTree } = useExplorerTree();

  const collectionNode = collectionId ? explorerTree.getCollectionNode(collectionId) : null;
  const collectionsPath = useResolvedPath('..');
  return (
    <div className="page-content">
      <PageHeader title="Create Document Collection" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: collectionNode?.name ?? 'Document Collections',
              to: collectionsPath.pathname,
            },
            {
              name: 'Create',
            },
          ]}
        />
      </div>

      <div className="card">
        <Formik
          initialValues={{
            name: '',
          }}
          validationSchema={createDocumentCollectionSchema}
          onSubmit={async (values) => {
            try {
              const payload: CreateDocumentCollectionPayload = {
                teamId: team.id,
                data: {
                  name: values.name,
                  parentCollectionId: collectionId ?? null,
                },
              };
              await fetchEndpointData<CreateDocumentCollectionResponseType>(`/api/v1/document-collection/create`, {
                method: 'POST',
                body: payload,
              });
              navigate('..');
              toast.success('Collection created');
            } catch (err: any) {
              toast.error('Could not create collection: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <InputField labelText="Name" type="text" name="name" />

              <Button variant="primary" type="submit" isDisabled={isSubmitting} isLoading={isSubmitting}>
                Create Document Collection
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
