import * as React from 'react';

import createContext from '../../../contexts/create-context';
import { useTeam } from '@/app/team/context/TeamContext';
import { ExplorerTree, getExplorerTreeInstance } from '../explorer-tree';

interface ProviderValue {
  tree: ExplorerTree;
  isSyncing: boolean;
  treeKey: number;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface ExplorerTreeProviderProps {
  children?: React.ReactNode;
}

export const ExplorerTreeProvider: React.FC<ExplorerTreeProviderProps> = (props) => {
  const { children } = props;
  const { team } = useTeam();
  const tree = getExplorerTreeInstance(team.id);
  const [treeKey, setTreeKey] = React.useState(0);
  const [isSyncing, setIsSyncing] = React.useState(tree.isSyncing);

  React.useEffect(() => {
    const disposable = tree.onTreeChange(() => {
      setTreeKey((prev) => prev + 1);
    });
    const disposableTwo = tree.onSyncStateChange((_newIsSyncing) => {
      setIsSyncing(_newIsSyncing);
    });
    return () => {
      disposable.dispose();
      disposableTwo.dispose();
    };
  }, [tree]);

  return <ReactProvider value={{ tree, isSyncing, treeKey }}>{children}</ReactProvider>;
};

export const useExplorerTree = useContext;
export const ExplorerTreeConsumer = ReactConsumer;
