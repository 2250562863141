// !!! This file is used both client and server side !!!

import { CustomError } from '../error/CustomError';
import { ErrorCode } from '../error/ErrorCodes';

export enum QuestionsPresetType {
  Global = 'Global',
  Company = 'Company',
  Document = 'Document',
}

export function getQuestionsPresetType(type: string): QuestionsPresetType {
  switch (type.toLowerCase().trim()) {
    case 'global':
      return QuestionsPresetType.Global;
    case 'company':
      return QuestionsPresetType.Company;
    case 'document':
      return QuestionsPresetType.Document;
    default:
      throw new CustomError('Invalid QuestionsPresetType', ErrorCode.VALIDATION_ERROR);
  }
}
