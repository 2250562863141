import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumb } from '../../../../components/Breadcrumb';
import { PageHeader } from '../../../../components/PageHeader';
import { useTeam } from '@/app/team/context/TeamContext';
import { Table } from '../../../../components/table/Table';
import { formatDateTime } from '../../../../utils/date';
import { useExplorerTree } from '../../../explorerTree/contexts/ExplorerContext';

const PROCESSING_QUEUE_HEADERS = [
  {
    id: 'id',
    name: 'Title',
  },
  {
    id: 'indexingStatus',
    name: 'Status',
  },
  {
    id: 'createdAt',
    name: 'Created at',
  },
];

export const ProcessingQueuePage = () => {
  const { team } = useTeam();
  const { tree: explorerTree } = useExplorerTree();
  const [pendingDocs, setPendingDocs] = useState(() => {
    return explorerTree.getPendingDocuments();
  });

  useEffect(() => {
    const disposable = explorerTree.onTreeChange(() => {
      setPendingDocs(explorerTree.getPendingDocuments());
    });
    setPendingDocs(explorerTree.getPendingDocuments());

    return () => {
      disposable.dispose();
    };
  }, [explorerTree]);

  const title = 'Document Processing Queue';
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={[
            {
              name: title,
            },
          ]}
        />
      </div>

      <div>
        {pendingDocs.length <= 0 && <div>No pending documents</div>}

        {pendingDocs.length > 0 && (
          <div>
            <Table
              idKey="id"
              headers={PROCESSING_QUEUE_HEADERS}
              data={pendingDocs}
              mapData={(entry) => {
                return [
                  <Link
                    to={`/app/t/${team.id}/documents/${entry.parentCollectionId}/${entry.document?.id}/`}
                    className="w-full hover:text-blue-200"
                  >
                    {entry.name}
                  </Link>,
                  entry.document?.indexingStatus,
                  formatDateTime(entry.document?.createdAt),
                ];
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
