import { useField } from 'formik';
import { useId } from 'react';

import { InputWrapper } from '../InputWrapper';
import { Input } from './Input';

export interface IInputFieldProps {
  labelText: string;
  name: string;
  helperText?: string;
  type?: 'email' | 'text' | 'password' | 'number' | 'date' | 'time';
  isDisabled?: boolean;
  step?: string;
  placeholder?: string;
  autoComplete?: string;
}

export const InputField: React.FC<IInputFieldProps> = (props) => {
  const { name, type = 'text', labelText, helperText, isDisabled, step, placeholder, autoComplete } = props;
  const id = useId();
  const [field, meta, helpers] = useField({ name });

  return (
    <InputWrapper labelText={labelText} invalidText={meta.touched ? meta.error : undefined} helperText={helperText}>
      <Input
        value={field.value}
        onChange={(val) => {
          helpers.setValue(val);
        }}
        onBlur={(evt) => {
          helpers.setTouched(true);
          field.onBlur(evt);
        }}
        id={id}
        type={type}
        step={step}
        isInvalid={Boolean(meta.touched && meta.error)}
        isDisabled={isDisabled}
        placeholder={placeholder}
        autoComplete={autoComplete ?? 'off'}
      />
    </InputWrapper>
  );
};
