import { useCallback, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { ITableHeader, Table } from '../../../components/table/Table';
import { useTeam } from '@/app/team/context/TeamContext';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { PageHeader } from '../../../components/PageHeader';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { LinkButton } from '../../../components/button/Button';
import { formatDateTime } from '../../../utils/date';
import { SpinnerBlock } from '../../../components/Spinner';
import { Pagination } from '../../../components/Pagination';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type { ResponseType as WorkspacesResponseType } from '../endpoints/WorkspacesEndpoint';

const WORKSPACES_TABLE_HEADERS: ITableHeader[] = [
  {
    id: 'id',
    name: 'Name',
  },
  {
    id: 'creator',
    name: 'Creator',
  },
  {
    id: 'createdAt',
    name: 'Created at',
  },
];

export const WorkspacesPage = () => {
  const { team } = useTeam();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const query = new URLSearchParams();
      query.set('teamId', team.id);
      if (variables.cursor) {
        query.set('id', variables.cursor);
      }
      query.set('take', variables.take.toString());
      const result = await fetchEndpointData<WorkspacesResponseType>(`/api/v1/workspace/list?${query.toString()}`);
      return result.workspaces;
    },
    [team.id],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 40,
    cursor: searchParams.get('pageCursor'),
    fetcher: pageFetcher,
  });

  const pageCursor = page.variables.cursor;
  useEffect(() => {
    if (!pageCursor) return;

    setSearchParams((prev) => {
      prev.set('pageCursor', pageCursor);
      return prev;
    });
  }, [pageCursor]);

  const history = page.data ?? [];
  const title = 'Workspaces';
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={[
            {
              name: title,
            },
          ]}
        />

        <div>
          <LinkButton to="new">Create workspace</LinkButton>
        </div>
      </div>

      {!!history.length && (
        <Table
          idKey="id"
          headers={WORKSPACES_TABLE_HEADERS}
          data={history}
          mapData={(entry) => {
            return [
              <Link to={entry.id} className="w-full hover:text-blue-200">
                {entry.name}
              </Link>,
              '-',
              formatDateTime(entry.createdAt),
            ];
          }}
        />
      )}

      {!history.length && !page.isFetching && (
        <div className="card">
          <div>No previous workspaces found.</div>
        </div>
      )}

      {!history.length && page.isFetching && (
        <div>
          <SpinnerBlock message="Loading..." className="h-screen" />
        </div>
      )}

      <div className="py-4">
        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </div>
  );
};
