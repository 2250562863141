import { useMemo, useState } from 'react';
import * as docx from 'docx';

import { ChatMessageText, IDocumentDict } from '../../../../../document/pages/chat/ChatMessage';
import { ReferenceDialog } from './ReferenceDialog';
import { WorkspacePresetRun } from './types';
import { Table } from '@/components/table/Table';
import { SpinnerBlock } from '@/components/Spinner';
import { PresetRunStatus } from '../../../../enums';
import { useExplorerTree } from '@/app/explorerTree/contexts/ExplorerContext';

export type GroupedAnswersType = Record<
  string,
  {
    answer: string;
    minifiedAnswer: string;
    documents: {
      id: string;
      answer: string;
      minifiedAnswer: string;
      doc: { id: string; name: string; collectionId?: string | null };
    }[];
  }
>;

export function downloadAnswersAsDocx(groupedAnswers: GroupedAnswersType) {
  const children: any[] = [];

  const table = new docx.Table({
    rows: [...Object.entries(groupedAnswers)].map(([question, answer]) => {
      return new docx.TableRow({
        children: [
          new docx.TableCell({
            children: [new docx.Paragraph({ text: question })],
            width: { size: 50, type: docx.WidthType.PERCENTAGE },
          }),
          new docx.TableCell({
            children: answer.minifiedAnswer.split('\n').map((v) => {
              return new docx.Paragraph({ text: v });
            }),
            width: { size: 50, type: docx.WidthType.PERCENTAGE },
          }),
        ],
      });
    }),
    width: { size: 100, type: docx.WidthType.PERCENTAGE },
    columnWidths: [50, 50],
    layout: docx.TableLayoutType.FIXED,
  });

  children.push(table);

  const doc = new docx.Document({
    creator: 'Jurimesh',
    sections: [
      {
        children,
      },
    ],
  });

  docx.Packer.toBlob(doc).then((blob) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'global-preset-results.docx';
    link.click();
  });
}

const TABLE_HEADERS = [
  {
    id: 'question',
    name: 'Question',
  },
  {
    id: 'answer',
    name: 'Answer',
  },
];

interface IRemappedRef {
  refId: number;
  documentId: string;
}

function processSummary(summary: string, references: IRemappedRef[]): string {
  let result = '' + summary;
  for (const ref of references) {
    result = result.replace(`[ref#${ref.documentId}]`, `[ref#${ref.refId}]`);
  }
  return result;
}

export interface IAggregatedAnswersResultsProps {
  presetRun: WorkspacePresetRun;
  showMinified: boolean;
}

export function useGroupedAnswers(presetRun: WorkspacePresetRun) {
  const grouped = useMemo(() => {
    const answers: GroupedAnswersType = {};
    for (const question of presetRun.questions) {
      for (const answer of question.answers) {
        answers[question.name] = {
          answer: answer.summary ?? '',
          minifiedAnswer: answer.minifiedSummary ?? '',
          documents: question.documentAnswers.map((d) => {
            return {
              id: d.id,
              answer: d.answer ?? '',
              minifiedAnswer: d.minifiedAnswer ?? '',
              doc: d.document,
            };
          }),
        };
      }
    }
    return answers;
  }, [presetRun]);

  return grouped;
}

export const AggregatedAnswersResults: React.FC<IAggregatedAnswersResultsProps> = (props) => {
  const { presetRun, showMinified } = props;
  const [openReference, setOpenReference] = useState<string | null>(null);
  const grouped = useGroupedAnswers(presetRun);
  const { tree, treeKey } = useExplorerTree();

  const totalDocuments = useMemo(() => {
    const node = tree.getCollectionNode(presetRun.collectionId);
    if (node) {
      return tree.countDocuments(node.id, true);
    } else {
      return 0;
    }
  }, [tree, treeKey]);

  const { totalQuestionCount, answeredQuestionCount, pendingDocumentCount } = useMemo(() => {
    let pendingDocuments = new Set<string>();
    let totalQuestionCount = 0;
    let answeredQuestionCount = 0;
    for (const question of presetRun.questions) {
      for (const answer of question.documentAnswers) {
        totalQuestionCount++;
        if (answer.isRelevant != null) {
          answeredQuestionCount++;
        } else {
          pendingDocuments.add(answer.document.id);
        }
      }
    }
    return {
      totalQuestionCount,
      answeredQuestionCount,
      pendingDocumentCount: pendingDocuments.size,
    };
  }, [presetRun]);

  const answerCount = Object.keys(grouped).length;
  const isPending = presetRun.status === PresetRunStatus.Running;
  const isCanceled = presetRun.status === PresetRunStatus.Canceled;
  return (
    <div className="grid gap-4">
      {isPending && answerCount === 0 && (
        <SpinnerBlock
          message={
            answeredQuestionCount === totalQuestionCount
              ? 'Aggregating answers...'
              : `Analysing documents ${totalDocuments - pendingDocumentCount}/${totalDocuments}...`
          }
        />
      )}

      {!isPending && answerCount === 0 && (
        <div className="text-center text-gray-600">
          {isCanceled ? 'Preset run canceled.' : 'Empty preset run results, documents have probably been removed.'}
        </div>
      )}

      {answerCount > 0 && (
        <Table
          idKey="id"
          headers={TABLE_HEADERS}
          data={Object.entries(grouped)}
          mapData={([answerName, value]) => {
            const refPrefix = `${answerName.toLowerCase().replace(/[^a-z]/g, '')}::`;
            const documentRefs: IDocumentDict = {};
            const references = value.documents.map((doc, docIdx) => {
              documentRefs[doc.doc.id] = {
                name: doc.doc.name,
              };

              return {
                refId: docIdx,
                documentId: doc.doc.id,
              };
            });

            return [
              <div>{answerName}</div>,
              <div>
                {value.documents.map((doc) => {
                  const key = `${refPrefix}${doc.doc.id}`;

                  return (
                    <ReferenceDialog
                      key={key}
                      documentAnswer={`${doc.answer}\n${doc.minifiedAnswer}`}
                      documentAnswerId={doc.id}
                      document={{
                        id: doc.doc.id,
                        name: doc.doc.name,
                        collectionId: doc.doc.collectionId ?? 'ROOT',
                      }}
                      isOpen={openReference === key}
                      onOpenChange={(isOpen) => {
                        if (!isOpen) {
                          setOpenReference(null);
                        } else {
                          setOpenReference(key);
                        }
                      }}
                    />
                  );
                })}

                <ChatMessageText
                  content={processSummary(showMinified ? value.minifiedAnswer : value.answer, references)}
                  references={references}
                  documents={documentRefs}
                  openRef={(refId) => {
                    const foundDocumentId = references.find((v) => v.refId === refId)?.documentId;
                    if (foundDocumentId) {
                      setOpenReference(`${refPrefix}${foundDocumentId}`);
                    }
                  }}
                />
              </div>,
            ];
          }}
        />
      )}
    </div>
  );
};
