import { PlusIcon } from 'lucide-react';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { LinkButton } from '../../../components/button/Button';
import { DocumentExplorer } from '../../document/components/DocumentExplorer';
import { AuthRoles } from '../../user/auth-roles';
import { useRole } from '../../../hooks/useRole';

export const DocumentCollectionsPage = () => {
  const userRole = useRole();

  return (
    <div className="page-content">
      <PageHeader title="Document Collections" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Document Collections',
            },
          ]}
        />

        <div className="flex gap-2">
          {userRole >= AuthRoles.Editor && (
            <LinkButton to="create-collection" title="Add Collection">
              <PlusIcon className="button-icon" />
            </LinkButton>
          )}
        </div>
      </div>

      <DocumentExplorer parentCollectionId={null} canUploadDocuments={false} isReadOnly={userRole < AuthRoles.Editor} />
    </div>
  );
};
