import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { AuthProvider } from './contexts/auth-context';
import { WebsocketProvider } from './contexts/websocket-context';

import { LoginPage } from './app/auth/pages/Login';
import { CreateTeamPage } from './app/team/pages/CreateTeam';
import { TeamsPage } from './app/team/pages/Teams';
import { TeamWrapper } from './app/team/pages/TeamWrapper';
import { DocumentCollectionsPage } from './app/documentCollection/pages/DocumentCollectionsPage';
import { CreateDocumentCollectionPage } from './app/documentCollection/pages/CreateDocumentCollection';
import { DocumentsPage } from './app/document/pages/DocumentsPage';
import { ChatDocumentsPage } from './app/document/pages/chat/ChatPage';
import { DocumentPage } from './app/document/pages/DocumentPage';
import { UsersPage } from './app/user/pages/Users';
import { AddUserPage } from './app/user/pages/AddUser';
import { SettingsPage } from './app/settings/pages/SettingsPage';
import { IntegrationsPage } from './app/integration/pages/IntegrationsPage';
import { EvernoteIntegrationPage } from './app/integration/pages/EvernoteIntegrationPage';
import { UploadDocumentsPage } from './app/document/pages/UploadPage';
import { ChatHistoryPage } from './app/document/pages/chat/ChatHistory';
import { UserNotFoundPage } from './app/auth/pages/UserNotFound';
import { TeamSettingsPage } from './app/team/pages/TeamSettings';
import { MyProfilePage } from './app/user/pages/MyProfile';
import { UsagePage } from './app/usage/pages/Usage';
import { InternalTeamsPage } from './app/team/pages/InternalTeamsPage';
import { InternalTeamPage } from './app/team/pages/InternalTeamPage';
import { UserDashboard } from './components/page-templates/UserDashboard';
import { TeamHomePage } from './app/home/pages/TeamHomePage';
import { ProcessingQueuePage } from './app/document/pages/processing-queue/ProcessingQueuePage';
import { WorkspacesPage } from './app/workspace/pages/WorkspacesPage';
import { CreateWorkspacePage } from './app/workspace/pages/CreateWorkspacePage';
import { WorkspacePage } from './app/workspace/pages/workspace/WorkspacePage';
import { WorkspaceDocumentsPage } from './app/workspace/pages/workspace/WorkspaceDocuments';
import { WorkspaceCompaniesPage } from './app/workspace/pages/workspace/WorkspaceCompaniesPage';
import { PresetsPage } from './app/questionsPreset/pages/PresetsPage';
import { CreatePresetPage } from './app/questionsPreset/pages/CreatePresetPage';
import { UpdatePresetPage } from './app/questionsPreset/pages/UpdatePresetPage';
import { WorkspacePresetRunsPage } from './app/workspace/pages/workspace/preset-runs/WorkspacePresetRunsPage';
import { PresetRunPage } from './app/workspace/pages/workspace/preset-runs/preset-run/WorkspacePresetRunPage';
import { ApiKeysPage } from './app/apiKey/pages/ApiKeysPage';
import { CreateApiKeyPage } from './app/apiKey/pages/CreateApiKeyPage';
import { KnowlexIntegrationPage } from './app/integration/pages/KnowlexIntegrationPage';
import { CategoriesPage } from './app/category/pages/CategoriesPage';
import { DocumentInsightsPage } from './app/insight/pages/DocumentInsightsPage';
import { ChatRedirectComponent } from './app/document/pages/chat/ChatRedirect';
import { WorkspaceOverviewPage } from './app/workspace/pages/workspace/WorkspaceOverviewPage';
import { LoginFailedPage } from './app/auth/pages/LoginFailed';

const AppWrapper = () => {
  return (
    <AuthProvider>
      <WebsocketProvider>
        <Outlet />
      </WebsocketProvider>
    </AuthProvider>
  );
};

const UserDashboardRoute = () => {
  return (
    <UserDashboard>
      <Outlet />
    </UserDashboard>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path="/app" element={<AppWrapper />}>
      <Route path="me" element={<UserDashboardRoute />}>
        <Route index element={<MyProfilePage />} />
      </Route>

      <Route path="internal" element={<UserDashboardRoute />}>
        <Route path="teams">
          <Route index element={<InternalTeamsPage />} />
          <Route path="new" element={<CreateTeamPage />} />
          <Route path=":internalTeamId" element={<InternalTeamPage />} />
        </Route>

        <Route index element={<Navigate to="teams" />} />
      </Route>

      <Route path="t">
        <Route path=":teamId" element={<TeamWrapper />}>
          <Route index element={<TeamHomePage />} />
          <Route path="usage" element={<UsagePage />} />
          <Route path="categories" element={<CategoriesPage />} />
          <Route path="settings">
            <Route path="team" element={<TeamSettingsPage />} />
            <Route path="users">
              <Route index element={<UsersPage />} />
              <Route path="add" element={<AddUserPage />} />
            </Route>
            <Route path="integrations">
              <Route index element={<IntegrationsPage />} />
              <Route path="evernote" element={<EvernoteIntegrationPage />} />
              <Route path="knowlex" element={<KnowlexIntegrationPage />} />
            </Route>
            <Route path="api-keys">
              <Route index element={<ApiKeysPage />} />
              <Route path="new" element={<CreateApiKeyPage />} />
            </Route>
            <Route index element={<SettingsPage />} />
          </Route>
          <Route path="chat">
            <Route index element={<ChatRedirectComponent />} />
            <Route path="new" element={<ChatRedirectComponent prefix="../" />} />
            <Route path="history" element={<ChatHistoryPage />} />
            <Route path=":chatId" element={<ChatDocumentsPage />} />
          </Route>
          <Route path="workspace">
            <Route index element={<WorkspacesPage />} />
            <Route path="new" element={<CreateWorkspacePage />} />
            <Route path=":workspaceId" element={<WorkspacePage />}>
              <Route index element={<Navigate to="overview" />} />
              <Route path="overview" element={<WorkspaceOverviewPage />} />
              <Route path="documents" element={<WorkspaceDocumentsPage />} />
              <Route path="companies" element={<WorkspaceCompaniesPage />} />
              <Route path="preset-runs">
                <Route index element={<WorkspacePresetRunsPage />} />
                <Route path=":presetRunId" element={<PresetRunPage />} />
              </Route>
            </Route>
          </Route>
          <Route path="questions-preset">
            <Route index element={<PresetsPage />} />
            <Route path="new" element={<CreatePresetPage />} />
            <Route path=":presetId" element={<UpdatePresetPage />} />
          </Route>
          <Route path="processing-queue" element={<ProcessingQueuePage />} />
          <Route path="insights" element={<DocumentInsightsPage />} />
          <Route path="documents">
            <Route index element={<DocumentCollectionsPage />} />
            <Route path="create-collection" element={<CreateDocumentCollectionPage />} />
            <Route path=":collectionId">
              <Route index element={<DocumentsPage />} />
              <Route path="upload" element={<UploadDocumentsPage />} />
              <Route path="create-collection" element={<CreateDocumentCollectionPage />} />
              <Route path=":documentId" element={<DocumentPage />} />
            </Route>
          </Route>
          <Route index path="*" element={<Navigate to="documents" replace={true} />} />
        </Route>
        <Route index path="*" element={<TeamsPage />} />
      </Route>
      <Route index path="*" element={<Navigate to="/app/t" replace={true} />} />
    </Route>,

    <Route path="/login" element={<LoginPage />} />,
    <Route path="/user-not-found" element={<UserNotFoundPage />} />,
    <Route path="/auth-failed" element={<LoginFailedPage />} />,

    <Route index element={<Navigate to="/app/t" />} />,

    // TODO: Add some nicer looking 404 page
    <Route
      path="*"
      element={
        <main className="p-2">
          <p>Page not found</p>
        </main>
      }
    />,
  ]),
);

export const AppRoutes = () => {
  return <RouterProvider router={router} />;
};
