import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import * as Yup from 'yup';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { Button } from '../../../components/button/Button';
import { InputField } from '../../../components/input/InputField';
import { PageHeader } from '../../../components/PageHeader';
import { getDisplayError } from '../../../utils/get-display-error';
import { useTeam } from '@/app/team/context/TeamContext';
import { SimpleSelectField } from '../../../components/select/SimpleSelectField';
import { ROLE_OPTIONS } from '../constants.client';
import { LanguageSelectField } from '../../../components/LanguageSelect';
import type { BodyType as AddUserPayload, ResponseType as AddUserResponseType } from '../endpoints/AddUserEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';

const addUserSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  email: Yup.string().email().required('Vereist'),
  role: Yup.mixed().required('Vereist'),
});

export const AddUserPage = () => {
  const navigate = useNavigate();
  const { team } = useTeam();

  const usersUrl = useResolvedPath('..');
  return (
    <div className="page-content">
      <PageHeader title="Add a user" />

      <div>
        <div className="mb-4">
          <Breadcrumb
            items={[
              {
                name: 'Users',
                to: usersUrl.pathname,
              },
              {
                name: 'Add a user',
              },
            ]}
          />
        </div>

        <div className="card">
          <Formik
            initialValues={{
              name: '',
              email: '',
              role: ROLE_OPTIONS[0]!,
              language: team.language,
            }}
            validationSchema={addUserSchema}
            onSubmit={async (values) => {
              try {
                const payload: AddUserPayload = {
                  teamId: team.id,
                  data: {
                    name: values.name,
                    email: values.email,
                    language: values.language,
                    role: +values.role.key,
                  },
                };
                await fetchEndpointData<AddUserResponseType>('/api/v1/user/add', {
                  method: 'POST',
                  body: payload,
                });
                navigate('..');
                toast.success('User has been added to the team');
              } catch (err: any) {
                toast.error('Could not add user to the team: ' + getDisplayError(err));
              }
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <InputField labelText="Name" type="text" name="name" />

                <InputField labelText="Email" type="email" name="email" />

                <LanguageSelectField labelText="Language" name="language" />

                <SimpleSelectField labelText="Role" name="role" items={ROLE_OPTIONS} />

                <Button type="submit" isDisabled={isSubmitting} isLoading={isSubmitting}>
                  Add user to the team
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
