import { ErrorCode } from './ErrorCodes';

export class CustomError extends Error {
  code: ErrorCode;

  constructor(msg: string, code: ErrorCode) {
    super(msg);
    this.code = code;
  }
}
