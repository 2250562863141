import { useEffect, useRef, useState } from 'react';

export function useSize<T = HTMLElement>(): [
  React.RefObject<T>,
  { x: number; y: number; width: number; height: number },
] {
  const ref = useRef<T>(null);
  const [size, setSize] = useState({ x: 0, y: 0, width: 0, height: 0 });

  function getSize() {
    if (ref.current) {
      return {
        // @ts-ignore
        x: ref.current.x || ref.current.offsetLeft,
        // @ts-ignore
        y: ref.current.y || ref.current.offsetTop,
        // @ts-ignore
        width: ref.current.offsetWidth,
        // @ts-ignore
        height: ref.current.offsetHeight,
      };
    }

    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };
  }

  useEffect(() => {
    if (!ref.current) return;

    setSize(getSize());

    const ro = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (!entry) return;

      const { x, y, width, height } = getSize();
      if (Math.abs(size.width - width) > 1 || Math.abs(size.height - height) > 1) {
        setSize({ x, y, width, height });
      }
    });

    // @ts-ignore
    ro.observe(ref.current);

    return () => {
      ro.disconnect();
    };
  }, []);

  return [ref, size];
}
