import { createId } from '@paralleldrive/cuid2';
import toast from 'react-hot-toast';

import { Emitter } from '../../../utils/emitter';
import { getClient } from '../../../contexts/websocket-context';
import { DisposableStore } from '../../../utils/disposable';

export class TeamState {
  private websocket = getClient();
  private subscribeDisposable = new DisposableStore();

  private reconnectHash = Date.now().toString(16);
  private reconnectEmitter = new Emitter<string>();
  public onReconnect = this.reconnectEmitter.event;

  private categoriesUpdateEmitter = new Emitter<string>();
  public onCategoriesUpdate = this.categoriesUpdateEmitter.event;

  private documentsUpdateEmitter = new Emitter<string>();
  public onDocumentsUpdate = this.documentsUpdateEmitter.event;

  private collectionsUpdateEmitter = new Emitter<string>();
  public onCollectionsUpdate = this.collectionsUpdateEmitter.event;

  private explorerEntriesUpdateEmitter = new Emitter<string>();
  public onExplorerEntriesUpdate = this.explorerEntriesUpdateEmitter.event;

  constructor(public teamId: string) {
    this.websocket.onConnect(() => {
      this.subscribe().catch((err) => {
        toast.error(err.message);
      });
    });

    this.subscribe().catch((err) => {
      toast.error(err.message);
    });
  }

  private updateReconnectHash(): void {
    this.reconnectHash = Date.now().toString(16);
    this.reconnectEmitter.fire(this.reconnectHash);

    // Update everything honestly...
    this.documentsUpdateEmitter.fire(this.reconnectHash);
    this.collectionsUpdateEmitter.fire(this.reconnectHash);
    this.categoriesUpdateEmitter.fire(this.reconnectHash);
    this.explorerEntriesUpdateEmitter.fire(this.reconnectHash);
  }

  private cleanupSubscription() {
    this.subscribeDisposable.dispose();
    this.subscribeDisposable = new DisposableStore();
  }

  private subscribe(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const msgRef = createId();
      let isResolved = false;

      this.subscribeDisposable.add(
        this.websocket.onMessage((message) => {
          if (message.ref === msgRef) {
            if (!isResolved && message.method === 'team/subscribe-ack') {
              resolve();
              this.updateReconnectHash();
              isResolved = true;
            }

            console.log(message);

            switch (message.method) {
              case 'team/category-update': {
                this.categoriesUpdateEmitter.fire(message.data.timestamp.toString(16));
                break;
              }
              case 'team/document-update': {
                this.documentsUpdateEmitter.fire(message.data.timestamp.toString(16));
                break;
              }
              case 'team/collection-update': {
                this.collectionsUpdateEmitter.fire(message.data.timestamp.toString(16));
                break;
              }
              case 'team/explorer-entry-update': {
                this.explorerEntriesUpdateEmitter.fire(message.data.timestamp.toString(16));
                break;
              }
            }
          }
        }),
      );
      this.subscribeDisposable.add(
        this.websocket.onErrorMessage((message) => {
          if (message.ref === msgRef) {
            if (!isResolved) {
              reject(new Error(message.error.message));
              isResolved = true;
            }
            this.cleanupSubscription();
          }
        }),
      );
      this.websocket.send({
        ref: msgRef,
        method: 'team/subscribe',
        data: {
          teamId: this.teamId,
          scopes: ['document-update', 'collection-update', 'explorer-entry-update', 'category-update'],
        },
      });
    });
  }
}

export class TeamStatesStore {
  states = new Map<string, TeamState>();

  getState(teamId: string): TeamState {
    let state = this.states.get(teamId);
    if (!state) {
      state = new TeamState(teamId);
      this.states.set(teamId, state);
    }
    return state;
  }
}

let _store: TeamStatesStore | null = null;
export function getTeamStates(): TeamStatesStore {
  if (!_store) {
    _store = new TeamStatesStore();
  }
  return _store;
}
