import { useEffect, useRef, useState } from 'react';

export function useThrottle(callback: () => void, key: string, ms: number) {
  const [lastSync, setLastSync] = useState(Date.now());
  const cbRef = useRef(callback);

  useEffect(() => {
    cbRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (Date.now() - lastSync > ms) {
      cbRef.current();
      setLastSync(Date.now());
    } else {
      const timeoutRef = setTimeout(() => {
        cbRef.current();
        setLastSync(Date.now());
      }, ms);
      return () => {
        clearTimeout(timeoutRef);
      };
    }
  }, [key, ms]);
}
