import { useMemo } from 'react';

import { useWorkspace } from './WorkspaceContext';
import { ProgressBar } from '../../../../components/ProgressBar';
import { Spinner, SpinnerBlock } from '../../../../components/Spinner';
import { DocumentTypeEnum } from '../../../document/enums';

export interface ICountsComponentProps {
  title: string;
  counts: Map<string, number>;
}

export const CountsComponent: React.FC<ICountsComponentProps> = (props) => {
  const { title, counts } = props;

  const sorted = useMemo(() => {
    return Array.from(counts.entries()).sort((a, b) => b[1] - a[1]);
  }, [counts]);

  const highest = sorted[0]?.[1] ?? 0;
  return (
    <div>
      <div className="font-medium mb-2">{title}</div>
      <div className="grid gap-2">
        {sorted.map(([key, value]) => {
          return (
            <div key={key}>
              <div className="flex justify-between">
                <div>{key}</div>
                <div>{value}</div>
              </div>

              <ProgressBar height={2} color="blue" progress={value} total={highest} pulse={false} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const WorkspaceOverviewPage = () => {
  const { processingState, documents, isFetching } = useWorkspace();

  const counts = useMemo(() => {
    const countPerType = new Map<string, number>();
    for (const doc of documents) {
      const documentType = doc?.document?.documentType ?? DocumentTypeEnum.Other;
      const newCount = (countPerType.get(documentType) ?? 0) + 1;
      countPerType.set(documentType, newCount);
    }

    const countPerCategory = new Map<string, number>();
    for (const doc of documents) {
      for (const category of doc?.document?.categories ?? []) {
        const categoryName = category.name;
        const newCount = (countPerCategory.get(categoryName) ?? 0) + 1;
        countPerCategory.set(categoryName, newCount);
      }
    }

    const countPerJurisdiction = new Map<string, number>();
    for (const doc of documents) {
      const jurisdiction = doc?.document?.jurisdiction ?? 'Unknown';
      const newCount = (countPerJurisdiction.get(jurisdiction) ?? 0) + 1;
      countPerJurisdiction.set(jurisdiction, newCount);
    }

    const countPerLanguage = new Map<string, number>();
    for (const doc of documents) {
      const lang = doc?.document?.language ?? 'Unknown';
      const newCount = (countPerLanguage.get(lang) ?? 0) + 1;
      countPerLanguage.set(lang, newCount);
    }

    return {
      totalCount: documents.length,
      countPerType,
      countPerCategory,
      countPerJurisdiction,
      countPerLanguage,
    };
  }, [documents]);

  return (
    <div>
      {processingState.processedCount < processingState.totalCount && (
        <div className="flex gap-1 items-center mb-4">
          <Spinner size={4} />
          Some documents are still being processed...
        </div>
      )}

      {!documents.length && isFetching && (
        <div>
          <SpinnerBlock message="Loading..." className="h-screen" />
        </div>
      )}

      <div className="grid gap-8 lg:grid-cols-2 xl:grid-cols-3">
        <CountsComponent title="Documents per type" counts={counts.countPerType} />
        <CountsComponent title="Documents per category" counts={counts.countPerCategory} />
        <CountsComponent title="Documents per jurisdiction" counts={counts.countPerJurisdiction} />
        <CountsComponent title="Documents per language" counts={counts.countPerLanguage} />
      </div>
    </div>
  );
};
