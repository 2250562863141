import { Link } from 'react-router-dom';
import { useMemo } from 'react';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { useTeam } from '@/app/team/context/TeamContext';

export const SettingsPage = () => {
  const { team } = useTeam();

  const sections = useMemo(() => {
    const res = [
      {
        title: 'Team Settings',
        to: 'team',
      },
      {
        title: 'Users',
        to: 'users',
      },
      {
        title: 'Integrations',
        to: 'integrations',
      },
      {
        title: 'API Keys',
        to: 'api-keys',
      },
    ];

    res.push();

    return res;
  }, [team]);

  return (
    <div className="page-content">
      <PageHeader title="Settings" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Settings',
            },
          ]}
        />
      </div>

      <div className="grid grid-cols-4 gap-4">
        {sections.map((v) => {
          return (
            <Link to={v.to} key={v.to} className="block card cursor-pointer" data-clickable="true">
              <div className="card-heading">{v.title}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
