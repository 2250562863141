import * as React from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { createId } from '@paralleldrive/cuid2';

import { nullthrows } from '../../../../utils/invariant';
import createContext from '../../../../contexts/create-context';
import { getWorkspaceStates, WorkspaceState } from './WorkspaceStates';
import { useTeam } from '@/app/team/context/TeamContext';
import { useExplorerTree } from '../../../explorerTree/contexts/ExplorerContext';
import { ExplorerTreeEntry } from '../../../explorerTree/explorer-tree';
import { SpinnerBlock } from '../../../../components/Spinner';
import { getDisplayError } from '../../../../utils/get-display-error';
import { useThrottle } from '../../../../hooks/useThrottle';
import type { ResponseType as WorkspaceResponseType } from '../../endpoints/WorkspaceEndpoint';
import type { ResponseType as WorkspaceCompaniesResponseType } from '../../endpoints/WorkspaceCompaniesEndpoint';
import { fetchEndpointData } from '../../../../utils/fetch.client';
import { ExplorerEntryType } from '../../../explorerTree/enums';

export type Workspace = WorkspaceResponseType['workspace'];

interface ProviderValue {
  workspace: Workspace;
  syncState: WorkspaceState;
  documents: ExplorerTreeEntry[];
  companies: WorkspaceCompaniesResponseType['companies'];
  isFetching: boolean;
  processingState: {
    processedCount: number;
    totalCount: number;
  };
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface WorkspaceProviderProps {
  children?: React.ReactNode;
}

const _WorkspaceProvider: React.FC<{
  workspace: Workspace;
  children?: React.ReactNode;
}> = (props) => {
  const { workspace, children } = props;
  const { team } = useTeam();
  const { workspaceId: _workspaceId } = useParams<{ workspaceId: string }>();
  const workspaceId = nullthrows(_workspaceId, 'document scan id not defined');
  const { tree: explorerTree, treeKey } = useExplorerTree();

  const workspaceCollectionId = workspace.documentCollection.id;
  const syncState = getWorkspaceStates().getState(workspaceId, team.id);
  const documents = React.useMemo(() => {
    const allCollections = explorerTree.getChildCollections(workspaceCollectionId);
    const documents = allCollections
      .map((v) => explorerTree.getCollectionNode(v))
      .map((v) => v?.children.filter((v) => v.type === ExplorerEntryType.Document))
      .flat()
      .filter(Boolean) as ExplorerTreeEntry[];
    return documents;
  }, [explorerTree, treeKey, workspaceCollectionId]);

  const processingState = React.useMemo(() => {
    let processedCount = 0;
    for (const doc of documents) {
      if (doc.document?.hasExtractedMetadata && doc.document?.hasExtractedParties) {
        processedCount += 1;
      }
    }
    return {
      processedCount,
      totalCount: documents.length,
    };
  }, [documents]);

  const [docPreprocessingKey, setDocPreprocessingKey] = React.useState(() => createId());
  useThrottle(
    React.useCallback(() => {
      fetchEndpointData(`/api/v1/workspace/doc-preprocessing`, {
        method: 'POST',
        body: {
          workspaceId,
        },
      })
        .then(() => {
          console.log('Document preprocessing started...');
        })
        .catch((err) => {
          console.error(err);
        });
    }, [workspaceId]),
    docPreprocessingKey,
    5000,
  );

  React.useEffect(() => {
    if (processingState.processedCount === processingState.totalCount) {
      fetchEndpointData(`/api/v1/workspace/extract-companies`, {
        method: 'POST',
        body: {
          workspaceId,
        },
      })
        .then(() => {
          console.log('Document preprocessing started...');
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setDocPreprocessingKey(createId());
    }
  }, [`${processingState.processedCount}::${processingState.totalCount}`]);

  const {
    data: companiesData,
    isLoading: fetchingCompanies,
    mutate: refetchCompaniesData,
  } = useSWR<WorkspaceCompaniesResponseType>(`/api/v1/workspace/companies/${workspaceId}`, fetchEndpointData);

  const [companiesFetchKey, setCompaniesFetchKey] = React.useState(syncState.companiesVersionHash);
  React.useEffect(() => {
    const changeDisposable = syncState.onCompaniesUpdate((newHash) => {
      setCompaniesFetchKey(newHash);
    });
    return () => {
      changeDisposable.dispose();
    };
  }, [syncState]);

  useThrottle(refetchCompaniesData, companiesFetchKey, 1000);

  const companies = companiesData?.companies ?? [];
  return (
    <ReactProvider
      value={{
        workspace,
        companies,
        syncState,
        documents,
        processingState,
        isFetching: explorerTree.isSyncing || fetchingCompanies,
      }}
    >
      {children}
    </ReactProvider>
  );
};

export const WorkspaceProvider: React.FC<WorkspaceProviderProps> = (props) => {
  const { children } = props;
  const { workspaceId: _workspaceId } = useParams<{ workspaceId: string }>();
  const workspaceId = nullthrows(_workspaceId, 'document scan id not defined');
  const { data, isLoading, error } = useSWR<WorkspaceResponseType>(
    `/api/v1/workspace/get/${workspaceId}`,
    fetchEndpointData,
  );

  const workspace = data?.workspace;

  if (!workspace && isLoading) {
    return <SpinnerBlock message="Loading workspace..." />;
  }

  if (!workspace && error) {
    return <div>{getDisplayError(error)}</div>;
  }

  if (!workspace) {
    return <div>Workspace not found</div>;
  }

  return <_WorkspaceProvider workspace={workspace}>{children}</_WorkspaceProvider>;
};

export const useWorkspace = useContext;
export const WorkspaceConsumer = ReactConsumer;
