import * as React from 'react';
import classNames from '@/utils/classnames';

export interface BaseInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: 'default' | 'inverted';
}

const BaseInput = React.forwardRef<HTMLInputElement, BaseInputProps>((props, ref) => {
  const { className, variant = 'default', ...otherProps } = props;

  return (
    <input
      className={classNames(
        'flex h-10 w-full rounded-md py-2 px-3 text-sm placeholder:border-gray-300 border border-transparent focus:outline-none disabled:cursor-not-allowed disabled:opacity-500 focus:border-gray-300 bg-gray-200 text-dark-500',
        className,
      )}
      ref={ref}
      {...otherProps}
    />
  );
});
BaseInput.displayName = 'BaseInput';

export { BaseInput };
