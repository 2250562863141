import { useEffect, useState } from 'react';

import classNames from '../utils/classnames';

export interface IDotLoaderProps {
  size: number;
}

export const DotLoader: React.FC<IDotLoaderProps> = (props) => {
  const { size } = props;
  const [highlightedDot, setHighlightedDot] = useState(0);

  useEffect(() => {
    const ref = setInterval(() => {
      setHighlightedDot((prev) => (prev + 1) % 3);
    }, 250);

    return () => clearInterval(ref);
  }, []);

  return (
    <div className="flex" style={{ gap: 2 }}>
      <div
        className={classNames('rounded-full', {
          'bg-gray-500': highlightedDot === 0,
          'bg-gray-400': highlightedDot !== 0,
        })}
        style={{
          width: size,
          height: size,
        }}
      ></div>
      <div
        className={classNames('rounded-full', {
          'bg-gray-500': highlightedDot === 1,
          'bg-gray-400': highlightedDot !== 1,
        })}
        style={{
          width: size,
          height: size,
        }}
      ></div>
      <div
        className={classNames('rounded-full', {
          'bg-gray-500': highlightedDot === 2,
          'bg-gray-400': highlightedDot !== 2,
        })}
        style={{
          width: size,
          height: size,
        }}
      ></div>
    </div>
  );
};
