import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { BriefcaseIcon, ChevronDown, ChevronUp, HashIcon, PencilIcon, TriangleAlertIcon, UserIcon } from 'lucide-react';

import { useWorkspace } from './WorkspaceContext';
import { formatDate } from '../../../../utils/date';
import { Pagination } from '../../../../components/Pagination';
import { Spinner, SpinnerBlock } from '../../../../components/Spinner';
import { Tag } from '../../../../components/Tag';
import { getDocumentTypeName } from '../../../document/constants.client';
import { Tooltip } from '../../../../components/tooltip/Tooltip';
import { DocumentMetadataDialog } from '../../../document/components/DocumentMetadataDialog';
import { ProgressBar } from '../../../../components/ProgressBar';
import { nullthrows } from '../../../../utils/invariant';
import { fetchEndpointData } from '../../../../utils/fetch.client';
import type { ResponseType as WorkspaceDocumentResponseType } from '../../endpoints/WorkspaceDocumentEndpoint';

export interface IWorkspaceDocumentsPageProps {
  documentId: string;
}

const WorkspaceDocumentDetailView: React.FC<IWorkspaceDocumentsPageProps> = (props) => {
  const { documentId } = props;
  const { data, isLoading } = useSWR<WorkspaceDocumentResponseType>(
    `/api/v1/workspace/document/${documentId}`,
    fetchEndpointData,
  );

  const doc = data?.document;

  if (!doc && isLoading) {
    return <Spinner size={6} />;
  }

  if (!doc) {
    return <div>Document not found.</div>;
  }

  return (
    <div className="my-2">
      {doc.parties.length > 0 && (
        <div>
          <div className="font-medium mb-2 border-b border-gray-200">Parties</div>
          <div className="my-2 grid md:grid-cols-2 gap-2">
            {doc.parties
              .sort((a, b) => {
                return a.role?.localeCompare(b.role ?? '') ?? 0;
              })
              .filter((b) => b.name.trim() || b.companyName?.trim())
              .map((p) => {
                const companyName = p.companyName?.trim() || '';
                const companyNumber = p.companyNumber?.trim() || '';
                const name = p.name.trim() || companyName || 'No name';
                const role = p.role?.trim() || '';

                return (
                  <div key={p.id}>
                    <div className="flex items-center gap-1">
                      <div className="text-dark-100">
                        {p.isPerson ? <UserIcon size={16} /> : <BriefcaseIcon size={16} />}
                      </div>
                      <div>{name}</div>
                    </div>
                    {role && <div>{role}</div>}
                    {p.isRepresentative && <div>Representative</div>}
                    {companyName && (
                      <div className="flex gap-4">
                        <div>{companyName}</div>
                        {companyNumber && (
                          <div className="flex items-center">
                            <HashIcon size={16} />
                            {companyNumber}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export const WorkspaceDocumentsPage = () => {
  const { workspace, processingState, documents: nodes, isFetching } = useWorkspace();
  const [detailView, setDetailView] = useState<string | null>(null);
  const [docEditView, setDocEditView] = useState<string | null>(null);

  const [pageCursor, setPageCursor] = useState<string | null>(null);
  const pageSize = 100;
  const { entries, nextCursor, prevCursor } = useMemo(() => {
    const foundStartIdx = pageCursor ? nodes.findIndex((v) => v.id === pageCursor) : 0;

    const results = nodes.slice(foundStartIdx, foundStartIdx + pageSize);
    const hasNext = foundStartIdx + pageSize < nodes.length;
    const hasPrevious = foundStartIdx > 0;

    return {
      entries: results,
      hasPrevious: hasPrevious,
      hasNext: hasNext,
      nextCursor: hasNext ? nodes[foundStartIdx + pageSize]?.id : null,
      prevCursor: hasPrevious ? nodes[foundStartIdx - 1]?.id : null,
    };
  }, [pageCursor, nodes]);

  const nextPage = useCallback(() => {
    setPageCursor(nextCursor ?? null);
  }, [setPageCursor, nextCursor]);

  const prevPage = useCallback(() => {
    setPageCursor(prevCursor ?? null);
  }, [setPageCursor, prevCursor]);

  const documents = entries;
  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-1">
          <div>Collection:</div>
          <Link to={`../../documents/${workspace.documentCollection.id}`} className="text-blue-200 hover:text-blue-500">
            {workspace.documentCollection.name}
          </Link>
        </div>
      </div>

      <div className="mb-2">
        <ProgressBar
          progress={processingState.processedCount}
          total={processingState.totalCount}
          color={processingState.processedCount >= processingState.totalCount ? 'green' : 'blue'}
          height={2}
        />
      </div>

      <div className="grid gap-1">
        {entries.map((entry) => {
          const doc = nullthrows(entry.document, 'Document not found');
          const isLoading = !doc.hasExtractedMetadata || !doc.hasExtractedParties;

          return (
            <div key={doc.id} className="bg-gray-100 rounded py-1 px-2">
              <div className="flex justify-between items-center">
                <div className="flex text-md items-center gap-1">
                  <div>{entry.name}</div>
                  <div>
                    {(doc.ocrConfidence ?? 1) < 0.8 && (
                      <Tooltip text="Document hard to read">
                        <div>
                          <TriangleAlertIcon className="text-red-400" size={16} />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>

                {isLoading ? (
                  <div>
                    <Spinner size={5} className="mx-2" />
                  </div>
                ) : (
                  <div className="flex gap-1">
                    {doc.categories.map((c) => (
                      <Tag color="green" key={`${doc.id}-${c.id}`}>
                        {c.name}
                      </Tag>
                    ))}
                    <Tag color="blue">{getDocumentTypeName(doc.documentType)}</Tag>
                    {doc.language && <Tag color="red">{doc.language}</Tag>}
                    {doc.jurisdiction && <Tag color="red">{doc.jurisdiction}</Tag>}

                    <div className="ml-2">{formatDate(doc.date ?? doc.createdAt)}</div>

                    <div
                      className="flex justify-center items-center cursor-pointer px-1"
                      onClick={() => {
                        setDocEditView(doc.id);
                      }}
                    >
                      <PencilIcon size={16} />
                    </div>

                    <DocumentMetadataDialog
                      open={docEditView === doc.id}
                      setOpen={(v) => {
                        if (!v) {
                          setDocEditView(null);
                        } else {
                          setDocEditView(doc.id);
                        }
                      }}
                      showTrigger={false}
                      document={{
                        ...doc,
                        categories: doc.categories,
                      }}
                    />

                    <div
                      className="flex justify-center items-center cursor-pointer"
                      onClick={() => {
                        setDetailView((v) => {
                          if (v === doc.id) {
                            return null;
                          } else {
                            return doc.id;
                          }
                        });
                      }}
                    >
                      {detailView === doc.id ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                    </div>
                  </div>
                )}
              </div>

              {detailView === doc.id && <WorkspaceDocumentDetailView documentId={doc.id} />}
            </div>
          );
        })}
      </div>

      {!documents.length && !isFetching && (
        <div className="card">
          <div>No documents found for this workspace.</div>
        </div>
      )}

      {!documents.length && isFetching && (
        <div>
          <SpinnerBlock message="Loading..." className="h-screen" />
        </div>
      )}

      <div className="py-4">
        <Pagination
          hasPrevious={!!prevCursor}
          previous={prevPage}
          hasNext={!!nextCursor}
          next={nextPage}
          isFetching={false}
        />
      </div>
    </div>
  );
};
