import { Outlet } from 'react-router-dom';

import { TeamProvider } from '../context/TeamContext';
import { TeamDashboard } from '../../../components/page-templates/TeamDashboard';
import { ExplorerTreeProvider } from '../../explorerTree/contexts/ExplorerContext';
import { CategoriesProvider } from '../../category/contexts/CategoriesContext';

export const TeamWrapper = () => {
  return (
    <TeamProvider>
      <ExplorerTreeProvider>
        <CategoriesProvider>
          <TeamDashboard>
            <Outlet />
          </TeamDashboard>
        </CategoriesProvider>
      </ExplorerTreeProvider>
    </TeamProvider>
  );
};
