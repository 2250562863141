import { Checkbox } from './Checkbox';

export interface ILabeledCheckboxProps {
  id: string;
  isChecked: boolean;
  onChange: (val: boolean) => void;
  isInvalid?: boolean;
  isDisabled?: boolean;
  labelText: string;
}

export const LabeledCheckbox: React.FC<ILabeledCheckboxProps> = (props) => {
  const { id, isChecked, onChange, isInvalid, isDisabled, labelText } = props;

  return (
    <label htmlFor={id} className="flex">
      <Checkbox isChecked={isChecked} onChange={onChange} id={id} isInvalid={isInvalid} isDisabled={isDisabled} />
      <div className="ml-2 text-dark-500">{labelText}</div>
    </label>
  );
};
