import { useNavigate, useParams, useResolvedPath, useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useId, useMemo, useState } from 'react';
import classNames from '@/utils/classnames';
import { toast } from 'react-hot-toast';
import {
  BugIcon,
  ClipboardIcon,
  DownloadIcon,
  EyeIcon,
  MessageCircleIcon,
  PencilIcon,
  Settings2Icon,
  TrashIcon,
} from 'lucide-react';
import useSWR from 'swr';

import { PageHeader } from '../../../components/PageHeader';
import { nullthrows } from '../../../utils/invariant';
import { IChildNode, generateDocumentContentTree } from './content-tree.util';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import { getDisplayError } from '../../../utils/get-display-error';
import { useRole } from '../../../hooks/useRole';
import { AuthRoles } from '../../user/auth-roles';
import { FilePreview, PREVIEW_MIMETYPES } from '../../file/components/FilePreview';
import { useAuth } from '../../../contexts/auth-context';
import { Button, LinkButton } from '../../../components/button/Button';
import { Tag } from '../../../components/Tag';
import { formatDate, formatDateTime } from '../../../utils/date';
import { useTeam } from '@/app/team/context/TeamContext';
import { getDocumentTypeName } from '../constants.client';
import { SummarizeDialog } from '../components/SummaryDialog';
import { InputDialog } from '../../../components/dialog/InputDialog';
import { DocumentMetadataDialog } from '../components/DocumentMetadataDialog';
import { StatusDot } from '../../../components/StatusDot';
import { SpinnerBlock } from '../../../components/Spinner';
import { useExplorerTree } from '../../explorerTree/contexts/ExplorerContext';
import { ExplorerTreeEntry } from '../../explorerTree/explorer-tree';
import { BodyType as DeleteDocumentsPayload } from '../endpoints/DeleteDocumentsEndpoint';
import { BodyType as RenameDocumentPayload } from '../endpoints/RenameDocumentEndpoint';
import { ResponseType as DocumentContentResponseType } from '../endpoints/DocumentContentEndpoint';
import { ResponseType as DocumentMetadataResponseType } from '../endpoints/DocumentMetadataEndpoint';
import { ResponseType as DocumentChunksResponseType } from '../endpoints/DocumentChunksEndpoint';
import { ResponseType as SignedUrlResponseType } from '../../file/endpoints/SignedFileUrlEndpoint';
import {
  ResponseType as DocumentParagraphIndexesResponseType,
  BodyType as DocumentParagraphIndexesPayload,
} from '../endpoints/DocumentParagraphIndexesEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { DocumentIndexingStatus } from '../enums';

function headingLevelToTag(level: number) {
  switch (level) {
    case 1:
      return 'h1';
    case 2:
      return 'h2';
    case 3:
      return 'h3';
    case 4:
      return 'h4';
    case 5:
      return 'h5';
    default:
      return 'h6';
  }
}

export interface IDocumentContentProps {
  content: string;
  paragraphIndexes?: number[];
  paragraphsToHighlight?: number[];
}

export const DocumentNode: React.FC<{ node: IChildNode; baseKey: string; isHighlighted: boolean }> = (props) => {
  const { node, baseKey, isHighlighted } = props;

  if (node.type === 'paragraph') {
    return (
      <p className="my-1 whitespace-pre-line">
        {node.children.map((c, i) => (
          <DocumentNode node={c} baseKey={`${baseKey}-${i}`} key={`${baseKey}-${i}`} isHighlighted={isHighlighted} />
        ))}
      </p>
    );
  } else if (node.type === 'heading') {
    return React.createElement(
      headingLevelToTag(node.level),
      {
        className: 'doc-heading',
      },
      node.children.map((c, i) => (
        <DocumentNode node={c} baseKey={`${baseKey}-${i}`} key={`${baseKey}-${i}`} isHighlighted={isHighlighted} />
      )),
    );
  } else if (node.type === 'table') {
    return (
      <table className="table-auto my-4 rounded-md overflow-hidden">
        {node.rows.map((row, rowIdx) => {
          return (
            <tr
              className={classNames('border-b', {
                'bg-dark-06': row.columns.find((col) => col.type === 'th'),
              })}
              key={`${baseKey}-${rowIdx}`}
            >
              {row.columns.map((col, colIdx) => {
                const colKey = `${baseKey}-${rowIdx}-${colIdx}`;

                if (col.type === 'td') {
                  return (
                    <td
                      key={colKey}
                      colSpan={col.colspan}
                      className={classNames('p-2 border-b', {
                        'border-r': colIdx !== row.columns.length - 1,
                      })}
                    >
                      {col.children.map((c, i) => {
                        return (
                          <DocumentNode
                            node={c}
                            baseKey={`${baseKey}-${i}`}
                            key={`${colKey}-${i}`}
                            isHighlighted={isHighlighted}
                          />
                        );
                      })}
                    </td>
                  );
                } else {
                  return (
                    <th
                      key={colKey}
                      colSpan={col.colspan}
                      className={classNames('p-2 border-b', {
                        'border-r': colIdx !== row.columns.length - 1,
                      })}
                    >
                      {col.children.map((c, i) => {
                        return (
                          <DocumentNode
                            node={c}
                            baseKey={`${baseKey}-${i}`}
                            key={`${colKey}-${i}`}
                            isHighlighted={isHighlighted}
                          />
                        );
                      })}
                    </th>
                  );
                }
              })}
            </tr>
          );
        })}
      </table>
    );
  } else if (node.type === 'list') {
    const TagType = node.isOrdered ? 'ol' : 'ul';
    return (
      <TagType className="my-4">
        {node.items.map((item, itemIdx) => {
          const liKey = `${baseKey}-${itemIdx}`;
          return (
            <li
              className={classNames('ml-4', {
                'list-decimal': node.isOrdered,
                'list-disc': !node.isOrdered,
              })}
              key={liKey}
            >
              {item.children.map((c, i) => (
                <DocumentNode
                  node={c}
                  baseKey={`${baseKey}-${i}`}
                  key={`${liKey}-${i}`}
                  isHighlighted={isHighlighted}
                />
              ))}
            </li>
          );
        })}
      </TagType>
    );
  } else if (node.type === 'text') {
    return (
      <span
        className={classNames({
          'font-bold': node.isBold,
          'font-italic': node.isItalic,
          'bg-yellow-fluo': isHighlighted,
        })}
      >
        {node.content}
      </span>
    );
  } else {
    console.error('Unhandled node type', node);
    return null;
  }
};

export const DocumentDebugView: React.FC<{ document: DocumentMetadataResponseType['document'] }> = (props) => {
  const { document } = props;
  const { data: chunksData, isLoading } = useSWR<DocumentChunksResponseType>(
    `/api/v1/document/chunks/${document.id}`,
    fetchEndpointData,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
    },
  );

  if (isLoading) {
    return <SpinnerBlock message="Loading debug data..." />;
  }

  const chunks = chunksData?.chunks ?? [];
  return (
    <div className="flex flex-col gap-4">
      <div className="card flex gap-2 flex-wrap">
        <Tag color="blue">{`Confidence ${Math.round((document.ocrConfidence ?? 1) * 100)}%`}</Tag>
        <Tag color="blue">{`Created at ${formatDateTime(document.createdAt)}`}</Tag>
        <Tag color="blue">{document.isSynced ? `Synced from Integration` : `No Integration`}</Tag>
      </div>

      {chunks
        .sort((a, b) => a.chunkIdx - b.chunkIdx)
        .map((c) => {
          return (
            <div key={c.id} className="card">
              <div className="flex justify-between items-center mb-4">
                <div className="font-bold">Chunk #{c.chunkIdx}</div>
                <div>
                  <Button
                    onTrigger={() => {
                      navigator.clipboard.writeText(c.content);
                      toast.success('Copied to clipboard');
                    }}
                  >
                    <ClipboardIcon className="button-icon" />
                  </Button>
                </div>
              </div>
              <div className="whitespace-pre-line">{c.content}</div>
            </div>
          );
        })}
    </div>
  );
};

export const DocumentContent: React.FC<IDocumentContentProps> = (props) => {
  const { content, paragraphIndexes = [], paragraphsToHighlight = [] } = props;

  const id = useId();
  const nodes = useMemo(() => {
    return generateDocumentContentTree(content);
  }, [content, paragraphIndexes.sort().join(',')]);

  useEffect(() => {
    if (paragraphsToHighlight.length) {
      const firstHighlighted = paragraphsToHighlight[0];
      const element = document.getElementById(`${id}-${firstHighlighted}`);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [paragraphsToHighlight]);

  const highlightSet = new Set(paragraphsToHighlight);
  const filterSet = new Set(paragraphIndexes);
  return (
    <>
      {nodes.map((node, nodeIdx) => {
        if (filterSet.has(nodeIdx)) {
          return null;
        }

        const baseKey = `${id}-${nodeIdx}`;
        if (highlightSet.has(nodeIdx)) {
          return (
            <div key={baseKey} id={baseKey}>
              <DocumentNode node={node} baseKey={baseKey} isHighlighted={true} />
            </div>
          );
        }

        return <DocumentNode node={node} baseKey={baseKey} key={baseKey} isHighlighted={false} />;
      })}
    </>
  );
};

const fetchParagraphIndexes = async ([endpoint, chunkIds]: [string, string[]]) => {
  const payload: DocumentParagraphIndexesPayload = {
    chunkIds,
  };
  const result = await fetchEndpointData<DocumentParagraphIndexesResponseType>(endpoint, {
    method: 'POST',
    body: payload,
  });
  return result;
};

export interface IDocumentComponentProps {
  document: DocumentMetadataResponseType['document'];
  documentContent: string | null;
  collectionNode: ExplorerTreeEntry;
}

const DocumentComponent: React.FC<IDocumentComponentProps> = (props) => {
  const { document, documentContent, collectionNode } = props;
  const [searchParams] = useSearchParams();
  const { team } = useTeam();
  const [debugMode, setDebugMode] = useState(false);
  const { me } = useAuth();
  const userRole = useRole();
  const navigate = useNavigate();

  const title = document.name;
  const documentId = document.id;
  const collectionUrl = useResolvedPath('..');
  const file = document.file;
  const pageNumber = searchParams.get('pageNumber');

  const highlightChunks = searchParams.get('highlightChunks');
  const { data: paragraphsToHighlightData } = useSWR(
    [`/api/v1/document/paragraph-indexes/${document.id}`, highlightChunks ? highlightChunks.split(';') : []],
    fetchParagraphIndexes,
  );
  const paragraphsToHighlight = paragraphsToHighlightData?.parahraphIndexes || [];

  const { data: signedFileLinkRes } = useSWR<SignedUrlResponseType>(
    `/api/v1/file/signed-url/${file?.id}`,
    fetchEndpointData,
    {
      isPaused: useCallback(() => !file, [file]),
    },
  );

  const fileUrl = signedFileLinkRes?.signedUrl;
  const hasPreviews = Boolean(file && PREVIEW_MIMETYPES.has(file.mimetype));
  const [showPreview, setShowPreview] = useState(false);

  const isProcessing = document.indexingStatus !== DocumentIndexingStatus.Indexed;
  const content = documentContent ?? '';
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="flex justify-between items-center max-w-full gap-4 mb-4">
        <div className="flex items-center" style={{ width: 'calc(100% - 240px)' }}>
          <div style={{ maxWidth: '50vw' }}>
            <Breadcrumb
              items={[
                {
                  name: collectionNode.name,
                  to: collectionUrl.pathname,
                },
                {
                  name: title,
                },
              ]}
            />
          </div>
          <div className="ml-1">
            <InputDialog
              triggerText={<PencilIcon className="button-icon" />}
              triggerVariant="ghost"
              title="Rename document"
              description="Enter a new name for the document"
              labelText="New name"
              submitText="Rename"
              initialValue={title}
              onSubmit={async (value) => {
                try {
                  const payload: RenameDocumentPayload = {
                    documentId,
                    newName: value,
                  };
                  await fetchEndpointData('/api/v1/document/rename', {
                    method: 'POST',
                    body: payload,
                  });
                  toast.success('Document name has been updated');
                } catch (err) {
                  toast.error('Could not update document name: ' + getDisplayError(err));
                }
              }}
            />
          </div>
        </div>

        <div className="flex gap-2 items-center">
          <SummarizeDialog title={document.name} documentId={documentId} language={me.language} />
          {me.isSuperUser && (
            <Button onTrigger={() => setDebugMode(!debugMode)}>
              <BugIcon className="button-icon" />
            </Button>
          )}
          {userRole >= AuthRoles.Editor && (
            <DocumentMetadataDialog
              triggerText={<Settings2Icon className="button-icon" />}
              document={{
                ...document,
                categories: document.categories.map((v) => v.category),
              }}
              key={document.id}
            />
          )}
          {userRole >= AuthRoles.Admin && (
            <ConfirmDialog
              triggerText={<TrashIcon className="button-icon" />}
              title="Delete document"
              submitText="Delete"
              triggerVariant="destructive"
              description={`Are you sure you want to delete ${document.name}?`}
              onSubmit={async () => {
                try {
                  const payload: DeleteDocumentsPayload = {
                    documentIds: [documentId],
                    teamId: team.id,
                  };
                  await fetchEndpointData('/api/v1/document/delete', {
                    method: 'DELETE',
                    body: payload,
                  });
                  toast.success('Document has been deleted');
                  navigate('..');
                } catch (err) {
                  toast.error('Could not delete document: ' + getDisplayError(err));
                }
              }}
            />
          )}
        </div>
      </div>

      {debugMode ? (
        <div>
          <DocumentDebugView document={document} />
        </div>
      ) : (
        <div className="card">
          <div className="flex justify-between items-center flex-wrap mb-4">
            <div className="flex items-center gap-2">
              {isProcessing && (
                <div className="flex items-center gap-1">
                  <StatusDot size={3} color="blue" pulse={true} />
                  Processing
                </div>
              )}
              <Tag color="blue">{formatDate(document.date ?? document.createdAt)}</Tag>
              <Tag color="blue">{getDocumentTypeName(document.documentType)}</Tag>
              {document.categories.map((c) => {
                return (
                  <Tag key={c.category.id} color="green">
                    {c.category.name}
                  </Tag>
                );
              })}
              {document.jurisdiction && <Tag color="red">{document.jurisdiction}</Tag>}
              {document.language && <Tag color="red">{document.language}</Tag>}
            </div>

            <div className="flex items-center gap-2">
              <LinkButton title="Chat with document" to={`../../../chat?documents=${document.id}`}>
                <MessageCircleIcon className="button-icon" />
              </LinkButton>
              {hasPreviews && (
                <Button title="Toggle Preview" onTrigger={() => setShowPreview(!showPreview)}>
                  <EyeIcon className="button-icon" />
                </Button>
              )}
              {fileUrl && (
                <LinkButton isExternal to={fileUrl} target="_blank">
                  <DownloadIcon className="button-icon" />
                </LinkButton>
              )}
              {document.evernoteIntegrationNote?.noteUrl && (
                <a target="_blank" className="block link-text-colored" href={document.evernoteIntegrationNote.noteUrl}>
                  Open in evernote
                </a>
              )}
            </div>
          </div>

          {isProcessing && !content && <SpinnerBlock message="Processing document..." />}

          {showPreview && hasPreviews && fileUrl && file ? (
            <FilePreview file={file} fileUrl={fileUrl} pageNumber={pageNumber ? +pageNumber : null} />
          ) : (
            <DocumentContent content={content} paragraphsToHighlight={paragraphsToHighlight} />
          )}
        </div>
      )}
    </div>
  );
};

export const DocumentPage = () => {
  const { documentId: _documentId, collectionId: _collectionId } = useParams<{
    documentId: string;
    collectionId: string;
  }>();
  const documentId = nullthrows(_documentId, 'documentId not defined in params');
  const collectionId = nullthrows(_collectionId, 'collectionId not defined in params');

  const {
    data: documentData,
    isLoading,
    mutate: mutateDocMetadata,
  } = useSWR<DocumentMetadataResponseType>(`/api/v1/document/metadata/${documentId}`, fetchEndpointData, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const {
    data: documentContentData,
    isLoading: isLoadingContent,
    mutate: mutateDocContent,
  } = useSWR<DocumentContentResponseType>(`/api/v1/document/content/${documentId}`, fetchEndpointData, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
  });
  const isFetching = isLoading || isLoadingContent;

  const { tree: explorerTree } = useExplorerTree();

  const content = documentContentData?.content;
  const refetchDocument = useCallback(() => {
    if (!content) {
      mutateDocContent(undefined, {
        revalidate: true,
      });
    }

    mutateDocMetadata(undefined, {
      revalidate: true,
    });
  }, [mutateDocMetadata, content]);

  useEffect(() => {
    const getUpdatedAt = () => {
      const node = explorerTree.getDocumentNode(documentId);
      if (!node) {
        return 0;
      }

      const nodeUpdatedAt = node.updatedAt.getTime();
      const documentUpdatedAt = node.document?.updatedAt.getTime() ?? 0;
      return Math.max(nodeUpdatedAt, documentUpdatedAt);
    };
    let lastUpdatedAt = getUpdatedAt();
    const disposable = explorerTree.onTreeChange(() => {
      const updatedAt = getUpdatedAt();
      if (updatedAt !== lastUpdatedAt) {
        lastUpdatedAt = updatedAt;
        refetchDocument();
      }
    });
    return () => {
      disposable.dispose();
    };
  }, [explorerTree]);

  const document = documentData?.document;
  const collectionNode = explorerTree.getCollectionNode(collectionId);
  if (!document || !collectionNode) {
    if (isFetching || explorerTree.isSyncing) {
      return <SpinnerBlock message="Loading document..." />;
    } else {
      if (!document) {
        throw new Error('Document not found');
      }

      if (!collectionNode) {
        throw new Error('Collection not found');
      }
    }
  }

  return (
    <DocumentComponent
      document={document}
      documentContent={documentContentData?.content || ''}
      collectionNode={collectionNode}
    />
  );
};
