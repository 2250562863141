import * as Sentry from '@sentry/react';

// @ts-ignore
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

function getEnvironment() {
  if (window.location.hostname.includes('staging')) {
    return 'staging';
  } else if (window.location.hostname.includes('ngrok.io')) {
    return 'development';
  } else {
    return 'production';
  }
}

Sentry.init({
  dsn: SENTRY_DSN,
  environment: getEnvironment(),
  integrations: [],
  ignoreErrors: ['Insufficient credits', 'Mimetype is not supported'],
});
