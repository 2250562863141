import * as RadixTooltip from '@radix-ui/react-tooltip';

export interface ITooltipProps {
  children: React.ReactNode;
  text: string;
  delayDuration?: number;
}

export const Tooltip: React.FC<ITooltipProps> = (props) => {
  const { children, text, delayDuration = 300 } = props;

  return (
    <RadixTooltip.Provider delayDuration={delayDuration}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className="border border-gray-300 shadow-md rounded px-2 py-1 bg-white text-sm text-dark-500 fade-in-50"
            sideOffset={5}
            style={{ zIndex: 500 }}
          >
            {text}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
