import { BookmarkIcon, ChevronDown, ChevronUp, HashIcon } from 'lucide-react';
import { useState } from 'react';

import { useWorkspace } from './WorkspaceContext';
import { Tooltip } from '../../../../components/tooltip/Tooltip';
import { Spinner, SpinnerBlock } from '../../../../components/Spinner';
import { numberAwareTextSort } from '../../../../utils/text';
import type { ResponseType as WorkspaceCompaniesResponseType } from '../../endpoints/WorkspaceCompaniesEndpoint';

type Company = WorkspaceCompaniesResponseType['companies'][0];

interface IWorkspaceCompaniesListProps {
  companies: Company[];
}

export const workspaceCompaniesSort = (a: Company, b: Company) => {
  const refDiff = b.referenceCount - a.referenceCount;
  if (refDiff === 0) {
    return numberAwareTextSort(a.name, b.name);
  } else {
    return refDiff;
  }
};

export const WorkspaceCompaniesList: React.FC<IWorkspaceCompaniesListProps> = (props) => {
  const { companies } = props;
  const [detailView, setDetailView] = useState<string | null>(null);

  return (
    <div className="grid gap-2">
      {companies.sort(workspaceCompaniesSort).map((company) => {
        return (
          <div key={company.id} className="bg-gray-100 rounded py-1 px-2">
            <div className="flex justify-between items-center">
              <div className="flex text-md items-center gap-1">
                <div>{company.name}</div>

                {company.companyNumber && (
                  <div className="flex items-center">
                    <HashIcon size={16} />
                    <div>{company.companyNumber}</div>
                  </div>
                )}
              </div>

              <div className="flex gap-2">
                <Tooltip text={`Referenced in ${company.referenceCount} documents`}>
                  <div className="flex items-center gap-1">
                    <div>{company.referenceCount}</div>
                    <BookmarkIcon size={16} />
                  </div>
                </Tooltip>

                <div
                  className="flex justify-center items-center cursor-pointer"
                  onClick={() => {
                    setDetailView((v) => {
                      if (v === company.id) {
                        return null;
                      } else {
                        return company.id;
                      }
                    });
                  }}
                >
                  {detailView === company.id ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                </div>
              </div>
            </div>

            {detailView === company.id && (
              <div className="mt-2">
                {company.aliases.length > 0 && (
                  <div>
                    <span className="font-medium">Aliases</span>: {company.aliases.join(', ')}
                  </div>
                )}
                {company.companyNumberAliases.length > 0 && (
                  <div>
                    <span className="font-medium">Company numbers</span>: {company.companyNumberAliases.join(', ')}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const WorkspaceCompaniesPage = () => {
  const { companies, processingState, isFetching } = useWorkspace();

  if (processingState.processedCount < processingState.totalCount && !companies.length) {
    return <SpinnerBlock message="Still processing documents..." />;
  }

  if (!companies.length && isFetching) {
    return <SpinnerBlock message="Loading company data..." />;
  }

  return (
    <div>
      {processingState.processedCount < processingState.totalCount && (
        <div className="flex gap-1 items-center mb-2">
          <Spinner size={4} />
          Some documents are still being processed...
        </div>
      )}

      <div className="mb-4">
        <WorkspaceCompaniesList companies={companies} />
      </div>
    </div>
  );
};
