import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { ChevronDownIcon, ChevronUpIcon, TrashIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { InputField } from '../../../components/input/InputField';
import { Button } from '../../../components/button/Button';
import { getDisplayError } from '../../../utils/get-display-error';
import { useTeam } from '@/app/team/context/TeamContext';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { SimpleSelectField } from '../../../components/select/SimpleSelectField';
import { QUESTIONS_PRESET_TYPE_OPTIONS } from '../constants.client';
import classNames from '../../../utils/classnames';
import { TextAreaField } from '../../../components/textarea/TextAreaField';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import type {
  BodyType as CreateQuestionsPresetPayload,
  ResponseType as CreateQuestionsPresetResponseType,
} from '../endpoints/CreateQuestionsPresetEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';

const createPresetQuestionSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Required').required('Required'),
  question: Yup.string().min(1, 'Required').required('Required'),
  index: Yup.string(),
  additionalContext: Yup.string(),
  answerInstructions: Yup.string(),
});

const createPresetSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Required').required('Required'),
  type: Yup.mixed().required('Required'),
  questions: Yup.array().of(createPresetQuestionSchema).required('Required'),
});

interface ICreatePresetQuestionProps {
  index: number;
  onDelete: () => void;
  isLast?: boolean;
  onMove: (offset: number) => void;
}

const CreatePresetQuestion = (props: ICreatePresetQuestionProps) => {
  const { index, onDelete, isLast, onMove } = props;

  return (
    <div
      className={classNames('w-full flex gap-4', {
        'border-b border-dark-08 mb-8': !isLast,
      })}
    >
      <div className="flex-1">
        <InputField labelText="Name" type="text" name={`questions.${index}.name`} />
        <InputField labelText="Question" type="text" name={`questions.${index}.question`} />
        <TextAreaField labelText="Additional context" name={`questions.${index}.additionalContext`} />
        <TextAreaField labelText="Additional answer instructions" name={`questions.${index}.answerInstructions`} />
      </div>

      <div className="flex flex-col gap-2 mt-6">
        <ConfirmDialog
          triggerText={<TrashIcon className="button-icon" />}
          aria-label="Delete question"
          title="Delete question"
          submitText="Delete"
          triggerVariant="destructive"
          description="Are you sure you want to delete this question?"
          onSubmit={onDelete}
        />
        <Button
          type="button"
          aria-label="Move up"
          onTrigger={() => {
            onMove(-1);
          }}
          isDisabled={index === 0}
        >
          <ChevronUpIcon className="button-icon" />
        </Button>
        <Button
          type="button"
          aria-label="Move down"
          onTrigger={() => {
            onMove(1);
          }}
          isDisabled={isLast}
        >
          <ChevronDownIcon className="button-icon" />
        </Button>
      </div>
    </div>
  );
};

export const CreatePresetPage = () => {
  const { team } = useTeam();
  const navigate = useNavigate();

  return (
    <div className="page-content">
      <PageHeader title="Create preset" />

      <div className="mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Presets',
              to: '..',
            },
            {
              name: 'Create a preset',
            },
          ]}
        />
      </div>

      <div className="card">
        <Formik
          initialValues={{
            name: '',
            type: QUESTIONS_PRESET_TYPE_OPTIONS[0],
            questions: [
              {
                index: '0',
                name: '',
                question: '',
                additionalContext: '',
                answerInstructions: '',
              },
            ],
          }}
          validationSchema={createPresetSchema}
          onSubmit={async (values) => {
            try {
              const payload: CreateQuestionsPresetPayload = {
                teamId: team.id,
                data: {
                  ...values,
                  questions: values.questions.map((v, idx) => {
                    return {
                      ...v,
                      index: idx,
                      additionalContext: v.additionalContext,
                      answerInstructions: v.answerInstructions,
                    };
                  }),
                  type: values.type?.key!,
                },
              };
              const result = await fetchEndpointData<CreateQuestionsPresetResponseType>(
                '/api/v1/questions-preset/create',
                {
                  method: 'POST',
                  body: payload,
                },
              );
              navigate(`../${result.preset.id}`);
              toast.success('Preset created');
            } catch (err: any) {
              toast.error('Could not create preset: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <form onSubmit={handleSubmit}>
              <InputField labelText="Name" type="text" name="name" />

              <SimpleSelectField labelText="Type" name="type" items={[...QUESTIONS_PRESET_TYPE_OPTIONS]} />

              <FieldArray
                name="questions"
                render={(arrayHelpers) => {
                  return (
                    <div className="mb-4">
                      <div className="flex justify-between">
                        <div className="mt-4 font-medium mb-2">Questions</div>
                        <Button
                          type="button"
                          onTrigger={() => {
                            arrayHelpers.push({
                              name: '',
                              question: '',
                              additionalContext: '',
                              answerInstructions: '',
                            });
                          }}
                        >
                          Add Question
                        </Button>
                      </div>

                      {values.questions.length > 0
                        ? values.questions.map((_, index) => (
                            <CreatePresetQuestion
                              key={index}
                              index={index}
                              onDelete={() => {
                                arrayHelpers.remove(index);
                              }}
                              onMove={(offset) => {
                                const newIndex = index + offset;
                                if (newIndex < 0 || newIndex >= values.questions.length) {
                                  return;
                                }
                                arrayHelpers.move(index, newIndex);
                              }}
                              isLast={index === values.questions.length - 1}
                            />
                          ))
                        : 'No questions, create at least one question'}
                    </div>
                  );
                }}
              />

              <Button type="submit" variant="primary" isDisabled={isSubmitting} isLoading={isSubmitting}>
                Create Preset
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
