import * as React from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import createContext from '../../../contexts/create-context';
import { SELECTED_TEAM_KEY } from '../../../constants.client';
import { SpinnerBlock } from '../../../components/Spinner';
import type { ResponseType as TeamResponseType } from '../endpoints/TeamEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';

interface ProviderValue {
  team: TeamResponseType['team'];
  refreshTeam: () => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface TeamProviderProps {
  children?: React.ReactNode;
}

export const TeamProvider: React.FC<TeamProviderProps> = (props) => {
  const { children } = props;
  const { teamId } = useParams<{ teamId: string }>();
  const { data, isLoading, mutate } = useSWR<TeamResponseType>(`/api/v1/team/data/${teamId}`, fetchEndpointData, {});

  const refreshTeam = React.useCallback(() => {
    mutate();
  }, [mutate]);

  const team = data?.team;
  React.useEffect(() => {
    if (!team) return;

    localStorage.setItem(SELECTED_TEAM_KEY, team.id);
  }, [team]);

  if ((!team || team.id !== teamId) && isLoading) {
    return <SpinnerBlock message="Loading..." />;
  }

  if (!team) {
    return <div>Team not found</div>;
  } else {
    return <ReactProvider value={{ team, refreshTeam }}>{children}</ReactProvider>;
  }
};

export const useTeam = useContext;
export const Consumer = ReactConsumer;
