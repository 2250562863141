import classNames from '@/utils/classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { Checkbox } from '../checkbox/Checkbox';
import { useSelectionReducer } from './selection-reducer';

export type TableRow = React.ReactNode[];

interface ITableRowProps<V> {
  index: number;
  mapData: (item: V) => TableRow;
  hasSelection?: boolean;
  selectedItems: number[];
  onSelect: (rowIdx: number) => void;
  data: V;
  isHovered: boolean;
  onHover: (id: string | number | null) => void;
  stickyFirstColumn?: boolean;
}

function TableRow<V = any>(props: ITableRowProps<V>) {
  const {
    index: rowIdx,
    data,
    hasSelection,
    selectedItems,
    onSelect,
    mapData,
    isHovered,
    onHover,
    stickyFirstColumn,
  } = props;

  const mapped = mapData(data);
  return (
    <tr
      key={`table-${rowIdx}`}
      className={classNames('border border-gray-200 rounded-lg', {
        'bg-gray-200': isHovered,
      })}
      onMouseEnter={() => onHover?.((data as any).id)}
    >
      {hasSelection && (
        <td
          className="h-12"
          style={
            stickyFirstColumn
              ? {
                  position: 'sticky',
                  zIndex: 1,
                  left: 0,
                }
              : {}
          }
        >
          <label className="flex justify-center items-center cursor-pointer w-full h-full">
            <Checkbox onChange={() => onSelect(rowIdx)} isChecked={selectedItems.includes(rowIdx)} />
          </label>
        </td>
      )}

      {mapped.map((cell, cellIdx) => {
        return (
          <td
            key={`table-${rowIdx}-${cellIdx}`}
            className={classNames('px-2 py-1', {
              'border-l border-gray-200': cellIdx !== 0,
              'bg-white': cellIdx === 0 && !isHovered,
              'bg-gray-200': isHovered,
            })}
            style={
              stickyFirstColumn && cellIdx === 0
                ? {
                    position: 'sticky',
                    zIndex: 1,
                    left: 0,
                  }
                : {}
            }
          >
            <div className="flex flex-wrap justify-start items-center">{cell}</div>
          </td>
        );
      })}
    </tr>
  );
}

export interface ITableHeader {
  id: string;
  name: string;
  width?: string;
}

export interface ITableCallbacks {
  resetSelections: () => void;
}

export interface ITableProps<V> {
  idKey: string;
  refreshToken?: string;
  headers: ITableHeader[];
  mapData: (item: V) => TableRow;
  onSelect?: (selectedItems: V[]) => void;
  isMultiSelect?: boolean;
  data: V[];
  hoveredId?: string | number | null;
  onHover?: (id: null | string | number) => void;
  registerCallbacks?: (callbacks: ITableCallbacks) => void;
  stickyFirstColumn?: boolean;
}

export function Table<V = any>(props: ITableProps<V>) {
  const { headers, data, mapData, onSelect, registerCallbacks, onHover: _onHover, idKey, stickyFirstColumn } = props;
  const [{ selectedItems }, dispatchSelection] = useSelectionReducer(Boolean(props.isMultiSelect));
  const [hoveredId, setHoveredId] = useState<string | number | null>(null);

  const hasSelection = Boolean(onSelect);

  useEffect(() => {
    dispatchSelection({
      type: 'change_type',
      isMultiSelect: Boolean(props.isMultiSelect),
    });
  }, [props.isMultiSelect]);

  useEffect(() => {
    if (onSelect) {
      onSelect(selectedItems.map((v) => data[v]!));
    }
  }, [selectedItems, Boolean(onSelect)]);

  const resetSelections = useCallback(() => {
    dispatchSelection({ type: 'reset' });
  }, [dispatchSelection]);

  useEffect(() => {
    registerCallbacks?.({
      resetSelections,
    });
  }, [registerCallbacks, resetSelections]);

  const handleSelect = useCallback(
    (index: number) => {
      dispatchSelection({
        type: 'select',
        index,
      });
    },
    [dispatchSelection],
  );

  const onHover = (id: number | string | null) => {
    _onHover?.(id);
    setHoveredId(id);
  };

  return (
    <table className="w-full relative" onMouseLeave={() => onHover(null)}>
      <thead>
        <tr className="relative">
          {hasSelection && (
            <th
              className="w-12"
              style={{
                position: 'sticky',
                zIndex: 2,
                left: stickyFirstColumn ? 0 : undefined,
                top: 0,
              }}
            ></th>
          )}

          {headers.map((header, idx) => {
            return (
              <th
                className={classNames(
                  'px-2 py-1 font-normal text-gray-600 text-nowrap border border-gray-200 bg-gray-100',
                )}
                style={{
                  minWidth: header.width ?? undefined,
                  position: 'sticky',
                  zIndex: idx === 0 ? 2 : 1,
                  left: stickyFirstColumn ? 0 : undefined,
                  top: 0,
                }}
                key={`table-header-${idx}`}
              >
                <div className="flex flex-wrap justify-start items-center">{header.name}</div>
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody className="w-full">
        {data.map((d, rowIdx) => {
          return (
            <TableRow
              // @ts-ignore
              key={`table-row-${d[idKey] ?? rowIdx}`}
              index={rowIdx}
              mapData={mapData}
              data={d}
              selectedItems={selectedItems}
              onSelect={handleSelect}
              hasSelection={hasSelection}
              onHover={onHover}
              isHovered={Boolean(hoveredId != null && (d as any)[idKey] != null && hoveredId === (d as any)[idKey])}
              stickyFirstColumn={stickyFirstColumn}
            />
          );
        })}
      </tbody>
    </table>
  );
}
