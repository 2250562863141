// !!! This is used on server and client side !!!

export enum DocumentTypeEnum {
  CaseLaw = 'case-law', // arresten, uitspraken
  Legislation = 'legislation', // wetgeving
  Contract = 'contract', // contracten
  LegalLiterature = 'legal-literature', // juridische literatuur, boeken, artikelen, noten, annotaties
  Advice = 'advice', // adviezen
  Email = 'email', // e-mails
  Other = 'other', // overig
}

export function getDocumentType(value: string): DocumentTypeEnum {
  switch (value.trim().toLowerCase()) {
    case 'case-law':
      return DocumentTypeEnum.CaseLaw;
    case 'legislation':
      return DocumentTypeEnum.Legislation;
    case 'contract':
      return DocumentTypeEnum.Contract;
    case 'legal-literature':
      return DocumentTypeEnum.LegalLiterature;
    case 'advice':
      return DocumentTypeEnum.Advice;
    case 'email':
      return DocumentTypeEnum.Email;
    default:
      return DocumentTypeEnum.Other;
  }
}

export enum DocumentIndexingStatus {
  Created = 'Created', // New record, pending embeddings generation
  Pending = 'Pending', // Embeddings have been generated, pending indexation
  Indexing = 'Indexing', // Indexing, awaiting task completion
  Indexed = 'Indexed', // Indexed, all done.
}

export function getDocumentIndexingStatus(value: string): DocumentIndexingStatus {
  switch (value.trim().toLowerCase()) {
    case 'created':
      return DocumentIndexingStatus.Created;
    case 'pending':
      return DocumentIndexingStatus.Pending;
    case 'indexing':
      return DocumentIndexingStatus.Indexing;
    case 'indexed':
      return DocumentIndexingStatus.Indexed;
    default:
      return DocumentIndexingStatus.Created;
  }
}
