import * as React from 'react';
import useSWR from 'swr';

import createContext from '../../../contexts/create-context';
import { useTeam } from '@/app/team/context/TeamContext';
import { SpinnerBlock } from '../../../components/Spinner';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { ResponseType as CategoryResult } from '../endpoints/ListCategoriesEndpoint';

export type Category = CategoryResult['items'][0];

export interface ICategoryNode {
  id: string;
  name: string;
  parentCategoryId: string | null;
  children: ICategoryNode[];
}

interface ProviderValue {
  categories: Category[];
  categoryMap: Map<string, Category>;
  categoryTree: ICategoryNode[];
  refetch: () => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface CategoriesProviderProps {
  children?: React.ReactNode;
}

export const CategoriesProvider: React.FC<CategoriesProviderProps> = (props) => {
  const { children } = props;
  const { team } = useTeam();
  const { data, isLoading, mutate } = useSWR<CategoryResult>(
    `/api/v1/category/list?teamId=${team.id}&take=500`,
    fetchEndpointData,
  );

  const refetch = React.useCallback(() => {
    mutate();
  }, [mutate]);

  React.useEffect(() => {
    const intervalRef = setInterval(() => {
      refetch();
    }, 15 * 60_000);

    return () => clearInterval(intervalRef);
  }, [refetch]);

  const rawCategories = data?.items ?? [];
  const { categories, categoryMap, categoryTree } = React.useMemo(() => {
    const categories = [...rawCategories];
    const categoryMap = new Map(categories.map((v) => [v.id, v]));

    const nodeMap: Map<string, ICategoryNode> = new Map();
    const categoryTree = [] as ICategoryNode[];

    let pendingItems = [...categories];
    let iterCount = 0;
    while (pendingItems.length > 0 && iterCount < 5000) {
      iterCount++;

      for (const pendingItem of pendingItems) {
        if (!pendingItem.parentCategoryId) {
          const node: ICategoryNode = {
            id: pendingItem.id,
            name: pendingItem.name,
            parentCategoryId: null,
            children: [],
          };
          categoryTree.push(node);
          nodeMap.set(pendingItem.id, node);
          pendingItems = pendingItems.filter((v) => v.id !== pendingItem.id);
        } else {
          const parentNode = nodeMap.get(pendingItem.parentCategoryId);
          if (parentNode) {
            const node: ICategoryNode = {
              id: pendingItem.id,
              name: pendingItem.name,
              parentCategoryId: pendingItem.parentCategoryId,
              children: [],
            };
            parentNode.children.push(node);
            nodeMap.set(pendingItem.id, node);
            pendingItems = pendingItems.filter((v) => v.id !== pendingItem.id);
          }
        }
      }
    }

    return {
      categories,
      categoryMap,
      categoryTree,
    };
  }, [data]);

  if (isLoading) {
    return <SpinnerBlock message="Loading categories..." className="h-screen" />;
  }

  return <ReactProvider value={{ categories, categoryMap, categoryTree, refetch }}>{children}</ReactProvider>;
};

export const useCategories = useContext;
export const CategoriesConsumer = ReactConsumer;
