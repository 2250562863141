import { ArrowRightIcon } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';

import { LinkButton } from '../../../components/button/Button';
import { PageHeader } from '../../../components/PageHeader';

export const LoginFailedPage = () => {
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error') || 'Unknown error';
  const errorCode = searchParams.get('error_code') || '-';
  const provider = searchParams.get('provider') || 'Unknown provider';

  return (
    <div className="py-8 min-h-screen">
      <PageHeader title="User not found" />

      <main className="sm:rounded-md sm:mt-16 max-w-lg mx-auto p-8 bg-gray-100">
        <h1 className="heading-one mb-8">Login failed</h1>

        <div className="my-4">
          <div>{`Provider: ${provider}`}</div>
          <div>{`Error code: ${errorCode}`}</div>
          <div>{`Error: ${error}`}</div>
        </div>

        <div className="flex my-4">
          <LinkButton to="/login">
            <div className="w-full flex items-center justify-between">
              <span>Go back to login page</span>
              <ArrowRightIcon className="h-4 w-5" />
            </div>
          </LinkButton>
        </div>
      </main>

      <footer className="flex justify-center items-center gap-4 flex-wrap my-8">
        <a
          href="https://docs.google.com/document/d/1VfBoGVguSoj5-r8NOcq72qZdGD0yoRx5E04drLrPgiM/edit?usp=sharing"
          className="hover:underline"
        >
          Terms and conditions
        </a>
        <div>|</div>
        <a
          href="https://docs.google.com/document/d/1yZ_9gworSTk6LNkXqVTYscd_9HoSYaL0g5w9HEpdTh4/edit?usp=sharing"
          className="hover:underline"
        >
          Acceptable Use Policy
        </a>
        <div>|</div>
        <a
          href="https://docs.google.com/document/d/1T33zx7ocKqyOQfEk_VS2I2o-y_qd3Y54lHKPK7SYvcg/edit?usp=sharing"
          className="hover:underline"
        >
          Privacy Policy
        </a>
      </footer>
    </div>
  );
};
