import { useMemo, useState } from 'react';
import * as docx from 'docx';
import * as XLSX from 'forked-xlsx';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import { AsteriskIcon, BanIcon, DownloadIcon } from 'lucide-react';

import { classNames } from '@/utils/classnames';

import { WorkspacePresetRun } from './types';
import { Table } from '../../../../../../components/table/Table';
import { Tooltip } from '../../../../../../components/tooltip/Tooltip';
import { Checkbox } from '../../../../../../components/checkbox/Checkbox';
import { Button } from '../../../../../../components/button/Button';
import { numberAwareTextSort, trimText } from '../../../../../../utils/text';
import { getRandomValue } from '../../../../../../utils/number';
import { ConfirmDialog } from '../../../../../../components/dialog/ConfirmDialog';
import { getDisplayError } from '../../../../../../utils/get-display-error';
import { DataField } from '../../../../../../components/DataField';
import { StatusDot } from '../../../../../../components/StatusDot';
import { ReferenceDialog } from './ReferenceDialog';
import { PresetRunStatus } from '../../../../enums';
import type { BodyType as CancelPresetRunPayload } from '../../../../endpoints/CancelPresetRunEndpoint';
import { fetchEndpointData } from '../../../../../../utils/fetch.client';

interface IGroupedDocumentAnswers {
  name: string;
  collectionId: string | null;
  answers: Record<
    string,
    {
      documentAnswerId: string;
      isLoading: boolean;
      isRelevant: boolean;
      full: string;
      minified: string;
    }
  >;
}

function downloadDocAnswersAsDocx(answerGroups: IGroupedDocumentAnswers[]) {
  const children: any[] = [];

  for (const answerGroup of answerGroups) {
    const table = new docx.Table({
      rows: [...Object.entries(answerGroup.answers)].map(([question, answer]) => {
        return new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [new docx.Paragraph({ text: question })],
              width: { size: 50, type: docx.WidthType.PERCENTAGE },
            }),
            new docx.TableCell({
              children: answer.minified.split('\n').map((v) => {
                return new docx.Paragraph({ text: v });
              }),
              width: { size: 50, type: docx.WidthType.PERCENTAGE },
            }),
          ],
        });
      }),
      width: { size: 100, type: docx.WidthType.PERCENTAGE },
      columnWidths: [50, 50],
      layout: docx.TableLayoutType.FIXED,
    });

    children.push(
      new docx.Paragraph({
        children: [
          new docx.TextRun({
            text: answerGroup.name,
            bold: true,
          }),
        ],
      }),
      table,
    );
  }

  const doc = new docx.Document({
    creator: 'Jurimesh',
    sections: [
      {
        children,
      },
    ],
  });

  docx.Packer.toBlob(doc).then((blob) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `preset-run-results-${dayjs().format('DD-MM-YYYY-HH-mm')}.docx`;
    link.click();
  });
}

function downloadDocAnswersAsExcel(grouped: Map<string, IGroupedDocumentAnswers>, keys: string[]) {
  const rows: any[] = [];
  const docs = [...grouped.values()].sort((a, b) => numberAwareTextSort(a.name, b.name));
  const titles = ['Document', ...keys];

  rows.push(titles);

  for (const doc of docs) {
    const row = [];
    for (const key of titles) {
      if (key === 'Document') {
        row.push(doc.name);
      } else {
        row.push(doc.answers[key]?.minified ?? '-');
      }
    }
    rows.push(row);
  }

  const ws = XLSX.utils.aoa_to_sheet(rows);

  if (!ws['!cols']) {
    ws['!cols'] = [];
  }

  for (let i = 0; i < titles.length; i++) {
    if (!ws['!cols'][i]) {
      ws['!cols'][i] = {};
    }

    ws['!cols']![i]!.wch = 50;
  }

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Preset run answers');
  const res = XLSX.write(wb, {
    type: 'base64',
    cellStyles: true,
  });

  const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res}`;
  const linkTag = document.createElement('a');
  linkTag.href = url;
  linkTag.download = `preset-run-results-${dayjs().format('DD-MM-YYYY-HH-mm')}.xlsx`;
  linkTag.click();
}

export const DocumentPresetRunPage: React.FC<{ presetRun: WorkspacePresetRun }> = (props) => {
  const { presetRun } = props;
  const [showFullVersion, setShowFullVersion] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [openRef, setOpenRef] = useState<string>('');

  const grouped = useMemo(() => {
    const documents = new Map<string, IGroupedDocumentAnswers>();
    for (const question of presetRun.questions) {
      for (const docAnswer of question.documentAnswers) {
        const documentData: IGroupedDocumentAnswers = documents.get(docAnswer.document.id) ?? {
          name: docAnswer.document.name,
          collectionId: docAnswer.document.collectionId ?? null,
          answers: {},
        };

        const isLoading = docAnswer.isRelevant === null;

        let answer = '';
        if (docAnswer.isRelevant === true) {
          answer = docAnswer.answer ?? '';
        } else if (docAnswer.isRelevant === false) {
          answer = '-';
        }
        documentData.answers[question.name] = {
          documentAnswerId: docAnswer.id,
          isRelevant: docAnswer.isRelevant ?? false,
          isLoading: isLoading,
          full: answer,
          minified: docAnswer.minifiedAnswer ? docAnswer.minifiedAnswer : isLoading ? '...' : '-',
        };
        documents.set(docAnswer.document.id, documentData);
      }
    }
    return documents;
  }, [presetRun]);

  const allKeys = useMemo(() => {
    const keys = new Set<string>();
    for (const group of grouped.values()) {
      for (const key of Object.keys(group.answers)) {
        keys.add(key);
      }
    }
    return keys;
  }, [grouped]);

  const isPending = presetRun.status === PresetRunStatus.Running;
  return (
    <div>
      <div className="mb-2 flex justify-between">
        <div className="flex gap-2 items-center">
          <DataField title="Status">
            <div className="flex gap-1 items-center">
              <StatusDot
                size={3}
                color={
                  presetRun.status === PresetRunStatus.Running
                    ? 'blue'
                    : presetRun.status === PresetRunStatus.Canceled
                      ? 'red'
                      : 'green'
                }
                pulse={presetRun.status === PresetRunStatus.Running}
              />
              {presetRun.status}
            </div>
          </DataField>
        </div>

        <div className="flex gap-2 items-center">
          {presetRun.status === PresetRunStatus.Finished && (
            <>
              <Button
                iconLeft={<DownloadIcon className="button-icon" />}
                onTrigger={() => {
                  downloadDocAnswersAsDocx([...grouped.values()]);
                }}
              >
                Word
              </Button>
              <Button
                iconLeft={<DownloadIcon className="button-icon" />}
                onTrigger={() => {
                  downloadDocAnswersAsExcel(grouped, [...allKeys]);
                }}
              >
                Excel
              </Button>
            </>
          )}

          {isPending && (
            <div>
              <ConfirmDialog
                triggerText={<BanIcon className="button-icon" />}
                title="Cancel"
                submitText="Cancel"
                triggerVariant="destructive"
                description="Are you sure you want to cancel this preset run?"
                onSubmit={async () => {
                  try {
                    const payload: CancelPresetRunPayload = {
                      presetRunId: presetRun.id,
                    };
                    await fetchEndpointData('/api/v1/workspace/preset-run/cancel', {
                      method: 'DELETE',
                      body: payload,
                    });
                    toast.success('Preset run canceled');
                  } catch (err) {
                    toast.error('Could not cancel preset run: ' + getDisplayError(err));
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between mb-2">
        <div>{presetRun.name}</div>

        <div className="flex gap-8 items-center">
          <label className="flex items-center gap-1">
            <Checkbox isChecked={showTable} onChange={setShowTable} />
            <div>Table</div>
          </label>

          <label className="flex items-center gap-1">
            <Checkbox isChecked={showFullVersion} onChange={setShowFullVersion} />
            <div>Detailed</div>
          </label>
        </div>
      </div>

      {showTable ? (
        <div className="w-screen overflow-auto" style={{ width: 'calc(100vw - 14rem)', height: 'calc(100vh - 18rem)' }}>
          <Table
            idKey="id"
            stickyFirstColumn={true}
            headers={['Document', ...allKeys].map((v, idx) => {
              return {
                id: v,
                name: v,
              };
            })}
            data={[...grouped.entries()]
              .sort((a, b) => {
                return numberAwareTextSort(a[1].name, b[1].name);
              })
              .map(([docKey, value]) => {
                const res: {
                  id: string;
                  name: string;
                  collectionId: string;
                  answers: Record<
                    string,
                    {
                      documentAnswerId: string;
                      content: string;
                      fullAnswer: string;
                      minifiedAnswer: string;
                      isLoading: boolean;
                    }
                  >;
                } = {
                  id: docKey,
                  collectionId: value.collectionId ?? 'ROOT',
                  name: value.name,
                  answers: {},
                };

                for (const key of allKeys) {
                  const answer = value.answers[key];
                  let answerText = '-';
                  if (answer) {
                    answerText = showFullVersion ? answer.full : answer.minified;
                  }
                  res.answers[key] = {
                    documentAnswerId: answer?.documentAnswerId ?? '',
                    content: answerText,
                    fullAnswer: answer?.full ?? '',
                    minifiedAnswer: answer?.minified ?? '',
                    isLoading: answer?.isLoading ?? false,
                  };
                }

                return res;
              })}
            mapData={(entry) => {
              return [
                <Tooltip text={entry.name}>
                  <div className="text-nowrap">{trimText(entry.name, 30)}</div>
                </Tooltip>,
                ...[...allKeys].map((key) => {
                  const cellId = `cell-${entry.id}-${key}`;
                  const answer = entry.answers[key];
                  let content = answer?.content ?? '-';

                  if (answer?.isLoading) {
                    if (presetRun.status === PresetRunStatus.Canceled) {
                      content = '-';
                    } else {
                      return (
                        <div
                          className="bg-gray-300 rounded animate-pulse"
                          style={{
                            height: 12,
                            width: Math.round(50 + getRandomValue(cellId) * 100),
                          }}
                        >
                          &nbsp;
                        </div>
                      );
                    }
                  }

                  const isEmpty = content === '-';
                  return (
                    <div
                      className={classNames('relative w-full', {
                        'cursor-pointer': !isEmpty,
                      })}
                      onClick={(evt) => {
                        if (evt.ctrlKey || evt.metaKey) {
                          navigator.clipboard.writeText(content);
                          toast.success('Copied to clipboard');
                          return;
                        }
                      }}
                    >
                      <div className="flex justify-between gap-2">
                        <div className="whitespace-pre-wrap">{content}</div>

                        {answer && answer.fullAnswer.length > 8 && (
                          <div
                            className="mt-1 hover:text-blue-500 cursor-pointer"
                            onClick={(evt) => {
                              evt.stopPropagation();
                              evt.preventDefault();

                              setOpenRef(answer?.documentAnswerId ?? '');
                            }}
                          >
                            <AsteriskIcon className="w-4 h-4" />
                          </div>
                        )}
                      </div>

                      <ReferenceDialog
                        documentAnswer={`${answer?.fullAnswer ?? ''}\n${answer?.minifiedAnswer}`}
                        documentAnswerId={answer?.documentAnswerId ?? ''}
                        document={{
                          id: entry.id,
                          name: entry.name,
                          collectionId: entry.collectionId!,
                        }}
                        onOpenChange={(newOpen) => {
                          if (newOpen) {
                            setOpenRef(answer?.documentAnswerId ?? '');
                          } else {
                            setOpenRef((prev) => {
                              if (prev === answer?.documentAnswerId) {
                                return '';
                              }

                              return prev;
                            });
                          }
                        }}
                        isOpen={openRef === answer?.documentAnswerId}
                      />
                    </div>
                  );
                }),
              ];
            }}
          />
        </div>
      ) : (
        <div>
          {[...grouped.entries()].map(([key, value]) => {
            return (
              <div key={key}>
                <div className="font-medium mb-2 mt-4">{value.name}</div>

                <Table
                  idKey="id"
                  stickyFirstColumn={true}
                  headers={['Question', 'Answer'].map((v, idx) => {
                    return {
                      id: v,
                      name: v,
                    };
                  })}
                  data={[...Object.entries(value.answers)]
                    .sort((a, b) => {
                      return numberAwareTextSort(a[0], b[0]);
                    })
                    .map(([questionName, value]) => {
                      let answerText = '-';
                      if (value.isRelevant && value.full) {
                        answerText = showFullVersion ? value.full : value.minified;
                      }

                      return {
                        questionName,
                        answerText,
                        isLoading: value?.isLoading ?? false,
                        documentAnswerId: value?.documentAnswerId,
                        fullAnswer: value.full,
                        minifiedAnswer: value.minified,
                      };
                    })}
                  mapData={(entry) => {
                    const isEmpty = entry.answerText === '-';
                    const content = entry.answerText;
                    const isLoading = entry.isLoading;
                    const documentAnswerId = entry.documentAnswerId;

                    return [
                      <div className="text-nowrap">{entry.questionName}</div>,
                      isLoading ? (
                        <div
                          className="bg-gray-300 rounded animate-pulse"
                          style={{
                            height: 12,
                            width: Math.round(50 + getRandomValue(documentAnswerId) * 100),
                          }}
                        >
                          &nbsp;
                        </div>
                      ) : (
                        <div className="relative w-full">
                          <div className="flex justify-between gap-2">
                            <div
                              className={classNames('whitespace-pre-wrap', {
                                'cursor-pointer': !isEmpty,
                              })}
                              onClick={(evt) => {
                                if (evt.ctrlKey || evt.metaKey) {
                                  navigator.clipboard.writeText(content);
                                  toast.success('Copied to clipboard');
                                  return;
                                }
                              }}
                            >
                              {content}
                            </div>

                            {!isEmpty && documentAnswerId && (
                              <div
                                className="mt-1 hover:text-blue-500 cursor-pointer"
                                onClick={(evt) => {
                                  evt.stopPropagation();
                                  evt.preventDefault();

                                  setOpenRef(entry.documentAnswerId);
                                }}
                              >
                                <AsteriskIcon className="w-4 h-4" />
                              </div>
                            )}

                            <ReferenceDialog
                              documentAnswer={`${entry?.fullAnswer ?? ''}\n${entry?.minifiedAnswer}`}
                              documentAnswerId={entry.documentAnswerId}
                              document={{
                                id: key,
                                name: value.name,
                                collectionId: value.collectionId!,
                              }}
                              onOpenChange={(newOpen) => {
                                if (newOpen) {
                                  setOpenRef(entry.documentAnswerId ?? '');
                                } else {
                                  setOpenRef((prev) => {
                                    if (prev === entry.documentAnswerId) {
                                      return '';
                                    }

                                    return prev;
                                  });
                                }
                              }}
                              isOpen={openRef === entry.documentAnswerId}
                            />
                          </div>
                        </div>
                      ),
                    ];
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
