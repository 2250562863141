import { Link } from 'react-router-dom';
import useSWR from 'swr';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { useTeam } from '@/app/team/context/TeamContext';
import { formatDate } from '../../../utils/date';
import { SpinnerBlock } from '../../../components/Spinner';
import { ResponseType as EvernoteIntegrationResponseType } from '../endpoints/evernote/EvernoteIntegrationEndpoint';
import { ResponseType as KnowlexIntegrationResponseType } from '../endpoints/knowlex/KnowlexIntegrationEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';

export const IntegrationsPage = () => {
  const { team } = useTeam();
  const { data: evernoteData, isLoading: evernoteFetching } = useSWR<EvernoteIntegrationResponseType>(
    `/api/v1/integration/evernote/data/${team.id}`,
    fetchEndpointData,
  );
  const { data: knowlexData, isLoading: knowlexFetching } = useSWR<KnowlexIntegrationResponseType>(
    `/api/v1/integration/knowlex/data/${team.id}`,
    fetchEndpointData,
  );

  const evernoteIntegration = evernoteData?.evernoteIntegration;
  const knowlexIntegration = knowlexData?.knowlexIntegration;

  if ((!evernoteIntegration && evernoteFetching) || (!knowlexIntegration && knowlexFetching)) {
    return <SpinnerBlock message="Loading..." />;
  }

  return (
    <div className="page-content">
      <PageHeader title="Integrations" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Settings',
              to: '..',
            },
            {
              name: 'Integrations',
            },
          ]}
        />
      </div>

      <div className="grid grid-cols-4 gap-4">
        <Link to="evernote" className="block card cursor-pointer" data-clickable="true">
          <div className="card-heading flex justify-between">Evernote</div>
          <div>
            <div>
              {evernoteIntegration?.linkedAt ? `Linked at ${formatDate(evernoteIntegration.linkedAt)}` : 'Not linked'}
            </div>
          </div>
        </Link>

        <Link to="knowlex" className="block card cursor-pointer" data-clickable="true">
          <div className="card-heading flex justify-between">Knowlex</div>
          <div>
            <div>
              {knowlexIntegration?.createdAt ? `Linked at ${formatDate(knowlexIntegration.createdAt)}` : 'Not linked'}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
