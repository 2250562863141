// !!! Used on both server and client !!!

export enum LanguageEnum {
  NL = 'nl',
  EN = 'en',
  DE = 'de',
  FR = 'fr',
}

const LANGUAGE_VALUES_SET = new Set<string>(Object.values(LanguageEnum));
export function getLanguage(lang: string): LanguageEnum {
  const normalized = lang.toLowerCase();
  if (LANGUAGE_VALUES_SET.has(normalized)) {
    return normalized as LanguageEnum;
  } else {
    return LanguageEnum.EN;
  }
}

export const LANG_MAP: Record<string, string> = {
  nl: 'Dutch',
  en: 'English',
  de: 'German',
  fr: 'French',
};

export enum JurisdictionEnum {
  BE = 'be', // Belgium
  FR = 'fr', // France
  NL = 'nl', // Netherlands
  DE = 'de', // Germany
  IT = 'it', // Italy
  ES = 'es', // Spain
  PT = 'pt', // Portugal
  UK = 'uk', // United Kingdom
  IE = 'ie', // Ireland
  SE = 'se', // Sweden
  NO = 'no', // Norway
  DK = 'dk', // Denmark
  FI = 'fi', // Finland
  CH = 'ch', // Switzerland
  AT = 'at', // Austria
  LU = 'lu', // Luxembourg
  PL = 'pl', // Poland
  CZ = 'cz', // Czech Republic
  HU = 'hu', // Hungary
  GR = 'gr', // Greece
  RO = 'ro', // Romania
  BG = 'bg', // Bulgaria
  HR = 'hr', // Croatia
  SI = 'si', // Slovenia
  SK = 'sk', // Slovakia
  LT = 'lt', // Lithuania
  LV = 'lv', // Latvia
  EE = 'ee', // Estonia
  US = 'us', // United States
}

const JURISDICTION_VALUES_SET = new Set<string>(Object.values(JurisdictionEnum));
export function getJurisdiction(jurisdiction: string): JurisdictionEnum {
  const normalized = jurisdiction.toLowerCase();
  if (JURISDICTION_VALUES_SET.has(normalized)) {
    return normalized as JurisdictionEnum;
  } else {
    return JurisdictionEnum.BE;
  }
}
