import { useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import classNames from '../../utils/classnames';

function addPercentage(base: number, addition: number): number {
  return Math.min(Math.round(base + addition), 100);
}

export interface IPieChartWithTableProps {
  data: Array<{ name: string; value: number }>;
  height: number;
}

export const PieChartWithTable: React.FC<IPieChartWithTableProps> = (props) => {
  const { data, height } = props;
  const [hoveredIdx, setHoveredIdx] = useState<number | null>(null);

  return (
    <div className="grid grid-cols-2">
      <ResponsiveContainer width="100%" height={height}>
        <PieChart>
          <Pie data={data} dataKey="value" nameKey="name">
            {data.map((entry, index) => {
              const addition = index === hoveredIdx ? 15 : 0;

              return (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    index % 2 === 0
                      ? `hsl(237, 76%, ${addPercentage(82, -addition)}%)`
                      : `hsl(0, 76%, ${addPercentage(85, -addition)}%)`
                  }
                  onMouseEnter={() => setHoveredIdx(index)}
                  onMouseLeave={() =>
                    setHoveredIdx((i) => {
                      if (i === index) {
                        return null;
                      }

                      return i;
                    })
                  }
                />
              );
            })}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>

      <div>
        <div className="grid grid-cols-2 gap-2 mb-2">
          <div className="font-medium">Name</div>
          <div className="font-medium">Value</div>
        </div>

        {data.map((entry, idx) => {
          console.log('hovered', idx === hoveredIdx);
          return (
            <div
              className={classNames('grid grid-cols-2 gap-2 mb-1 cursor-pointer', {
                'font-medium': idx === hoveredIdx,
              })}
              key={idx}
              onMouseEnter={() => setHoveredIdx(idx)}
              onMouseLeave={() =>
                setHoveredIdx((i) => {
                  if (i === idx) {
                    return null;
                  }

                  return i;
                })
              }
            >
              <div>{entry.name}</div>
              <div>{entry.value}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
