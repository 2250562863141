// !!! Used on both client and server, keep this in mind when making changes !!!

export interface ISentence {
  content: string;
  startIdx: number;
  endIdx: number;
}

export function cleanSentence(sentence: string): string {
  return sentence.replace(/[^\S\r\n]+/gm, ' ').trim();
}

export function getParagraphs(content: string): ISentence[] {
  const paragraphs = content.split('\n\n');
  const result: ISentence[] = [];
  let idx = 0;
  for (const paragraph of paragraphs) {
    result.push({
      content: cleanSentence(paragraph),
      startIdx: idx,
      endIdx: idx + paragraph.length,
    });
    idx += paragraph.length + 2;
  }
  return result;
}

export function getSentences(content: string): ISentence[] {
  const lines = content.split('\n');
  const sentences: ISentence[] = [];
  let idx = 0;
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i]!;
    let sentence = '';
    for (let i = 0; i < line.length; i++) {
      const char = line[i];
      if (char === '.' || char === '!' || char === '?' || char === '\n') {
        const nextChar = line[i + 1];
        if (nextChar === ' ') {
          const nextSentenceStart = line[i + 2];
          if (char !== '.' || nextSentenceStart !== nextSentenceStart?.toLowerCase()) {
            sentence += char;
            const cleanedSentence = cleanSentence(sentence);
            if (cleanedSentence.length > 0) {
              sentences.push({
                content: cleanedSentence,
                startIdx: idx,
                endIdx: idx + sentence.length,
              });
              idx += sentence.length;
            }
            sentence = '';
            continue;
          }
        }
      }
      sentence += char;
    }

    const cleanedSentence = cleanSentence(sentence);
    const addNewLine = i < lines.length - 1;
    if (cleanedSentence.length > 0) {
      sentences.push({
        content: cleanedSentence + (addNewLine ? '\n' : ''),
        startIdx: idx,
        endIdx: idx + sentence.length + (addNewLine ? 1 : 0),
      });
    }
    idx += sentence.length + 1;
  }

  return sentences;
}

export function splitSentences(content: string): Promise<string[]> {
  return Promise.resolve(getSentences(content).map((v) => v.content));
}
