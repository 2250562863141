import { createId } from '@paralleldrive/cuid2';
import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

export interface IChatRedirectProps {
  prefix?: string;
}

export const ChatRedirectComponent: React.FC<IChatRedirectProps> = (props) => {
  const { prefix = '' } = props;
  const [searchParams] = useSearchParams();

  searchParams.append('new', 'true');

  return <Navigate to={`${prefix}${createId()}?${searchParams.toString()}`} replace={true} />;
};
