import { createId } from '@paralleldrive/cuid2';
import toast from 'react-hot-toast';

import { getClient } from '../../../../contexts/websocket-context';
import { Emitter } from '../../../../utils/emitter';
import { IDocumentSearchMatch } from '../../websocket/chat/types';
import { IChatMessage } from './useChat';
import { DisposableStore } from '../../../../utils/disposable';
import { INewChatRequestMessage } from '../../websocket/chat/message-types';
import { IDocumentSearchFiltersValue } from '../types';

export class ChatStateHandler {
  private websocket = getClient();
  public filters: IDocumentSearchFiltersValue = {
    selectedCollectionIds: [],
    selectedDocumentIds: [],
  };
  public messages: IChatMessage[] = [];
  public isFetching: boolean = false;
  public isSubscribed: boolean = false;
  private subscriptionPromise: Promise<void> | null = null;

  public fetchingChangeEmitter = new Emitter<boolean>();
  public messagesChangedEmitter = new Emitter<IChatMessage[]>();
  public filtersChangedEmitter = new Emitter<IDocumentSearchFiltersValue>();

  constructor(
    public chatId: string,
    public teamId: string,
    public isNew: boolean,
    initialFilters: IDocumentSearchFiltersValue,
  ) {
    if (!this.isNew) {
      this.isFetching = true;
      this.subscribe().catch((err) => {
        console.error(chatId, { teamdId: this.teamId, isNew });

        toast.error(err.message);
      });
    } else {
      this.filters = initialFilters;
    }
  }

  private fireMessagesChanged() {
    this.messagesChangedEmitter.fire([...this.messages]);
  }

  private _setFilters(newFilters: IDocumentSearchFiltersValue) {
    this.filters = newFilters;
    this.filtersChangedEmitter.fire(newFilters);
  }

  setFilters(newFilters: IDocumentSearchFiltersValue) {
    this._setFilters(newFilters);
  }

  setFetchingState(newFetchingState: boolean) {
    this.isFetching = newFetchingState || this.messages.some((m) => !m.isFinished);
    this.fetchingChangeEmitter.fire(newFetchingState);
  }

  subscribe(): Promise<void> {
    if (this.isSubscribed) {
      return Promise.resolve();
    }

    if (!this.subscriptionPromise) {
      this.subscriptionPromise = this._subscribe();
      this.subscriptionPromise.finally(() => {
        this.isSubscribed = true;
      });
    }

    return this.subscriptionPromise;
  }

  _subscribe(): Promise<void> {
    const msgRef = createId();
    const disposableStore = new DisposableStore();

    return new Promise<void>((resolve, reject) => {
      let isResolved = false;

      this.setFetchingState(true);

      disposableStore.add(
        this.websocket.onMessage((message) => {
          if (message.ref === msgRef) {
            if (!isResolved && message.method === 'document/chat-sync-init') {
              resolve();
              isResolved = true;
            }

            switch (message.method) {
              case 'document/chat-sync-init': {
                const data = message.data;
                if (data.chatId === this.chatId) {
                  this.messages = message.data.messages
                    .sort((a, b) => a.sortIdx - b.sortIdx)
                    .map((m) => ({
                      userId: m.role === 'User' ? 'me' : 'system',
                      messageId: m.id,
                      status: '',
                      prevMessageId: m.prevMessageId ?? undefined,
                      content: m.content,
                      references: m.references,
                      documents: m.documents,
                      usage: m.usage,
                      isFinished: m.isFinished,
                      messagePartsHash: '',
                    }));
                  this.fireMessagesChanged();

                  this.filters.selectedCollectionIds = message.data.collectionIds;
                  this.filters.selectedDocumentIds = message.data.documentIds ?? [];
                  this.filtersChangedEmitter.fire(this.filters);

                  this.setFetchingState(false);
                }
                break;
              }
              case 'document/chat-sync-message-status': {
                const data = message.data;
                if (data.chatId === this.chatId) {
                  this.upsertMessage(
                    {
                      userId: 'system',
                      prevMessageId: data.prevMessageId,
                      messageId: data.messageId,
                      status: data.status,
                      content: '',
                      references: [],
                      documents: {},
                      usage: 0,
                      isFinished: false,
                      messagePartsHash: '',
                    },
                    true,
                  );
                }
                break;
              }
              case 'document/chat-sync-new-message': {
                const data = message.data;
                if (data.chatId === this.chatId) {
                  this.upsertMessage(
                    {
                      userId: data.userId ?? 'system',
                      prevMessageId: data.prevMessageId,
                      messageId: data.messageId,
                      status: '',
                      content: data.content,
                      references: [],
                      documents: {},
                      usage: data.usage,
                      isFinished: data.isFinished,
                      messagePartsHash: '',
                    },
                    true,
                  );
                }
                break;
              }
              case 'document/chat-sync-references': {
                const data = message.data;
                if (data.chatId === this.chatId) {
                  this.upsertMessage(
                    {
                      userId: 'system',
                      prevMessageId: data.prevMessageId,
                      messageId: data.messageId,
                      status: '',
                      content: '',
                      references: data.references,
                      documents: data.documents,
                      usage: 0,
                      isFinished: false,
                      messagePartsHash: '',
                    },
                    true,
                  );
                }
                break;
              }
              case 'document/chat-sync-message-parts-analysed': {
                const data = message.data;
                if (data.chatId === this.chatId) {
                  const msg = this.messages.find((m) => m.messageId === data.messageId);
                  if (msg) {
                    msg.messagePartsHash = createId();
                    this.fireMessagesChanged();
                  }
                }
                break;
              }
            }
          }
        }),
      );
      disposableStore.add(
        this.websocket.onErrorMessage((message) => {
          if (message.ref === msgRef) {
            if (!isResolved) {
              reject(new Error(message.error.message));
              isResolved = true;
            }
            disposableStore.dispose();
            this.setFetchingState(false);
          }
        }),
      );
      this.websocket.send({
        ref: msgRef,
        method: 'document/chat-sync-subscribe',
        data: {
          chatId: this.chatId,
        },
      });
    });
  }

  upsertMessage(newMessage: IChatMessage, validatePendingState: boolean) {
    const existingMessage = this.messages.find((m) => m.messageId === newMessage.messageId);
    if (!existingMessage) {
      if (newMessage.prevMessageId) {
        const prevMessageIndex = this.messages.findIndex((m) => m.messageId === newMessage.prevMessageId);
        if (prevMessageIndex >= 0) {
          const firstPart = this.messages.slice(0, prevMessageIndex + 1);
          const secondPart = this.messages.slice(prevMessageIndex + 1);
          this.messages = [...firstPart, newMessage, ...secondPart];
        } else {
          this.messages.push(newMessage);
        }
      } else {
        this.messages.push(newMessage);
      }
      this.fireMessagesChanged();
    } else {
      if (newMessage.content && existingMessage.content !== newMessage.content) {
        existingMessage.content = newMessage.content;
      }

      if (newMessage.status && existingMessage.status !== newMessage.status) {
        existingMessage.status = newMessage.status;
      }

      if (newMessage.usage) {
        existingMessage.usage = newMessage.usage;
      }

      if (newMessage.documents) {
        existingMessage.documents = {
          ...existingMessage.documents,
          ...newMessage.documents,
        };
      }

      if (newMessage.references.length > 0) {
        const references = new Map<
          string,
          {
            refId: number | null;
            documentId: string;
            matches: IDocumentSearchMatch[];
          }
        >();
        for (const reference of [...existingMessage.references, ...newMessage.references]) {
          if (!references.has(reference.documentId)) {
            references.set(reference.documentId, reference);
          } else {
            const existingReference = references.get(reference.documentId)!;
            const seenChunks = new Set<number>();
            references.set(reference.documentId, {
              documentId: reference.documentId,
              refId: reference.refId,
              matches: [...existingReference.matches, ...reference.matches].filter((v) => {
                if (seenChunks.has(v.chunkIdx)) {
                  return false;
                } else {
                  seenChunks.add(v.chunkIdx);
                  return true;
                }
              }),
            });
          }
        }
        existingMessage.references = [...references.values()];
      }

      if (newMessage.isFinished) {
        existingMessage.isFinished = true;
      }

      this.fireMessagesChanged();
    }

    if (validatePendingState) {
      const isFinished = this.messages.every((m) => m.isFinished);
      if (isFinished) {
        this.setFetchingState(false);
      }
    }
  }

  sendMessage(msgText: string) {
    this.setFetchingState(true);

    const message: INewChatRequestMessage['data'] = {
      chatId: this.chatId,
      messageId: createId(),
      prevMessageId: this.messages[this.messages.length - 1]?.messageId ?? undefined,
      content: msgText,
      teamId: this.teamId,
      documentIds: this.filters.selectedDocumentIds,
      collectionIds: this.filters.selectedCollectionIds,
    };

    const msgRef = createId();
    const disposableStore = new DisposableStore();
    disposableStore.add(
      this.websocket.onMessage((message) => {
        if (message.ref === msgRef) {
          if (this.isNew) {
            this.subscribe().catch((err) => {
              toast.error(err.message);
            });
            this.isNew = false;
          }
        }
      }),
    );
    disposableStore.add(
      this.websocket.onErrorMessage((message) => {
        if (message.ref === msgRef) {
          toast.error(message.error.message);
          disposableStore.dispose();
        }
      }),
    );
    this.websocket.send({
      ref: msgRef,
      method: 'document/chat-request',
      data: message,
    });

    this.upsertMessage(
      {
        userId: 'me',
        prevMessageId: message.prevMessageId,
        messageId: message.messageId,
        status: 'Finished',
        content: message.content,
        references: [],
        documents: {},
        usage: 0,
        isFinished: true,
        messagePartsHash: '',
      },
      false,
    );
  }
}

export class ChatStatesStore {
  chats = new Map<string, ChatStateHandler>();

  getChat(
    chatId: string,
    teamId: string,
    isNew: boolean,
    initialFilters: IDocumentSearchFiltersValue,
  ): ChatStateHandler {
    let chat = this.chats.get(chatId);
    if (!chat) {
      chat = new ChatStateHandler(chatId, teamId, isNew, initialFilters);
      this.chats.set(chatId, chat);
    }
    return chat;
  }
}

let _chatStates: ChatStatesStore | null = null;
export function getChatStates(): ChatStatesStore {
  if (!_chatStates) {
    _chatStates = new ChatStatesStore();
  }
  return _chatStates;
}
