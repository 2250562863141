import React from 'react';
import classNames from '@/utils/classnames';
import { BaseTextArea } from './BaseTextArea';

type BaseProps = React.InputHTMLAttributes<HTMLTextAreaElement>;

export interface TextAreaProps {
  id?: string;
  value: string;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onFocus?: () => void;
  onChange: (newValue: string) => void;
  isInvalid?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  rows?: number;
  cols?: number;
  className?: string;
  autoComplete?: BaseProps['autoComplete'];
  onKeyDown?: BaseProps['onKeyDown'];
}

export const TextArea: React.FC<TextAreaProps> = (props) => {
  const {
    id,
    value,
    onBlur,
    onFocus,
    onChange,
    isInvalid,
    isDisabled,
    placeholder,
    rows,
    cols,
    className,
    autoComplete,
    onKeyDown,
  } = props;

  return (
    <BaseTextArea
      id={id}
      rows={rows}
      cols={cols}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      placeholder={placeholder}
      onChange={(evt) => onChange(evt.target.value)}
      autoComplete={autoComplete}
      className={className}
      onKeyDown={onKeyDown}
      disabled={isDisabled}
    />
  );
};
