import React, { useState } from 'react';

import { getDisplayError } from '../../utils/get-display-error';
import { BaseButton } from '../button/BaseButton';
import { Button, ButtonProps } from '../button/Button';
import { DialogContent, DialogRoot } from './Dialog';

export interface IConfirmDialogProps {
  triggerSize?: ButtonProps['size'];
  triggerText: React.ReactNode;
  triggerVariant?: ButtonProps['variant'];
  title: string;
  description: React.ReactNode;
  cancelText?: string;
  submitText: string;
  isDisabled?: boolean;
  onSubmit: () => void | Promise<void>;
}

export const ConfirmDialog: React.FC<IConfirmDialogProps> = (props) => {
  const {
    triggerSize,
    triggerText,
    triggerVariant,
    title,
    cancelText = 'Cancel',
    submitText,
    description,
    onSubmit,
    isDisabled,
  } = props;
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await onSubmit();
      setOpen(false);
    } catch (err) {
      setError(getDisplayError(err));
    }
    setIsLoading(false);
  };

  return (
    <DialogRoot open={open} onOpenChange={setOpen}>
      <Button
        size={triggerSize}
        isLoading={isLoading}
        variant={triggerVariant}
        title={title}
        isDisabled={isDisabled}
        onTrigger={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();

          setOpen(true);
        }}
      >
        {triggerText}
      </Button>

      <DialogContent className="dialog-content">
        <h1 className="heading-one mb-4">{title}</h1>

        <div className="mb-4">{description}</div>

        <div className="text-sm mb-2 w-full">{error}</div>

        <div className="flex justify-between">
          <div>
            <BaseButton
              isLoading={isLoading}
              disabled={isLoading}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                setOpen(false);
              }}
            >
              {cancelText}
            </BaseButton>
          </div>
          <div>
            <BaseButton
              variant="primary"
              isLoading={isLoading}
              disabled={isLoading}
              onClick={(event) => {
                handleSubmit();

                event.preventDefault();
                event.stopPropagation();
              }}
            >
              {submitText}
            </BaseButton>
          </div>
        </div>
      </DialogContent>
    </DialogRoot>
  );
};
