import { Fragment } from 'react';
import { Link } from 'react-router-dom';

export interface IBreadcrumbItem {
  name: string;
  to?: string;
}

export interface IBreadcrumbProps {
  items: IBreadcrumbItem[];
}

export const Breadcrumb: React.FC<IBreadcrumbProps> = (props) => {
  const { items } = props;

  return (
    <div className="flex items-center">
      {items.map((item, idx) => {
        const isLast = idx >= items.length - 1;

        return (
          <Fragment key={idx}>
            {item.to && !isLast ? (
              <Link to={item.to} className="heading-one hover:text-blue-200 whitespace-nowrap">
                {item.name}
              </Link>
            ) : (
              <div className="heading-one truncate">{item.name}</div>
            )}
            {!isLast && <div className="heading-one mx-4">/</div>}
          </Fragment>
        );
      })}
    </div>
  );
};
