import { createId } from '@paralleldrive/cuid2';
import toast from 'react-hot-toast';

import { getClient } from '../../../../contexts/websocket-context';
import { Emitter } from '../../../../utils/emitter';
import { DisposableStore } from '../../../../utils/disposable';

export class WorkspaceState {
  private websocket = getClient();
  private companiesSubscribeDisposable = new DisposableStore();

  public companiesVersionHash = Date.now().toString(16);
  private companiesUpdateEmitter = new Emitter<string>();
  public onCompaniesUpdate = this.companiesUpdateEmitter.event;

  constructor(
    public workspaceId: string,
    public teamId: string,
  ) {
    this.websocket.onConnect(() => {
      this.subscribe().catch((err) => {
        toast.error(err.message);
      });
      this.updateCompaniesHash();
    });

    this.subscribe().catch((err) => {
      toast.error(err.message);
    });
    this.updateCompaniesHash();
    this.updateCompaniesHash();
  }

  private updateCompaniesHash(): void {
    this.companiesVersionHash = Date.now().toString(16);
    this.companiesUpdateEmitter.fire(this.companiesVersionHash);
  }

  private cleanupCompaniesSubscription() {
    this.companiesSubscribeDisposable.dispose();
    this.companiesSubscribeDisposable = new DisposableStore();
  }

  private cleanupSubscription() {
    this.cleanupCompaniesSubscription();
  }

  private subscribeCompanies(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const msgRef = createId();
      let isResolved = false;

      this.companiesSubscribeDisposable.add(
        this.websocket.onMessage((message) => {
          if (message.ref === msgRef) {
            if (!isResolved && message.method === 'workspace/subscribe-ack') {
              resolve();
              this.updateCompaniesHash();
              isResolved = true;
            }

            switch (message.method) {
              case 'workspace/companies-analysed': {
                this.updateCompaniesHash();
                break;
              }
            }
          }
        }),
      );
      this.companiesSubscribeDisposable.add(
        this.websocket.onErrorMessage((message) => {
          if (message.ref === msgRef) {
            if (!isResolved) {
              reject(new Error(message.error.message));
              isResolved = true;
            }
            this.cleanupCompaniesSubscription();
          }
        }),
      );
      this.websocket.send({
        ref: msgRef,
        method: 'workspace/subscribe',
        data: {
          workspaceId: this.workspaceId,
        },
      });
    });
  }

  private subscribe(): Promise<void> {
    this.cleanupSubscription();

    return this.subscribeCompanies();
  }
}

export class WorkspaceStatesStore {
  workspaces = new Map<string, WorkspaceState>();

  getState(workspaceId: string, teamId: string): WorkspaceState {
    let workspace = this.workspaces.get(workspaceId);
    if (!workspace) {
      workspace = new WorkspaceState(workspaceId, teamId);
      this.workspaces.set(workspaceId, workspace);
    }
    return workspace;
  }
}

let _workspaceStates: WorkspaceStatesStore | null = null;
export function getWorkspaceStates(): WorkspaceStatesStore {
  if (!_workspaceStates) {
    _workspaceStates = new WorkspaceStatesStore();
  }
  return _workspaceStates;
}
