import { Select } from './Select';

export interface ISimpleSelectItem {
  key: string;
  name: string;
}

export interface ISimpleSelectProps {
  placeholder?: string;
  items: ISimpleSelectItem[];
  selectedItem: ISimpleSelectItem | null;
  onSelect: (item: ISimpleSelectItem | null) => void;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onBlur?: () => void;
  isOptional?: boolean;
}

const display = (val: ISimpleSelectItem) => val.name;

export const SimpleSelect: React.FC<ISimpleSelectProps> = (props) => {
  const { placeholder, items, selectedItem, onSelect, isDisabled, isInvalid, isOptional } = props;

  return (
    <Select
      keyName="key"
      placeholder={placeholder}
      items={items}
      selectedItem={selectedItem}
      onSelect={onSelect}
      display={display}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      isOptional={isOptional}
    />
  );
};
