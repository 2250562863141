import { FallbackProps } from 'react-error-boundary';

import { Button } from './button/Button';

export const ErrorFallback: React.FC<FallbackProps> = (props: FallbackProps) => {
  const { error, resetErrorBoundary } = props;

  return (
    <div className="w-full h-full p-8">
      <h1 className="text-red-600 font-medium text-lg mb-2 border-b-2">Oops, something went wrong</h1>
      <h2 className="text-gray-600 font-medium">Message: {error.message}</h2>

      <div className="my-4">
        <Button onTrigger={() => resetErrorBoundary()}>Reset application</Button>
      </div>
    </div>
  );
};
