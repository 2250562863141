import { ArrowRightIcon } from 'lucide-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { Button } from '../../../components/button/Button';
import { PageHeader } from '../../../components/PageHeader';
import { SpinnerBlock } from '../../../components/Spinner';
import type { ResponseType as MeResponse } from '../../user/endpoints/MeEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';

export const LoginPage = () => {
  const navigate = useNavigate();
  const { data, isLoading: isLoadingMe, mutate: mutateMe } = useSWR<MeResponse>('/api/v1/user/me', fetchEndpointData);

  useEffect(() => {
    if (data?.me) {
      navigate('/app/t');
    }
  }, [data?.me]);

  if (isLoadingMe) {
    return <SpinnerBlock message="Loading..." className="h-screen" />;
  }

  const redirectUri = window.location.origin;
  return (
    <div className="py-8 min-h-screen">
      <PageHeader title="Login" />

      <main className="sm:rounded-md sm:mt-16 max-w-lg mx-auto p-8 bg-gray-100">
        <h1 className="heading-one mb-8">Login</h1>

        <div className="my-4">
          <Button
            type="button"
            onTrigger={() => {
              window.location.href = `/api/auth/google/redirect?redirectUri=${encodeURI(redirectUri)}`;
            }}
          >
            <div className="w-full flex items-center justify-between">
              <span>Login met Google</span>
              <ArrowRightIcon className="h-4 w-5" />
            </div>
          </Button>
        </div>

        <div className="my-4">
          <Button
            type="button"
            onTrigger={() => {
              window.location.href = `/api/auth/microsoft/redirect?redirectUri=${encodeURI(redirectUri)}`;
            }}
          >
            <div className="w-full flex items-center justify-between">
              <span>Login met Microsoft</span>
              <ArrowRightIcon className="h-4 w-5" />
            </div>
          </Button>
        </div>
      </main>

      <footer className="flex justify-center items-center gap-4 flex-wrap my-8">
        <a
          href="https://docs.google.com/document/d/1VfBoGVguSoj5-r8NOcq72qZdGD0yoRx5E04drLrPgiM/edit?usp=sharing"
          className="hover:underline"
        >
          Terms and conditions
        </a>
        <div>|</div>
        <a
          href="https://docs.google.com/document/d/1yZ_9gworSTk6LNkXqVTYscd_9HoSYaL0g5w9HEpdTh4/edit?usp=sharing"
          className="hover:underline"
        >
          Acceptable Use Policy
        </a>
        <div>|</div>
        <a
          href="https://docs.google.com/document/d/1T33zx7ocKqyOQfEk_VS2I2o-y_qd3Y54lHKPK7SYvcg/edit?usp=sharing"
          className="hover:underline"
        >
          Privacy Policy
        </a>
      </footer>
    </div>
  );
};
