import { ISimpleSelectItem } from '../../components/select/SimpleSelect';
import { AuthRoles } from './auth-roles';

export const ROLE_OPTIONS: ISimpleSelectItem[] = [
  {
    key: '' + AuthRoles.Viewer,
    name: 'Viewer',
  },
  {
    key: '' + AuthRoles.Editor,
    name: 'Editor',
  },
  {
    key: '' + AuthRoles.Admin,
    name: 'Admin',
  },
  {
    key: '' + AuthRoles.Owner,
    name: 'Owner',
  },
];
