import { DocumentTypeEnum } from './enums';
import { JurisdictionEnum } from './prompts/constants';

export const DOCUMENT_TYPE_OPTIONS: Array<{ key: DocumentTypeEnum; name: string }> = [
  {
    key: DocumentTypeEnum.CaseLaw,
    name: 'Case Law',
  },
  {
    key: DocumentTypeEnum.Legislation,
    name: 'Legislation',
  },
  {
    key: DocumentTypeEnum.LegalLiterature,
    name: 'Legal Literature',
  },
  {
    key: DocumentTypeEnum.Contract,
    name: 'Contract',
  },
  {
    key: DocumentTypeEnum.Advice,
    name: 'Advice',
  },
  {
    key: DocumentTypeEnum.Other,
    name: 'Other',
  },
];

export function getDocumentTypeName(type: string): string {
  return DOCUMENT_TYPE_OPTIONS.find((option) => option.key === type)?.name || type;
}

export const JURISDICTION_NAMES: Record<JurisdictionEnum, string> = {
  [JurisdictionEnum.BE]: 'Belgium',
  [JurisdictionEnum.FR]: 'France',
  [JurisdictionEnum.NL]: 'Netherlands',
  [JurisdictionEnum.DE]: 'Germany',
  [JurisdictionEnum.IT]: 'Italy',
  [JurisdictionEnum.ES]: 'Spain',
  [JurisdictionEnum.PT]: 'Portugal',
  [JurisdictionEnum.UK]: 'United Kingdom',
  [JurisdictionEnum.IE]: 'Ireland',
  [JurisdictionEnum.SE]: 'Sweden',
  [JurisdictionEnum.NO]: 'Norway',
  [JurisdictionEnum.DK]: 'Denmark',
  [JurisdictionEnum.FI]: 'Finland',
  [JurisdictionEnum.CH]: 'Switzerland',
  [JurisdictionEnum.AT]: 'Austria',
  [JurisdictionEnum.LU]: 'Luxembourg',
  [JurisdictionEnum.PL]: 'Poland',
  [JurisdictionEnum.CZ]: 'Czech Republic',
  [JurisdictionEnum.HU]: 'Hungary',
  [JurisdictionEnum.GR]: 'Greece',
  [JurisdictionEnum.RO]: 'Romania',
  [JurisdictionEnum.BG]: 'Bulgaria',
  [JurisdictionEnum.HR]: 'Croatia',
  [JurisdictionEnum.SI]: 'Slovenia',
  [JurisdictionEnum.SK]: 'Slovakia',
  [JurisdictionEnum.LT]: 'Lithuania',
  [JurisdictionEnum.LV]: 'Latvia',
  [JurisdictionEnum.EE]: 'Estonia',
  [JurisdictionEnum.US]: 'United States',
};
