import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  CalendarIcon,
  CheckCheckIcon,
  CheckIcon,
  FileIcon,
  FolderIcon,
  PlusIcon,
  Settings2Icon,
  TrashIcon,
  TriangleAlertIcon,
  UploadIcon,
  XIcon,
} from 'lucide-react';
import classNames from '@/utils/classnames';
import toast from 'react-hot-toast';
import useSWR from 'swr';

import { useTeam } from '@/app/team/context/TeamContext';
import { formatDate } from '../../../utils/date';
import { SpinnerBlock } from '../../../components/Spinner';
import { Pagination } from '../../../components/Pagination';
import { Input } from '../../../components/input/Input';
import { getDisplayError } from '../../../utils/get-display-error';
import { Button } from '../../../components/button/Button';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import { MoveDialog } from './MoveDialog';
import { getDocumentTypeName } from '../constants.client';
import { Tag } from '../../../components/Tag';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { BulkDocumentMetadataDialog } from './BulkMetadataDialog';
import { useExplorerTree } from '../../explorerTree/contexts/ExplorerContext';
import { numberAwareTextSort } from '../../../utils/text';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type { BodyType as DeleteDocumentsPayload } from '../endpoints/DeleteDocumentsEndpoint';
import type { BodyType as DeleteDocumentCollectionsPayload } from '../../documentCollection/endpoints/DeleteDocumentCollectionsEndpoint';
import type {
  BodyType as SearchEntriesPayload,
  ResponseType as SearchResultsResponseType,
} from '../../explorerTree/endpoints/SearchExplorerEntriesEndpoint';

export type Entry = {
  id: string;
};

export type EntryWithSearchData = Entry & {
  _searchData?: {
    hits: number;
    score: number;
    chunkIds: string[];
  };
};

export interface IStyledCheckboxProps {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}

const StyledCheckbox: React.FC<IStyledCheckboxProps> = (props) => {
  const { isChecked, onChange } = props;

  return (
    <div
      className="p-2"
      onClick={(evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        onChange(!isChecked);
      }}
    >
      <div
        className={classNames('relative h-4 w-4 rounded cursor-default flex items-center justify-center', {
          'bg-primary-800 text-white': isChecked,
          'bg-gray-300 text-gray-300': !isChecked,
        })}
      >
        <CheckIcon
          className="h-3 w-3"
          style={{
            top: 1,
            left: 1,
          }}
        />
      </div>
    </div>
  );
};

interface IEntriesProps {
  selectedEntries: string[];
  setSelectedEntries: (newValues: string[]) => void;
  entries: EntryWithSearchData[];
}

const Entries: React.FC<IEntriesProps> = (props) => {
  const { selectedEntries, setSelectedEntries, entries } = props;
  const { team } = useTeam();
  const { tree: explorerTree } = useExplorerTree();

  return (
    <div className="grid gap-1">
      {entries.map((_entry) => {
        const entry = explorerTree.entries.get(_entry.id);
        if (!entry) {
          return null;
        }

        const searchData = _entry._searchData;
        const document = entry.document;
        const collection = entry.collection;
        const isSelected = !!selectedEntries.find((v) => v === entry.id);

        const selectEntry = () => {
          if (!isSelected) {
            setSelectedEntries([...selectedEntries, entry.id]);
          } else {
            setSelectedEntries(selectedEntries.filter((id) => id !== entry.id));
          }
        };

        const documentType = document?.documentType ?? collection?.documentType;
        const categories = document?.categories ?? collection?.categories ?? [];
        const language = document?.language ?? collection?.language;
        const jurisdiction = document?.jurisdiction ?? collection?.jurisdiction;

        return (
          <Link
            className="grid pr-4 card-no-padding cursor-pointer"
            style={{ gridTemplateColumns: 'auto 1fr auto' }}
            data-clickable="true"
            to={
              document
                ? `/app/t/${team.id}/documents/${entry.parentCollectionId}/${document.id}/?highlightChunks=${(searchData?.chunkIds || []).slice(0, 5).join(';')}`
                : `/app/t/${team.id}/documents/${collection!.id}/`
            }
            key={`entry-${entry.id}-${!!isSelected}`}
            onClick={(evt) => {
              if (selectedEntries.length) {
                evt.preventDefault();
                evt.stopPropagation();

                selectEntry();
              }
            }}
          >
            <div className="flex items-center">
              <StyledCheckbox onChange={selectEntry} isChecked={!!isSelected} />
            </div>

            <div className="flex items-center gap-2 min-w-0 py-1 pr-4">
              <div>
                {document ? (
                  <FileIcon className="button-icon file-icon-style" />
                ) : (
                  <FolderIcon className="button-icon folder-icon-style" />
                )}
              </div>
              <div className="overflow-hidden whitespace-nowrap overflow-ellipsis">{entry.name}</div>

              <div className="hidden lg:flex gap-2 items-center">
                {documentType && (
                  <Tooltip text="Document Type">
                    <div>
                      <Tag color="blue">{getDocumentTypeName(documentType)}</Tag>
                    </div>
                  </Tooltip>
                )}
                {categories.map((v) => {
                  return (
                    <Tooltip text="Category" key={v.id}>
                      <div>
                        <Tag color="green">{v.name}</Tag>
                      </div>
                    </Tooltip>
                  );
                })}
                {language && (
                  <Tooltip text="Language">
                    <div>
                      <Tag color="red">{language.toUpperCase()}</Tag>
                    </div>
                  </Tooltip>
                )}
                {jurisdiction && (
                  <Tooltip text="Jurisdiction">
                    <div>
                      <Tag color="red">{jurisdiction.toUpperCase()}</Tag>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>

            <div className="flex gap-2 items-center py-1">
              {(document?.ocrConfidence ?? 1) < 0.8 && (
                <Tooltip text="Document hard to read">
                  <div>
                    <TriangleAlertIcon className="text-red-400" size={16} />
                  </div>
                </Tooltip>
              )}
              {searchData && (
                <Tooltip text={`Found ${searchData.hits} relevant chunks in this document`} key={entry.id}>
                  <div>
                    <Tag color="orange">{`${searchData.hits} hits`}</Tag>
                  </div>
                </Tooltip>
              )}
              <div>
                <CalendarIcon className="button-icon" />
              </div>
              {formatDate(document ? (document.date ?? document.createdAt) : collection?.createdAt)}
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export interface IPaginatedExplorerProps {
  isReadOnly: boolean;
  parentCollectionId?: string | null;
  canUploadDocuments?: boolean;
  selectedEntries: string[];
  setSelectedEntries: (newValues: string[]) => void;
  setEntries: (newValues: Entry[]) => void;
  pageSize?: number;
}

const PaginatedExplorer: React.FC<IPaginatedExplorerProps> = (props) => {
  const {
    isReadOnly,
    canUploadDocuments,
    parentCollectionId,
    selectedEntries,
    setSelectedEntries,
    setEntries,
    pageSize = 100,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const { tree: explorerTree, treeKey } = useExplorerTree();
  const [pageCursor, _setPageCursor] = useState<string | null>(() => {
    return searchParams.get('pageCursor');
  });

  const setPageCursor = useCallback(
    (newPageCursor: string | null) => {
      _setPageCursor(newPageCursor);
      setSearchParams((prev) => {
        if (!newPageCursor) {
          prev.delete('pageCursor');
        } else {
          prev.set('pageCursor', newPageCursor);
        }
        return prev;
      });
    },
    [_setPageCursor, setSearchParams],
  );

  const { entries, nextCursor, prevCursor } = useMemo(() => {
    const collection = parentCollectionId ? explorerTree.getCollectionNode(parentCollectionId) : explorerTree.root;
    const children = collection?.children.sort((a, b) => numberAwareTextSort(a.name, b.name)) ?? [];
    const foundStartIdx = pageCursor ? children.findIndex((v) => v.id === pageCursor) : 0;

    const results = children.slice(foundStartIdx, foundStartIdx + pageSize).map((v) => {
      return {
        id: v.id,
        name: v.name,
      };
    });

    const hasNext = foundStartIdx + pageSize < children.length;
    const hasPrevious = foundStartIdx > 0;

    return {
      entries: results,
      hasPrevious: hasPrevious,
      hasNext: hasNext,
      nextCursor: hasNext ? children[foundStartIdx + pageSize]?.id : null,
      prevCursor: hasPrevious ? children[foundStartIdx - 1]?.id : null,
    };
  }, [pageCursor, treeKey, parentCollectionId]);

  const nextPage = useCallback(() => {
    setPageCursor(nextCursor ?? null);
  }, [setPageCursor, nextCursor]);

  const prevPage = useCallback(() => {
    setPageCursor(prevCursor ?? null);
  }, [setPageCursor, prevCursor]);

  useEffect(() => {
    const newEntryIds = entries.map((entry) => entry.id);
    setEntries(entries);
    setSelectedEntries(selectedEntries.filter((v) => newEntryIds.includes(v)));
  }, [entries]);

  const isFetching = explorerTree.isSyncing;
  return (
    <>
      <Entries selectedEntries={selectedEntries} setSelectedEntries={setSelectedEntries} entries={entries} />

      {!entries.length && isFetching && (
        <div>
          <SpinnerBlock message="Loading..." className="h-screen" />
        </div>
      )}

      {!entries.length && !isFetching && (
        <div className="flex flex-col items-center gap-4 my-8">
          <img
            src="/static/illustrations/get-started.svg"
            alt="No search results"
            style={{ maxWidth: '16rem', maxHeight: '16rem' }}
          />
          <div className="font-medium">
            {canUploadDocuments ? 'No documents or collections found' : 'No collections found'}
          </div>
          <div className="flex gap-4">
            {!isReadOnly && (
              <Link
                className="card flex flex-col items-center justify-center"
                data-clickable="true"
                to="create-collection"
              >
                <div>
                  <PlusIcon className="button-icon" />
                </div>
                <div className="card-heading">Create a new collection</div>
              </Link>
            )}
            {canUploadDocuments && !isReadOnly && (
              <Link className="card flex flex-col items-center justify-center" data-clickable="true" to="upload">
                <div>
                  <UploadIcon className="button-icon" />
                </div>
                <div className="card-heading">Upload a new document</div>
              </Link>
            )}
          </div>
        </div>
      )}

      {!!entries.length && (!!prevCursor || !!nextCursor) ? (
        <div className="py-4">
          <Pagination
            hasPrevious={!!prevCursor}
            previous={prevPage}
            hasNext={!!nextCursor}
            next={nextPage}
            isFetching={false}
          />
        </div>
      ) : (
        <div className="h-4" />
      )}
    </>
  );
};

async function fetchSearchResults([url, { teamId, searchQuery, take, collectionIds }]: [
  string,
  { teamId: string; searchQuery: string; take: number; collectionIds: (string | null)[] },
]) {
  const payload: SearchEntriesPayload = {
    q: searchQuery,
    teamId,
    take,
    collectionIds,
  };
  const result = await fetchEndpointData<SearchResultsResponseType>(url, {
    method: 'POST',
    body: payload,
  });
  return result;
}

export interface ISearchResultsExplorerProps {
  searchQuery: string;
  parentCollectionId?: string | null;
  selectedEntries: string[];
  setSelectedEntries: (newValues: string[]) => void;
  setEntries: (newValues: Entry[]) => void;
}

const SearchResultsExplorer: React.FC<ISearchResultsExplorerProps> = (props) => {
  const { searchQuery, parentCollectionId, selectedEntries, setSelectedEntries, setEntries } = props;
  const { team } = useTeam();
  const { data, isLoading, error } = useSWR(
    [
      `/api/v1/explorer-tree/search`,
      {
        teamId: team.id,
        searchQuery: searchQuery.trim().toLowerCase(),
        take: 50,
        collectionIds: parentCollectionId ? [parentCollectionId] : [null],
      },
    ],
    fetchSearchResults,
  );

  const remappedEntries = useMemo(() => {
    const entries = data?.results ?? [];
    return entries.map((v) => {
      return {
        id: v.explorerEntryId,
        _searchData: {
          hits: v.hits,
          score: v.score,
          chunkIds: v.chunkIds,
        },
      };
    });
  }, [data]);

  useEffect(() => {
    setEntries(remappedEntries);
    setSelectedEntries([]);
  }, [remappedEntries]);

  if (!remappedEntries.length) {
    if (isLoading) {
      return (
        <div>
          <SpinnerBlock message="Loading..." />
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex flex-col items-center gap-4 my-8">
          <div className="font-medium">Something went wrong searching for documents and collections</div>
          <div>{getDisplayError(error)}</div>
        </div>
      );
    }

    if (!error) {
      return (
        <div className="flex flex-col items-center gap-8 my-8">
          <img
            src="/static/illustrations/no-results.svg"
            alt="No search results"
            style={{ maxWidth: '16rem', maxHeight: '16rem' }}
          />
          <div className="font-medium">No search results found</div>
        </div>
      );
    }
  }

  return (
    <Entries selectedEntries={selectedEntries} setSelectedEntries={setSelectedEntries} entries={remappedEntries} />
  );
};

export interface IDocumentExplorerProps {
  parentCollectionId?: string | null;
  canUploadDocuments: boolean;
  isReadOnly: boolean;
}

export const DocumentExplorer: React.FC<IDocumentExplorerProps> = (props) => {
  const { parentCollectionId, canUploadDocuments, isReadOnly } = props;
  const { team } = useTeam();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(searchParams.get('search') ?? '');
  const [confirmedSearchValue, _setConfirmedSearchValue] = useState(searchParams.get('search') ?? '');
  const [selectedEntries, setSelectedEntries] = useState<string[]>([]);
  const [entries, setEntries] = useState<Entry[]>([]);
  const { tree: explorerTree, treeKey } = useExplorerTree();
  const [searchKey, setSearchKey] = useState(treeKey);

  const setConfirmedSearchValue = (val: string) => {
    _setConfirmedSearchValue(val);
    setSearchKey(treeKey);
  };

  const selectedDocumentIds: string[] = useMemo(() => {
    return entries
      .filter((entry) => selectedEntries.includes(entry.id))
      .map((_entry) => {
        const entry = explorerTree.entries.get(_entry.id);
        return entry?.document?.id;
      })
      .filter(Boolean) as string[];
  }, [entries, selectedEntries, treeKey]);

  const selectedDocumentCollectionIds: string[] = useMemo(() => {
    return entries
      .filter((entry) => selectedEntries.includes(entry.id))
      .map((_entry) => {
        const entry = explorerTree.entries.get(_entry.id);
        return entry?.collection?.id;
      })
      .filter(Boolean) as string[];
  }, [entries, selectedEntries, treeKey]);

  const [lastSearch, setLastSearch] = useState(Date.now());
  useEffect(() => {
    if (Date.now() - lastSearch < 500 || !confirmedSearchValue) return;

    setLastSearch(Date.now());
  }, [confirmedSearchValue]);

  return (
    <div>
      <div className="flex gap-2 mb-2">
        <Input
          variant="inverted"
          placeholder="Search for documents or collections..."
          type="text"
          value={searchValue}
          onChange={(val) => {
            setSearchValue(val);
            if (val.length < 1) {
              setConfirmedSearchValue('');
            }
          }}
          onKeyDown={(evt) => {
            if (evt.key === 'Enter') {
              setSearchParams((prev) => {
                prev.set('search', searchValue);
                return prev;
              });
              setConfirmedSearchValue(searchValue);
            }
          }}
        />

        {!isReadOnly && (
          <>
            <MoveDialog
              selectedCollectionIds={selectedDocumentCollectionIds}
              selectedDocumentIds={selectedDocumentIds}
              onComplete={() => {
                setSelectedEntries([]);
              }}
            />

            <BulkDocumentMetadataDialog
              key={treeKey}
              triggerText={<Settings2Icon className="button-icon" />}
              selectedDocumentIds={selectedDocumentIds}
              selectedCollectionIds={selectedDocumentCollectionIds}
            />

            <ConfirmDialog
              triggerText={<TrashIcon className="button-icon" />}
              title="Delete documents"
              submitText="Delete"
              triggerVariant="destructive"
              description={`Are you sure you want to delete these documents?`}
              isDisabled={!selectedEntries.length}
              onSubmit={async () => {
                try {
                  if (selectedDocumentIds.length) {
                    const payload: DeleteDocumentsPayload = {
                      documentIds: selectedDocumentIds,
                      teamId: team.id,
                    };
                    await fetchEndpointData('/api/v1/document/delete', {
                      method: 'DELETE',
                      body: payload,
                    });
                  }

                  if (selectedDocumentCollectionIds.length) {
                    const payload: DeleteDocumentCollectionsPayload = {
                      teamId: team.id,
                      documentCollectionIds: selectedDocumentCollectionIds,
                    };
                    await fetchEndpointData(`/api/v1/document-collection/delete`, {
                      method: 'DELETE',
                      body: payload,
                    });
                  }

                  setSelectedEntries([]);

                  toast.success('Deletions have been queued');
                } catch (err) {
                  toast.error('Could not queue deletions: ' + getDisplayError(err));
                }
              }}
            />

            <Button
              isDisabled={!entries.length}
              onTrigger={() => {
                if (!selectedEntries.length) {
                  setSelectedEntries(entries.map((entry) => entry.id));
                } else {
                  setSelectedEntries([]);
                }
              }}
            >
              {selectedEntries.length > 0 ? (
                <XIcon className="button-icon" />
              ) : (
                <CheckCheckIcon className="button-icon" />
              )}
            </Button>
          </>
        )}
      </div>

      {confirmedSearchValue.length > 0 ? (
        <SearchResultsExplorer
          key={`${searchKey}-${confirmedSearchValue}`}
          parentCollectionId={parentCollectionId}
          searchQuery={confirmedSearchValue}
          selectedEntries={selectedEntries}
          setSelectedEntries={setSelectedEntries}
          setEntries={setEntries}
        />
      ) : (
        <PaginatedExplorer
          parentCollectionId={parentCollectionId}
          canUploadDocuments={canUploadDocuments}
          selectedEntries={selectedEntries}
          setSelectedEntries={setSelectedEntries}
          setEntries={setEntries}
          isReadOnly={isReadOnly}
        />
      )}
    </div>
  );
};
