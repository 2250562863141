import classNames from '@/utils/classnames';

export type TagColor = 'blue' | 'orange' | 'green' | 'red';

export interface ITagProps {
  children: string;
  className?: string;
  color: TagColor;
}

export const Tag: React.FC<ITagProps> = (props) => {
  const { children, className, color } = props;

  return (
    <div
      className={classNames('px-2 rounded inline-flex justify-center items-center text-sm', className, {
        'bg-accent-one text-dark-500': color === 'blue',
        'bg-accent-two text-dark-500': color === 'green',
        'bg-accent-three text-dark-500': color === 'orange',
        'bg-accent-four text-dark-500': color === 'red',
      })}
    >
      {children}
    </div>
  );
};
