import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { useTeam } from '@/app/team/context/TeamContext';
import { PageHeader } from '../../../components/PageHeader';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { getDisplayError } from '../../../utils/get-display-error';
import { InputField } from '../../../components/input/InputField';
import { Button } from '../../../components/button/Button';
import { DocumentPickerDialogWithTrigger } from '../../document/components/DocumentPicker';
import { ExplorerTreeEntry } from '../../explorerTree/explorer-tree';
import type {
  BodyType as CreateWorkspacePayload,
  ResponseType as CreateWorkspaceResponseType,
} from '../endpoints/CreateWorkspaceEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';

export const CreateWorkspacePage = () => {
  const { team } = useTeam();
  const navigate = useNavigate();
  const [showFolderPicket, setShowFolderPicker] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState<ExplorerTreeEntry | null>(null);

  const title = 'Create workspace';
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="h-full">
        <div className="flex justify-between items-center mb-4">
          <Breadcrumb
            items={[
              {
                name: 'Workspaces',
                to: '..',
              },
              {
                name: title,
              },
            ]}
          />
        </div>

        <div className="card">
          <Formik
            initialValues={{
              name: '',
              documentCollectionId: '',
            }}
            onSubmit={async (values) => {
              try {
                if (!values.documentCollectionId) {
                  throw new Error('No document collection selected');
                }

                const payload: CreateWorkspacePayload = {
                  teamId: team.id,
                  data: {
                    name: values.name,
                    documentCollectionId: values.documentCollectionId,
                  },
                };
                const result = await fetchEndpointData<CreateWorkspaceResponseType>('/api/v1/workspace/create', {
                  method: 'POST',
                  body: payload,
                });
                navigate(`../${result.workspace.id}`);
                toast.success('Workspace created successfully');
              } catch (err) {
                console.error(err);
                toast.error('Failed to create workspace: ' + getDisplayError(err));
              }
            }}
          >
            {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <InputField type="text" name="name" labelText="Name" />

                <div className="mb-8">
                  <DocumentPickerDialogWithTrigger
                    triggerText={selectedCollection ? `Selection: ${selectedCollection.name}` : 'Select a folder'}
                    isDisabled={isSubmitting}
                    title="Select a folder"
                    open={showFolderPicket}
                    onOpenChange={setShowFolderPicker}
                    multiSelect={false}
                    onlyCollections={true}
                    onSubmit={(selectedNodes) => {
                      const selectedNode = selectedNodes.pop() ?? null;
                      const selectedId = selectedNode?.collection?.id ?? null;
                      if (!selectedId) {
                        setFieldValue('documentCollectionId', null);
                        setSelectedCollection(null);
                        setShowFolderPicker(false);
                        return;
                      }

                      setFieldValue('documentCollectionId', selectedId);
                      setSelectedCollection(selectedNode);
                      setShowFolderPicker(false);
                    }}
                  />
                </div>

                <Button variant="primary" type="submit" isDisabled={isSubmitting} isLoading={isSubmitting}>
                  Create workspace
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
