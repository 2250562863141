import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { PageHeader } from '../../../../components/PageHeader';
import { Breadcrumb } from '../../../../components/Breadcrumb';
import { WorkspaceProvider, useWorkspace } from './WorkspaceContext';
import { Tabs } from '../../../../components/tabs/Tabs';
import { StatusDot } from '../../../../components/StatusDot';
import { ConfirmDialog } from '../../../../components/dialog/ConfirmDialog';
import { getDisplayError } from '../../../../utils/get-display-error';
import { fetchEndpointData } from '../../../../utils/fetch.client';
import type { BodyType as DeleteWorkspacePayload } from '../../endpoints/DeleteWorkspaceEndpoint';

const WorkspacePageContent = () => {
  const { workspace, processingState } = useWorkspace();
  const navigate = useNavigate();

  const title = workspace.name;
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Workspaces',
              to: '..',
            },
            {
              name: title,
            },
          ]}
        />

        <div className="flex gap-4 items-center">
          {processingState.processedCount < processingState.totalCount && (
            <div className="text-nowrap flex items-center gap-2">
              <StatusDot color="blue" pulse={true} size={3} />
              {`Processed ${processingState.processedCount} of ${processingState.totalCount} files`}
            </div>
          )}

          <ConfirmDialog
            triggerText="Delete"
            title="Delete workspace"
            submitText="Delete"
            triggerVariant="destructive"
            description={`Are you sure you want to delete the workspace ${workspace.name}?`}
            onSubmit={async () => {
              try {
                const payload: DeleteWorkspacePayload = {
                  workspaceId: workspace.id,
                };
                await fetchEndpointData(`/api/v1/workspace/delete`, {
                  method: 'DELETE',
                  body: payload,
                });
                toast.success('Workspace has been deleted');
                navigate('..');
              } catch (err) {
                toast.error('Could not delete workspace: ' + getDisplayError(err));
              }
            }}
          />
        </div>
      </div>

      <div>
        <Tabs
          items={[
            { title: 'Overview', path: 'overview' },
            { title: 'Documents', path: 'documents' },
            { title: 'Companies', path: 'companies' },
            { title: 'Preset runs', path: 'preset-runs' },
          ]}
        />
      </div>
    </div>
  );
};

export const WorkspacePage = () => {
  return (
    <WorkspaceProvider>
      <WorkspacePageContent />
    </WorkspaceProvider>
  );
};
