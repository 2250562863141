import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { nullthrows } from '../../../../../../utils/invariant';
import { getPresetRunStates } from './PresetRunStates';
import { useTeam } from '@/app/team/context/TeamContext';
import { DocumentPresetRunPage } from './DocumentPresetRunResults';
import { CompanyPresetRunPage } from './CompanyPresetRunResults';
import { useThrottle } from '../../../../../../hooks/useThrottle';
import { SpinnerBlock } from '../../../../../../components/Spinner';
import { GlobalPresetRunPage } from './GlobalPresetRunResults';
import { fetchEndpointData } from '../../../../../../utils/fetch.client';
import type { ResponseType as WorkspacePresetRunResponseType } from '../../../../endpoints/WorkspacePresetRunEndpoint';
import { QuestionsPresetType } from '../../../../../questionsPreset/enums';

export const PresetRunPage = () => {
  const { presetRunId: _presetRunId } = useParams<{ presetRunId: string }>();
  const presetRunId = nullthrows(_presetRunId, 'Preset run id not defined');

  const { team } = useTeam();
  const state = getPresetRunStates().getState(presetRunId, team.id);
  const [fetchKey, setFetchKey] = useState(state.updatedHash);
  useEffect(() => {
    const changeDisposable = state.onUpdate((newHash) => {
      setFetchKey(newHash);
    });
    return () => {
      changeDisposable.dispose();
    };
  }, [state]);

  const {
    data,
    isLoading,
    mutate: refetchPresetData,
  } = useSWR<WorkspacePresetRunResponseType>(`/api/v1/workspace/preset-run/${presetRunId}`, fetchEndpointData);
  useThrottle(refetchPresetData, fetchKey, 1000);

  if (!data?.presetRun && isLoading) {
    return <SpinnerBlock message="Loading preset run..." />;
  }

  const presetRun = nullthrows(data?.presetRun, 'Preset run not found');
  switch (presetRun.type) {
    case QuestionsPresetType.Document:
      return <DocumentPresetRunPage presetRun={presetRun} />;
    case QuestionsPresetType.Company:
      return <CompanyPresetRunPage presetRun={presetRun} />;
    case QuestionsPresetType.Global:
      return <GlobalPresetRunPage presetRun={presetRun} />;
    default:
      return <div>Not implemented yet...</div>;
  }
};
