export class Throttle {
  private lastExecution = 0;
  private timeoutRef: NodeJS.Timeout | null = null;
  private ms: number;
  private callback: () => void;

  constructor(callback: () => void, ms: number) {
    this.ms = ms;
    this.callback = callback;
    this.lastExecution = 0;
    this.timeoutRef = null;
  }

  execute(): void {
    if (Date.now() - this.lastExecution >= this.ms) {
      this.callback();
      this.lastExecution = Date.now();
    } else if (!this.timeoutRef) {
      this.timeoutRef = setTimeout(
        () => {
          this.lastExecution = Date.now();
          this.timeoutRef = null;
          this.callback();
        },
        this.ms - (Date.now() - this.lastExecution),
      );
    }
  }

  clear() {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
      this.timeoutRef = null;
    }

    this.lastExecution = Date.now();
  }
}
