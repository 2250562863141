import { useMemo } from 'react';
import { useTeam } from '@/app/team/context/TeamContext';
import { useAuth } from '../contexts/auth-context';

export function useRole(): number {
  const { myTeams } = useAuth();
  const { team } = useTeam();

  return useMemo(() => {
    return myTeams.find((t) => t.team.id === team.id)?.role ?? 0;
  }, [team, myTeams]);
}
