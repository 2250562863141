import { createElement, useId, useMemo } from 'react';
import { lexer as mdLexer, Token as MdToken, Tokens as MdTokens } from 'marked';
import classNames from '@/utils/classnames';

import { IChatMessage } from './useChat';
import { DotLoader } from '../../../../components/DotLoader';
import { REF_RE, UNKNOWN_TAGS_RE, getReferenceIdFromMatch } from '../../constants';
import { Tooltip } from '../../../../components/tooltip/Tooltip';

export type IDocumentDict = Record<string, { name: string }>;
export type IDocumentRef = { refId: number | null; documentId: string };

const parseMarkdown = (contents: string) => {
  const parsed = mdLexer(contents.replace(/^[\u200B\u200C\u200D\u200E\u200F\uFEFF]/, ''));
  return parsed;
};

export interface IMarkdownNodeProps {
  token: MdToken;
  references: IDocumentRef[];
  documents: IDocumentDict;
  openRef: (refId: number | string) => void;
  isOnlyElement?: boolean;
  position?: 'first' | 'middle' | 'last';
}

const ChatTextNode: React.FC<IChatMessageTextProps> = (props) => {
  const { references, documents, openRef } = props;
  const id = useId();

  const content = props.content.replaceAll('&lt;', '<').replaceAll('&gt;', '>');
  const nodes = useMemo(() => {
    const matches = content.matchAll(REF_RE);
    const result = [];
    let key = 0;
    let lastIndex = 0;
    for (const match of matches) {
      if (match.index == null) continue;

      if (lastIndex !== match.index) {
        result.push(content.slice(lastIndex, match.index));
      }
      const refId = getReferenceIdFromMatch(match);
      const ref = references.find((v) => v.refId === refId);
      if (ref) {
        const document = documents[ref.documentId];
        if (document) {
          result.push(
            <Tooltip text={document.name} delayDuration={100} key={`${id}-${result.length}`}>
              <span
                className="cursor-pointer rounded-md text-sm whitespace-nowrap text-blue-200 mx-1"
                key={key}
                onClick={() => {
                  openRef(refId);
                }}
              >
                {`[${refId}]`}
              </span>
            </Tooltip>,
          );
        }
      }
      lastIndex = match.index + match[0].length;
    }
    if (lastIndex !== content.length) {
      result.push(content.slice(lastIndex));
    }

    return result.map((r) => {
      if (typeof r === 'string') {
        return r.replace(UNKNOWN_TAGS_RE, '');
      } else {
        return r;
      }
    });
  }, [content]);

  return <>{nodes}</>;
};

export const MarkdownNode: React.FC<IMarkdownNodeProps> = (props) => {
  const { token, references, documents, openRef, isOnlyElement = false, position = 'middle' } = props;
  const id = useId();

  const margins = isOnlyElement ? '' : position === 'first' ? 'mb-2' : position === 'last' ? 'mt-2' : 'my-2';
  switch (token.type) {
    case 'text':
      const textToken = token as MdTokens.Text;

      return (
        <span>
          {textToken.tokens ? (
            textToken.tokens.map((t, i) => {
              return (
                <MarkdownNode
                  key={`${id}-${i}`}
                  token={t}
                  references={references}
                  documents={documents}
                  openRef={openRef}
                />
              );
            })
          ) : (
            <ChatTextNode content={textToken.raw} references={references} documents={documents} openRef={openRef} />
          )}
        </span>
      );
    case 'strong':
      return (
        <span className="font-medium">
          {token.tokens?.map((t, i) => {
            return (
              <MarkdownNode
                key={`${id}-${i}`}
                token={t}
                references={references}
                documents={documents}
                openRef={openRef}
              />
            );
          })}
        </span>
      );
    case 'em':
      return (
        <span className="italic">
          {token.tokens?.map((t, i) => {
            return (
              <MarkdownNode
                key={`${id}-${i}`}
                token={t}
                references={references}
                documents={documents}
                openRef={openRef}
              />
            );
          })}
        </span>
      );
    case 'paragraph':
      return (
        <p className={margins}>
          {token.tokens?.map((t, i) => {
            return (
              <MarkdownNode
                key={`${id}-${i}`}
                token={t}
                references={references}
                documents={documents}
                openRef={openRef}
              />
            );
          })}
        </p>
      );
    case 'list':
      const listToken = token as MdTokens.List;

      const paddingLeft = Math.max(listToken.start.toString(10).length, 1) * 0.75 + 0.5 + 'rem';
      return createElement(
        listToken.ordered ? 'ol' : 'ul',
        {
          className: classNames(listToken.ordered ? 'list-decimal' : 'list-disc', margins),
          start: listToken.start,
          style: {
            paddingLeft,
          },
        },
        listToken.items?.map((item, i) => {
          return (
            <MarkdownNode
              key={`${id}-${i}`}
              token={item}
              references={references}
              documents={documents}
              openRef={openRef}
            />
          );
        }),
      );
    case 'list_item':
      return (
        <li>
          {token.tokens?.map((t, i) => {
            return (
              <MarkdownNode
                key={`${id}-${i}`}
                token={t}
                references={references}
                documents={documents}
                openRef={openRef}
              />
            );
          })}
        </li>
      );
    case 'heading':
      const depth = (token as MdTokens.Heading).depth;

      return createElement(
        `h${depth}`,
        {
          className: classNames('mb-1 font-medium', {
            'text-lg font-bold': depth === 1,
            'text-md font-bold': depth === 2,
          }),
        },
        token.tokens?.map((t, i) => {
          return (
            <MarkdownNode
              key={`${id}-${i}`}
              token={t}
              references={references}
              documents={documents}
              openRef={openRef}
            />
          );
        }),
      );
    case 'space':
      return null;
    default:
      console.error('Unhandled token', token);
      return (
        <div>
          <ChatTextNode content={token.raw} references={references} documents={documents} openRef={openRef} />
        </div>
      );
  }
};

export interface IChatMessageTextProps {
  content: string;
  references: IDocumentRef[];
  documents: IDocumentDict;
  openRef: (refId: number | string) => void;
}

export const ChatMessageText: React.FC<IChatMessageTextProps> = (props) => {
  const { content, references, documents, openRef } = props;
  const id = useId();

  const parsedMd = useMemo(() => {
    return parseMarkdown(content);
  }, [content]);

  return (
    <div>
      {parsedMd.map((token, i) => {
        return (
          <MarkdownNode
            key={`${id}-${i}`}
            token={token}
            references={references}
            documents={documents}
            openRef={openRef}
            isOnlyElement={i === 0 && parsedMd.length === 1}
            position={i === 0 ? 'first' : i === parsedMd.length - 1 ? 'last' : 'middle'}
          />
        );
      })}
    </div>
  );
};

export interface IChatMessageContentProps {
  message: IChatMessage;
  references: IChatMessage['references'];
  documents: IChatMessage['documents'];
  openRef: (refId: number | string) => void;
}

export const ChatMessageContent: React.FC<IChatMessageContentProps> = (props) => {
  const { message, references, documents, openRef } = props;

  const content = message.content;
  if (!content) {
    return (
      <div className="flex gap-1 items-center">
        <div className="whitespace-nowrap">{message.status || 'Loading'}</div>
        <div className="self-end mb-1">
          <DotLoader size={4} />
        </div>
      </div>
    );
  } else {
    return <ChatMessageText content={content} references={references} documents={documents} openRef={openRef} />;
  }
};
