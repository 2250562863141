import { PdfPreview } from '../../../components/PdfPreview';

export const PREVIEW_MIMETYPES = new Set(['application/pdf']);

export interface IFilePreviewProps {
  file: {
    id: string;
    name: string;
    mimetype: string;
  };
  fileUrl: string;
  pageNumber?: number | null;
}

export const FilePreview: React.FC<IFilePreviewProps> = (props) => {
  const { file, fileUrl, pageNumber } = props;

  if (file.mimetype === 'application/pdf') {
    return <PdfPreview fileUrl={fileUrl} title={file.name} page={pageNumber} />;
  }

  return <div>File not supported.</div>;
};
