export function getDisplayError(err: any) {
  if (typeof err !== 'object') {
    return `${err}`;
  }

  if (Array.isArray(err.errors)) {
    if (typeof err.errors[0] === 'string') {
      return err.errors.join(', ');
    }
  }

  if (err.message) {
    return err.message;
  }

  return 'Ongekende error';
}
