import { useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import { PencilIcon, PlusIcon, Settings2Icon, TrashIcon, UploadIcon } from 'lucide-react';
import toast from 'react-hot-toast';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { nullthrows } from '../../../utils/invariant';
import { LinkButton } from '../../../components/button/Button';
import { DocumentExplorer } from '../components/DocumentExplorer';
import { useRole } from '../../../hooks/useRole';
import { AuthRoles } from '../../user/auth-roles';
import { Tag } from '../../../components/Tag';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { InputDialog } from '../../../components/dialog/InputDialog';
import { getDisplayError } from '../../../utils/get-display-error';
import { DocumentCollectionMetadataDialog } from '../../documentCollection/components/DocumentCollectionMetadataDialog';
import { useExplorerTree } from '../../explorerTree/contexts/ExplorerContext';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import { useTeam } from '../../team/context/TeamContext';
import { SpinnerBlock } from '../../../components/Spinner';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type { BodyType as UpdateDocumentCollectionPayload } from '../../documentCollection/endpoints/UpdateDocumentCollectionEndpoint';
import type { BodyType as DeleteDocumentCollectionsPayload } from '../../documentCollection/endpoints/DeleteDocumentCollectionsEndpoint';

export const DocumentsPage = () => {
  const { collectionId: _collectionId } = useParams<{ collectionId: string }>();
  const collectionId = nullthrows(_collectionId, 'Collection id not defined');
  const navigate = useNavigate();
  const userRole = useRole();
  const { team } = useTeam();
  const { tree: explorerTree } = useExplorerTree();

  const collectionNode = explorerTree.getCollectionNode(collectionId);
  const parentCollectionNode = collectionNode?.parentCollectionId
    ? explorerTree.getCollectionNode(collectionNode.parentCollectionId)
    : null;
  const collectionPath = useResolvedPath(
    collectionNode?.parentCollectionId ? `../${collectionNode.parentCollectionId}` : '..',
  );

  if (!collectionNode) {
    if (explorerTree.isSyncing) {
      return (
        <div className="page-content">
          <PageHeader title="Loading collection..." />

          <div className="mb-4">
            <Breadcrumb
              items={[
                {
                  name: 'Document Collections',
                  to: '..',
                },
                {
                  name: 'Collection',
                },
              ]}
            />
          </div>

          <div>
            <SpinnerBlock message="Loading collection..." />
          </div>
        </div>
      );
    } else {
      return (
        <div className="page-content">
          <PageHeader title="Collection not found" />

          <div className="mb-4">
            <Breadcrumb
              items={[
                {
                  name: 'Document Collections',
                  to: '..',
                },
                {
                  name: 'Unknown collection',
                },
              ]}
            />
          </div>

          <div>Collection not found</div>
        </div>
      );
    }
  }

  const collection = nullthrows(collectionNode?.collection, 'Collection not found');
  return (
    <div className="page-content">
      <PageHeader title={collectionNode.name} />

      <div className="flex justify-between items-center mb-4">
        <div className="flex justify-between">
          <div className="flex items-center">
            <Breadcrumb
              items={[
                {
                  name: parentCollectionNode ? parentCollectionNode.name : 'Document Collections',
                  to: collectionPath.pathname,
                },
                {
                  name: collectionNode.name,
                },
              ]}
            />

            {userRole >= AuthRoles.Editor && (
              <div className="ml-1 flex">
                <InputDialog
                  resetKey={collectionNode.id}
                  triggerVariant="ghost"
                  triggerText={<PencilIcon className="button-icon" />}
                  triggerSize={6}
                  title="Rename collection"
                  description="Enter a new name for the collection"
                  labelText="New name"
                  submitText="Rename"
                  initialValue={collectionNode.name}
                  onSubmit={async (value) => {
                    try {
                      const payload: UpdateDocumentCollectionPayload = {
                        teamId: team.id,
                        collectionId: collectionId,
                        data: {
                          name: value,
                        },
                      };
                      await fetchEndpointData(`/api/v1/document-collection/update`, {
                        method: 'POST',
                        body: payload,
                      });
                      toast.success('Collection name has been updated');
                    } catch (err) {
                      toast.error('Could not update collection name: ' + getDisplayError(err));
                    }
                  }}
                />

                <ConfirmDialog
                  triggerVariant="ghost"
                  triggerText={<TrashIcon className="button-icon" />}
                  triggerSize={6}
                  title="Delete collection"
                  submitText="Delete"
                  description={`Are you sure you want to delete this collection ${collectionNode.name}?`}
                  onSubmit={async () => {
                    try {
                      const payload: DeleteDocumentCollectionsPayload = {
                        teamId: team.id,
                        documentCollectionIds: [collectionId],
                      };
                      await fetchEndpointData(`/api/v1/document-collection/delete`, {
                        method: 'DELETE',
                        body: payload,
                      });
                      toast.success('Collection deletion has been queued');
                      navigate(collectionPath);
                    } catch (err) {
                      toast.error('Could not queue deletion: ' + getDisplayError(err));
                    }
                  }}
                />

                {userRole >= AuthRoles.Editor && (
                  <DocumentCollectionMetadataDialog
                    triggerVariant="ghost"
                    triggerText={<Settings2Icon className="button-icon" />}
                    triggerSize={6}
                    documentCollection={{
                      name: collectionNode.name,
                      ...collection,
                      categories: collection.categories,
                    }}
                    key={collection.id}
                  />
                )}
              </div>
            )}

            {collection.isSynced && (
              <div className="ml-4">
                <Tooltip text="This collection is automatically synced using an integration and cannot be modified manually">
                  <div>
                    <Tag color="green">Integration</Tag>
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </div>

        <div className="flex gap-2">
          {userRole >= AuthRoles.Editor && !collection.isSynced && (
            <>
              <LinkButton to="upload" title="Upload">
                <UploadIcon className="button-icon" />
              </LinkButton>
              <LinkButton to="create-collection" title="Create Collection">
                <PlusIcon className="button-icon" />
              </LinkButton>
            </>
          )}
        </div>
      </div>

      <div>
        <DocumentExplorer
          key={collection.id}
          parentCollectionId={collection.id}
          isReadOnly={collection.isSynced || userRole < AuthRoles.Editor}
          canUploadDocuments={true}
        />
      </div>
    </div>
  );
};
