import classNames from '@/utils/classnames';
import { Link, Outlet, useLocation } from 'react-router-dom';

export interface ITabItem {
  title: string;
  path: string;
}

export interface ITabsProps {
  items: ITabItem[];
}

export const Tabs: React.FC<ITabsProps> = (props) => {
  const { items } = props;
  const location = useLocation();
  const { search } = useLocation();

  const activeItem = items.find((v) => location.pathname.includes(v.path));

  return (
    <div>
      <div className="flex border-b border-gray-200">
        {items.map((item, idx) => {
          const isActive = item.path === activeItem?.path;
          return (
            <Link to={item.path + search} key={`tab-${idx}`}>
              <div
                className={classNames('whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm', {
                  'border-dark-500 text-dark-500': isActive,
                  'border-transparent text-gray-500 hover:border-gray-300': !isActive,
                  'ml-6': idx > 0,
                })}
                key={`tab-header-${idx}`}
              >
                {item.title}
              </div>
            </Link>
          );
        })}
      </div>

      <div className="pt-4">
        <Outlet />
      </div>
    </div>
  );
};
