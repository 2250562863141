import { Button } from './button/Button';

export interface IPaginationProps {
  hasPrevious: boolean;
  previous: () => void;
  hasNext: boolean;
  next: () => void;
  isFetching: boolean;
}

export const Pagination: React.FC<IPaginationProps> = (props) => {
  const { hasPrevious, previous, hasNext, next, isFetching } = props;

  return (
    <div className="flex justify-between">
      <div>
        <Button isDisabled={isFetching || !hasPrevious} onTrigger={previous} isLoading={isFetching}>
          Previous
        </Button>
      </div>
      <div>
        <Button isDisabled={isFetching || !hasNext} onTrigger={next} isLoading={isFetching}>
          Next
        </Button>
      </div>
    </div>
  );
};
