import { useField } from 'formik';
import { InputWrapper } from '../../../components/InputWrapper';
import { TeamAddons } from '../enums';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { LabeledCheckbox } from '../../../components/checkbox/LabeledCheckbox';
import { useId } from 'react';

export interface IAddonSelectionFieldProps {
  labelText: string;
  name: string;
}

export const AddonSelectionField: React.FC<IAddonSelectionFieldProps> = (props) => {
  const { labelText, name } = props;
  const [field, meta, helpers] = useField({ name });
  const id = useId();

  return (
    <InputWrapper labelText={labelText} invalidText={meta.touched ? meta.error : undefined} noLabel={true}>
      <div className="grid lg:grid-cols-4 md:grid-cols-2">
        {Object.values(TeamAddons).map((v, idx) => {
          return (
            <LabeledCheckbox
              id={`${id}-${idx}`}
              labelText={v}
              key={v}
              isChecked={field.value.includes(v)}
              onChange={(checked) => {
                if (checked) {
                  helpers.setValue([...field.value, v]);
                } else {
                  helpers.setValue(field.value.filter((a: string) => a !== v));
                }
              }}
            />
          );
        })}
      </div>
    </InputWrapper>
  );
};
