import toast from 'react-hot-toast';
import React, { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import useSWR from 'swr';
import { TriangleAlertIcon } from 'lucide-react';

import { FormDialog } from '../../../../../components/dialog/FormDialog';
import { useWorkspace } from '../WorkspaceContext';
import { useTeam } from '@/app/team/context/TeamContext';
import { SimpleSelectField } from '../../../../../components/select/SimpleSelectField';
import { getDisplayError } from '../../../../../utils/get-display-error';
import { ISimpleSelectItem } from '../../../../../components/select/SimpleSelect';
import { nullthrows } from '../../../../../utils/invariant';
import { workspaceCompaniesSort } from '../WorkspaceCompaniesPage';
import { QuestionsPresetType } from '../../../../questionsPreset/enums';
import { fetchEndpointData } from '../../../../../utils/fetch.client';
import type { ResponseType as QuestionsPresetsResponseType } from '../../../../questionsPreset/endpoints/QuestionsPresetsEndpoint';
import type { BodyType as StartPresetRunPayload } from '../../../endpoints/StartPresetRunEndpoint';
import { InputField } from '../../../../../components/input/InputField';
import { DocumentPickerDialogWithTrigger } from '@/app/document/components/DocumentPicker';
import { ExplorerTreeEntry } from '@/app/explorerTree/explorer-tree';
import { useExplorerTree } from '@/app/explorerTree/contexts/ExplorerContext';
import classNames from '@/utils/classnames';
import { HelpDialog } from '../../../../../components/dialog/HelpDialog';

const DOC_ALERT_TRESHOLD = 50;

interface IValues {
  preset?: (ISimpleSelectItem & { type: string }) | null;
  companyName?: string | null;
  selectedCollection?: ExplorerTreeEntry | null;
}

const ConditionalFields = () => {
  const { companies } = useWorkspace();
  const { values } = useFormikContext<IValues>();

  const companyItems = useMemo(() => {
    return companies.sort(workspaceCompaniesSort).map((v) => {
      return {
        key: v.id,
        name: v.name,
      };
    });
  }, [companies]);

  const preset = values.preset;
  if (!preset || preset.type !== QuestionsPresetType.Company) {
    return null;
  }

  return <InputField name="companyName" labelText="Company Name" />;
};

interface ISubFieldsProps {
  rootExplorerId: string;
}

const SubFields: React.FC<ISubFieldsProps> = (props) => {
  const { rootExplorerId } = props;
  const { values, isSubmitting, setFieldValue } = useFormikContext<IValues>();
  const [showFolderPicket, setShowFolderPicker] = useState(false);

  const selectedCollection = values.selectedCollection;
  const { tree } = useExplorerTree();
  const docCount = useMemo(() => {
    return tree.countDocuments(selectedCollection?.id ?? rootExplorerId, true);
  }, [selectedCollection, tree]);

  return (
    <div>
      <DocumentPickerDialogWithTrigger
        showRoot={true}
        rootExplorerId={rootExplorerId}
        triggerText={selectedCollection ? `Selection: ${selectedCollection.name}` : 'Select a folder'}
        isDisabled={isSubmitting}
        title="Select a folder"
        open={showFolderPicket}
        onOpenChange={setShowFolderPicker}
        multiSelect={false}
        onlyCollections={true}
        onSubmit={(selectedNodes) => {
          const selectedNode = selectedNodes.pop() ?? null;
          const selectedId = selectedNode?.collection?.id ?? null;
          if (!selectedId) {
            setFieldValue('selectedCollection', null);
            setShowFolderPicker(false);
            return;
          }

          setFieldValue('selectedCollection', selectedNode);
          setShowFolderPicker(false);
        }}
      />

      <div className="flex justify-end mt-4 mb-2 gap-1">
        {docCount === 0 ? (
          <div className="text-red-500">No documents selected</div>
        ) : (
          <div
            className={classNames('flex gap-1 items-center', {
              'text-red-500': docCount > DOC_ALERT_TRESHOLD,
            })}
          >
            {docCount > DOC_ALERT_TRESHOLD && <TriangleAlertIcon className="w-4 h-4" />}
            <div>
              This preset will be executed on:<span className="font-medium ml-1">{docCount} documents</span>
            </div>
          </div>
        )}

        <HelpDialog title="Hint: preset document count">
          <div>
            Presets always run over all selected documents, the more documents you select, the more expensive the preset
            run becomes. Make sure to always select the most minimal subset of data to ensure quick and efficient preset
            runs and to prevent excessive costs.
          </div>
        </HelpDialog>
      </div>
    </div>
  );
};

export interface IStartPresetRunDialogProps {
  isProcessing?: boolean;
  onStart?: () => void;
}

export const StartPresetRunDialog: React.FC<IStartPresetRunDialogProps> = (props) => {
  const { isProcessing, onStart } = props;
  const { team } = useTeam();
  const { workspace } = useWorkspace();
  const { tree } = useExplorerTree();
  const { data, isLoading } = useSWR<QuestionsPresetsResponseType>(
    `/api/v1/questions-preset/list?teamId=${team.id}&take=100`,
    fetchEndpointData,
  );

  const presets = data?.presets || [];
  const items = presets.map((p) => {
    return {
      key: p.id,
      name: p.name,
      type: p.type,
    };
  });

  const rootCollectionExplorerId = tree.collectionIdToExplorerId.get(workspace.documentCollection.id);
  if (!rootCollectionExplorerId) {
    return null;
  }

  const rootCollectionNode = tree.entries.get(rootCollectionExplorerId);
  return (
    <FormDialog
      triggerText="Start Preset Run"
      triggerVariant="primary"
      title="Start Preset Run"
      submitText="Start"
      isDisabled={isProcessing}
      isLoading={isLoading && !presets.length}
      onSubmit={async (values) => {
        try {
          const presetId = nullthrows(values.preset?.key as string, 'No preset selected');
          const companyName = values.companyName?.trim() || null;
          const collectionId = values.selectedCollection?.collection?.id ?? workspace.documentCollection.id;

          const explorerId = tree.collectionIdToExplorerId.get(collectionId);
          if (!explorerId) {
            throw new Error('Collection not found');
          }

          const docCount = tree.countDocuments(explorerId, true);
          if (docCount === 0) {
            throw new Error('No documents selected for preset');
          }

          if (values.preset?.type === QuestionsPresetType.Company && !companyName) {
            throw new Error('Select a company to run a company preset run');
          }

          const payload: StartPresetRunPayload = {
            presetId,
            companyName,
            workspaceId: workspace.id,
            collectionId,
          };
          await fetchEndpointData(`/api/v1/workspace/start-preset-run`, {
            method: 'POST',
            body: payload,
          });
          onStart?.();
          toast.success('Preset run has been started');
        } catch (err) {
          toast.error('Could not start preset run: ' + getDisplayError(err));
          throw err;
        }
      }}
      initialValues={
        {
          preset: null,
          companyName: null,
          selectedCollection: rootCollectionNode,
        } as IValues
      }
    >
      <SimpleSelectField name="preset" labelText="Preset" items={items} />

      <ConditionalFields />

      <SubFields rootExplorerId={rootCollectionExplorerId} />
    </FormDialog>
  );
};
