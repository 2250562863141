const VAT_NUMBER_REGEXES: { [key: string]: RegExp } = {
  AT: /(AT)?U[0-9]{8}/,
  BE: /(BE)?0[0-9]{9}/,
  BG: /(BG)?[0-9]{9,10}/,
  CY: /(CY)?[0-9]{8}L/,
  CZ: /(CZ)?[0-9]{8,10}/,
  DE: /(DE)?[0-9]{9}/,
  DK: /(DK)?[0-9]{8}/,
  EE: /(EE)?[0-9]{9}/,
  EL: /(EL|GR)?[0-9]{9}/,
  GR: /(EL|GR)?[0-9]{9}/,
  ES: /(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]/,
  FI: /(FI)?[0-9]{8}/,
  FR: /(FR)?[0-9A-Z]{2}[0-9]{9}/,
  GB: /(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})/,
  HU: /(HU)?[0-9]{8}/,
  IE: /(IE)?[0-9]S[0-9]{5}L/,
  IT: /(IT)?[0-9]{11}/,
  LT: /(LT)?([0-9]{9}|[0-9]{12})/,
  LU: /(LU)?[0-9]{8}/,
  LV: /(LV)?[0-9]{11}/,
  MT: /(MT)?[0-9]{8}/,
  NL: /(NL)?[0-9]{9}B[0-9]{2}/,
  PL: /(PL)?[0-9]{10}/,
  PT: /(PT)?[0-9]{9}/,
  RO: /(RO)?[0-9]{2,10}/,
  SE: /(SE)?[0-9]{12}/,
  SI: /(SI)?[0-9]{8}/,
  SK: /(SK)?[0-9]{10}/,
};

const VAT_NUMBER_FORMATS = new Map<string, number[]>([
  ['BE', [4, 3, 3]],
  ['NL', [4, 2, 3, 3]],
]);

export interface VatNumberValue {
  fullVatNumber: string;
  countryCode: string;
  vatNumber: string;
}

export function cleanCompanyNumber(val: string) {
  return val.replace(/[\s.]+/g, '').toUpperCase();
}

export function isValidVatNumber(val: string): boolean {
  let cleanedVatNumber = cleanCompanyNumber(val);
  if (cleanedVatNumber.length < 2) {
    return false;
  }

  let countryCode = cleanedVatNumber.substring(0, 2);
  let countryRegex: RegExp = VAT_NUMBER_REGEXES[countryCode]!;
  if (!countryRegex) {
    throw new Error('VAT Number has unknown country code');
  }

  return countryRegex.test(cleanedVatNumber);
}

export function cleanAndValidateVatNumber(val: string): string {
  const isValid = isValidVatNumber(val);
  if (!isValid) {
    throw new Error('Invalid vat number');
  }
  return cleanCompanyNumber(val);
}

export function formatVatNumber(val: string): string {
  const cleaned = cleanCompanyNumber(val);
  const countryCode = cleaned.substring(0, 2);
  let result = countryCode + ' ';
  const format = VAT_NUMBER_FORMATS.get(countryCode) ?? [];
  let offset = 2;
  for (let i = 0; i < format.length; i++) {
    const group = format[i]!;
    result += cleaned.substring(offset, offset + group);
    if (i < format.length - 1) {
      result += '.';
    }
    offset += group;
  }
  // If any characters are left, we also should print it
  // this function does no validation
  // also handles countries that we didn't implement yet
  result += cleaned.substring(offset);
  return result;
}
