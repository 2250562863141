import * as React from 'react';
import classNames from '@/utils/classnames';

export interface BaseTextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  variant?: 'default' | 'inverted';
}

const BaseTextArea = React.forwardRef<HTMLTextAreaElement, BaseTextAreaProps>((props, ref) => {
  const { className, variant = 'default', children, ...otherProps } = props;

  return (
    <textarea
      className={classNames(
        'flex w-full rounded-md py-2 px-3 text-sm border border-transparent focus:outline-none focus:border-gray-300 disabled:cursor-not-allowed disabled:opacity-500 bg-gray-200 text-dark-500',
        className,
      )}
      ref={ref}
      {...otherProps}
    >
      {children}
    </textarea>
  );
});
BaseTextArea.displayName = 'BaseTextArea';

export { BaseTextArea };
