import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from '@/utils/classnames';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  AsteriskIcon,
  BugIcon,
  CircleDotIcon,
  CoinsIcon,
  ExternalLinkIcon,
  FilterIcon,
  MessageSquare,
  SearchIcon,
  SendIcon,
} from 'lucide-react';
import toast from 'react-hot-toast';
import useSWR from 'swr';

import { Breadcrumb } from '../../../../components/Breadcrumb';
import { PageHeader } from '../../../../components/PageHeader';
import { IChatMessage, useChat } from './useChat';
import { Button, LinkButton } from '../../../../components/button/Button';
import { IDocumentSearchMatch, IMinimalDocument } from '../../websocket/chat/types';
import { nullthrows } from '../../../../utils/invariant';
import { ChatMessageContent } from './ChatMessage';
import { useAuth } from '../../../../contexts/auth-context';
import { ChatMessageActions } from './ChatMessageActions';
import { REF_RE, getReferenceIdFromMatch } from '../../constants';
import { BaseTextArea } from '../../../../components/textarea/BaseTextArea';
import { formatDate } from '../../../../utils/date';
import { SpinnerBlock } from '../../../../components/Spinner';
import { Tooltip } from '../../../../components/tooltip/Tooltip';
import { DialogContent, DialogRoot } from '../../../../components/dialog/Dialog';
import { useTeam } from '@/app/team/context/TeamContext';
import { useSize } from '../../../../hooks/useSize';
import { DocumentPickerDialog } from '../../components/DocumentPicker';
import { fetchEndpointData } from '../../../../utils/fetch.client';
import { ResponseType as ChatMessagePartsResponseType } from '../../endpoints/ChatMessagePartsEndpoint';

const MAX_INPUT_HEIGHT = 250;

interface IChatReferencePreviewProps {
  document: IMinimalDocument;
  reference: {
    refId: number | null;
    documentId: string;
    matches: IDocumentSearchMatch[];
  };
  messageParts: ChatMessagePartsResponseType['parts'];
  isOpen: boolean;
  onOpenChange: (newOpen: boolean) => void;
}

export const ChatReferencePreviewDialog: React.FC<IChatReferencePreviewProps> = (props) => {
  const { reference, document, isOpen, onOpenChange, messageParts } = props;
  const { me } = useAuth();
  const [showHidden, setShowHidden] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen === false) {
      setShowHidden([]);
    }
  }, [isOpen]);

  const matches = reference.matches;
  const searchParams = new URLSearchParams();
  const mostRelevant = [...matches].sort((a, b) => a.questionDistance - b.questionDistance)[0];
  if (mostRelevant) {
    if (mostRelevant.pageNumber) {
      searchParams.set('pageNumber', mostRelevant.pageNumber.toString());
    }

    if (mostRelevant.pageRef) {
      searchParams.set('pageRef', mostRelevant.pageRef);
    }
  }

  searchParams.set('highlightChunks', [...new Set(matches.map((v) => v.chunkId))].join(';'));

  const maxSimilaritiesPerChunk = useMemo(() => {
    const maxSimilarities = new Map<string, number>();
    for (const msgPart of messageParts) {
      for (const chunk of msgPart.chunks) {
        const newMaxSimilarity = Math.max(maxSimilarities.get(chunk.chunkId) ?? 0, chunk.answerSimilarity);
        maxSimilarities.set(chunk.chunkId, newMaxSimilarity);
      }
    }
    return maxSimilarities;
  }, [messageParts]);

  const chunkIds = useMemo(() => {
    return new Set(matches.map((v) => v.chunkId));
  }, [matches]);

  const [topChunkId, topSimilarity] = useMemo(() => {
    const filtered = [...maxSimilaritiesPerChunk.entries()].filter(([chunkId, similarity]) => {
      return chunkIds.has(chunkId);
    });
    const sorted = filtered.sort((a, b) => b[1] - a[1]);
    const topValue = sorted[0];
    return [topValue?.[0] ?? '', topValue?.[1] ?? 0];
  }, [maxSimilaritiesPerChunk, document.id]);

  const similarityTreshold = Math.max(topSimilarity - 0.1, 0.35);
  const irrelevanceTreshold = Math.max(topSimilarity - 0.2, 0);

  const [matchBlocks, irrelevanceBlocks] = useMemo(() => {
    let prevBlockWasIrrelevant = false;
    const irrelevanceBlocks: string[][] = [];
    const blocks: typeof matches = [];
    for (const match of matches) {
      const maxSimilarity = maxSimilaritiesPerChunk.get(match.chunkId) ?? 0;
      const isIrrelevantMatch = maxSimilarity < irrelevanceTreshold;

      if (isIrrelevantMatch) {
        if (prevBlockWasIrrelevant) {
          const lastIrrelevanceBlock = irrelevanceBlocks.pop() ?? [];
          lastIrrelevanceBlock.push(match.chunkId);
          irrelevanceBlocks.push(lastIrrelevanceBlock);
        } else {
          irrelevanceBlocks.push([match.chunkId]);
        }
      }

      if (isIrrelevantMatch) {
        if (!prevBlockWasIrrelevant || showHidden.includes(match.chunkId)) {
          blocks.push(match);
        }
      } else {
        blocks.push(match);
      }

      if (isIrrelevantMatch) {
        prevBlockWasIrrelevant = true;
      } else {
        prevBlockWasIrrelevant = false;
      }
    }
    return [blocks, irrelevanceBlocks];
  }, [matches, showHidden, irrelevanceTreshold, maxSimilaritiesPerChunk]);

  // useEffect(() => {
  //   if (topChunkId) {
  //     setTimeout(() => {
  //       const element = window.document.getElementById(`dialog-chunk-${topChunkId}`);
  //       if (element) {
  //         element.scrollIntoView();
  //       }
  //     }, 50);
  //   }
  // }, [topChunkId, reference]);

  return (
    <DialogRoot open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="dialog-content flex flex-col">
        <div className="overflow-y-auto">
          <h1 className="heading-one mb-4">{document.title}</h1>

          <div className="mt-8">
            {matchBlocks.map((m) => {
              const showDebugInfo = me.isSuperUser;
              const maxSimilarity = maxSimilaritiesPerChunk.get(m.chunkId) ?? 0;
              const isRelevantMatch = maxSimilarity > similarityTreshold;
              const isIrrelevantMatch = maxSimilarity < irrelevanceTreshold;

              const debugInfo = [];
              if (m.isOriginalMatch) {
                debugInfo.push('Original match');
                debugInfo.push(`Question similarity: ${Math.round(Math.max(0, 1 - m.questionDistance) * 100)}%`);
              }
              if (maxSimilarity > 0) {
                debugInfo.push(`Answer similarity: ${Math.round(maxSimilarity * 100)}%`);
              }

              if (isIrrelevantMatch && !showHidden.includes(m.chunkId)) {
                return (
                  <div
                    key={m.chunkId}
                    className="relative my-4"
                    onClick={() => {
                      const irrelevanceBlock = irrelevanceBlocks.find((v) => v.includes(m.chunkId)) ?? [];
                      setShowHidden((prev) => {
                        return [...new Set([...prev, ...irrelevanceBlock])];
                      });
                    }}
                  >
                    <div className="absolute rounded-full h-6 px-2 bg-gray-100 right-0 -top-3 cursor-pointer">show</div>
                    <div className="bg-gray-100 my-2 cursor-pointer" style={{ height: 1 }}></div>
                  </div>
                );
              } else {
                return (
                  <div key={m.chunkId} id={`dialog-chunk-${m.chunkId}`}>
                    {isIrrelevantMatch && (
                      <div key={m.chunkId} className="relative my-4">
                        <div
                          className="absolute rounded-full h-6 px-2 bg-gray-100 right-0 -top-3 cursor-pointer"
                          onClick={() => {
                            const irrelevanceBlock = new Set(
                              irrelevanceBlocks.find((v) => v.includes(m.chunkId)) ?? [],
                            );
                            setShowHidden((prev) => {
                              return prev.filter((v) => !irrelevanceBlock.has(v));
                            });
                          }}
                        >
                          hide
                        </div>
                        <div className="bg-gray-100 my-2 cursor-pointer" style={{ height: 1 }}></div>
                      </div>
                    )}

                    <div
                      className={classNames('whitespace-pre-line my-2 border-l-2 pl-2 text-dark-500', {
                        'border-transparent': !isRelevantMatch && !m.isOriginalMatch,
                        'border-blue-200': isRelevantMatch,
                        'border-gray-300': !isRelevantMatch && m.isOriginalMatch,
                      })}
                    >
                      <div>{m.content}</div>
                      {m.relevancyReason && (
                        <div className="flex gap-1 font-medium text-xs text-gray-500 mt-1">
                          <AsteriskIcon className="w-4 h-4" />
                          <div>{m.relevancyReason}</div>
                        </div>
                      )}
                      {showDebugInfo && debugInfo.length > 0 && (
                        <div className="flex gap-1 font-medium text-xs text-gray-500 mt-1">
                          <BugIcon className="w-4 h-4" />
                          <div>{debugInfo.join(', ')}</div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>

        <div className="flex justify-between mt-4">
          <LinkButton
            variant="primary"
            to={`../../documents/${document.collectionId}/${document.id}?${searchParams}`}
            iconLeft={<ExternalLinkIcon className="button-icon" />}
          >
            Open Document
          </LinkButton>

          <Button
            onTrigger={() => {
              onOpenChange(false);
            }}
          >
            Close
          </Button>
        </div>
      </DialogContent>
    </DialogRoot>
  );
};

export interface IBaseReferenceButtonProps {
  isDirect: boolean;
  footer?: string;
  refId?: string;
  onTrigger: () => void;
  children: React.ReactNode;
}

const BaseReferenceButton: React.FC<IBaseReferenceButtonProps> = (props) => {
  const { onTrigger, children, footer, refId, isDirect } = props;

  return (
    <div
      className={classNames(
        'h-full font-medium select-none cursor-pointer rounded-md text-sm p-1 px-2 text-dark-300 flex flex-col justify-between break-words',
        {
          'bg-blue-50 hover:bg-blue-100': isDirect,
          'bg-gray-200 hover:bg-gray-300': !isDirect,
        },
      )}
      onClick={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        onTrigger();
      }}
    >
      <div>{children}</div>
      <div className="flex justify-between text-card-subtle mt-1">
        <div>{footer}</div>
        <div>{refId}</div>
      </div>
    </div>
  );
};

export interface IReferenceButtonProps {
  isDirect: boolean;
  setOpenRef: (newRef: string | null) => void;
  contextEntry: IChatMessage['references'][0] & { key: string };
  doc: { title: string; date: Date };
}

const ReferenceButton: React.FC<IReferenceButtonProps> = (props) => {
  const { setOpenRef, contextEntry, doc, isDirect } = props;

  let text = `[${contextEntry.refId}] ${doc.title}`;
  if (text.length > 80) {
    text = text.slice(0, 77) + '...';
  }

  return (
    <BaseReferenceButton
      isDirect={isDirect}
      // refId={`${contextEntry.refId ?? ''}`}
      footer={formatDate(doc.date)}
      onTrigger={() => {
        setOpenRef(contextEntry.key);
      }}
    >
      {text}
    </BaseReferenceButton>
  );
};

export interface IChatMessageComponentProps {
  msg: IChatMessage;
  openRef: string | null;
  setOpenRef: (newRef: string | null) => void;
}

const ChatMessageComponent: React.FC<IChatMessageComponentProps> = (props) => {
  const { msg, openRef, setOpenRef } = props;
  const [showAllRefs, setShowAllRefs] = useState(false);
  const { data: msgPartsData, mutate } = useSWR<ChatMessagePartsResponseType>(
    `/api/v1/chat/chat-message-parts/${msg.messageId}`,
    fetchEndpointData,
  );

  useEffect(() => {
    mutate();
  }, [msg.messagePartsHash]);

  const isUser = msg.userId !== 'system';
  const references = msg.references
    .sort((a, b) => {
      return b.matches.filter((m) => m.isRelevant).length - a.matches.filter((m) => m.isRelevant).length;
    })
    .map((ref) => {
      return {
        ...ref,
        key: ref.refId != null ? `${msg.messageId}#${ref.refId}` : `${msg.messageId}#${ref.documentId}`,
      };
    });

  // Fix content using <ref>{number}</ref> to <ref#{number}>
  const content = msg.content.replaceAll(REF_RE, (substring) => {
    const matches = Array.from(substring.matchAll(REF_RE));
    if (matches.length > 0) {
      const refId = getReferenceIdFromMatch(matches[0]!);
      return `<ref#${refId}>`;
    } else {
      return '';
    }
  });
  msg.content = content;

  const usedReferences = useMemo(() => {
    return new Set<number>(
      [...content.matchAll(REF_RE)]
        .map((v) => {
          const ref = getReferenceIdFromMatch(v);
          if (typeof ref !== 'number') {
            return -1;
          } else {
            return ref;
          }
        })
        .filter((v) => v >= 0),
    );
  }, [content]);

  const toggleShowAllReferences = useCallback(() => {
    setShowAllRefs((prev) => !prev);
  }, [setShowAllRefs]);

  const directReferences = references.filter((v) => v.refId != null && usedReferences.has(v.refId));
  const indirectReferences = references.filter((v) => v.refId != null && !usedReferences.has(v.refId));

  const messageParts = useMemo(() => {
    return msgPartsData?.parts ?? [];
  }, [msgPartsData]);

  return (
    <div key={msg.messageId}>
      {references.length > 0 && (
        <div className="mb-4">
          <div className="chat-title">
            <SearchIcon className="button-icon" /> Sources
          </div>
          <div className="grid grid-cols-4 gap-2">
            {!showAllRefs &&
              directReferences
                .sort((a, b) => (a.refId ?? 0) - (b.refId ?? 0))
                .map((contextEntry) => {
                  const doc = msg.documents[contextEntry.documentId]!;
                  return (
                    <ReferenceButton
                      key={contextEntry.key}
                      setOpenRef={setOpenRef}
                      contextEntry={contextEntry}
                      doc={doc}
                      isDirect={false}
                    />
                  );
                })}

            {!!showAllRefs &&
              references
                .sort((a, b) => (a.refId ?? 0) - (b.refId ?? 0))
                .map((v) => {
                  const doc = msg.documents[v.documentId]!;
                  const isDirect = usedReferences.has(v.refId!);
                  return (
                    <ReferenceButton
                      key={v.key}
                      setOpenRef={setOpenRef}
                      contextEntry={v}
                      doc={doc}
                      isDirect={isDirect}
                    />
                  );
                })}

            {indirectReferences.length > 0 && (
              <div>
                <BaseReferenceButton
                  onTrigger={toggleShowAllReferences}
                  refId={`${indirectReferences.length}`}
                  isDirect={false}
                >
                  {showAllRefs ? 'Hide other sources' : 'Show all sources'}
                </BaseReferenceButton>
              </div>
            )}
          </div>
        </div>
      )}

      {!isUser && (
        <div className="chat-title">
          <MessageSquare className="button-icon" /> Answer
        </div>
      )}

      <div className={classNames('flex')}>
        <div
          className={classNames('rounded-md whitespace-pre-line max-w-full relative', {
            'font-medium': isUser,
            'text-2xl': isUser && msg.content.length < 250,
            'text-lg': isUser && msg.content.length >= 250,
          })}
        >
          <ChatMessageContent
            message={msg}
            references={msg.references}
            documents={msg.documents}
            openRef={(refId) => {
              setOpenRef(`${msg.messageId}#${refId}`);
            }}
          />
        </div>
      </div>

      <div>
        {references.map((ref) => {
          const doc = msg.documents[ref.documentId]!;
          return (
            <ChatReferencePreviewDialog
              key={ref.key}
              messageParts={messageParts}
              document={doc}
              reference={ref}
              isOpen={openRef === ref.key}
              onOpenChange={(newOpen) => {
                if (newOpen === true) {
                  setOpenRef(ref.key);
                } else if (openRef === ref.key) {
                  setOpenRef(null);
                }
              }}
            />
          );
        })}
      </div>

      {!isUser && (
        <div className="my-2 flex justify-between items-center">
          <div className="flex gap-1 items-center">
            {msg.usage > 0 && (
              <Tooltip text={`${Math.ceil(msg.usage / 1000)} credits`}>
                <CoinsIcon className="button-icon text-dark-08" />
              </Tooltip>
            )}
          </div>

          <ChatMessageActions messageId={msg.messageId} />
        </div>
      )}
    </div>
  );
};

export interface IChatPageProps {
  chatId: string;
  isNew: boolean;
  initialCollectionFilters: string[];
  initialDocumentFilters: string[];
}

const ChatPage: React.FC<IChatPageProps> = (props) => {
  const { isNew, chatId, initialCollectionFilters, initialDocumentFilters } = props;
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const {
    state: chatState,
    sendMessage,
    setFilters,
    filterNodeIds,
  } = useChat(
    useMemo(() => {
      return {
        chatId: nullthrows(chatId, 'chatId not defined'),
        isNewChat: isNew,
        filters: {
          selectedCollectionIds: initialCollectionFilters,
          selectedDocumentIds: initialDocumentFilters,
        },
      };
    }, [chatId, isNew, initialCollectionFilters, initialDocumentFilters]),
  );
  const [openRef, setOpenRef] = useState<string | null>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [pendingMessage, setPendingMessage] = useState('');

  const messages = chatState.messages;
  useEffect(() => {
    if (messages.length) {
      setSearchParams(
        (prev) => {
          prev.delete('new');
          return prev;
        },
        { replace: true },
      );
    }
  }, [messages.length]);

  const lastMessage = messages[messages.length - 1];
  const lastMessageId = lastMessage?.messageId;
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo(0, chatContainerRef.current.scrollHeight);
    }
  }, [lastMessageId]);

  const { team } = useTeam();
  const handleMessageSendAction = () => {
    const lastMessage = chatState.messages[chatState.messages.length - 1];
    if (lastMessage && !lastMessage.isFinished) {
      toast.error('Already generating a message');
      return;
    }

    const trimmedPendingMessage = pendingMessage.trim();
    if (trimmedPendingMessage.length > 0) {
      sendMessage(trimmedPendingMessage);
    }
    setPendingMessage('');
  };

  const firstMessage = messages[0];
  const title = firstMessage?.content ? `Chat - ${firstMessage.content}` : 'Chat';

  const [containerRef, containerSize] = useSize<HTMLDivElement>();
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="h-full">
        <div className="flex justify-between items-center mb-4">
          <Breadcrumb
            items={[
              {
                name: 'Chat',
              },
            ]}
          />

          <div className="flex gap-4">
            <LinkButton to="../history">History</LinkButton>
            <LinkButton variant="primary" to="../new">
              New Chat
            </LinkButton>
          </div>
        </div>

        <DocumentPickerDialog
          initialSelection={filterNodeIds}
          open={showFilterDialog}
          onOpenChange={setShowFilterDialog}
          multiSelect={true}
          onSubmit={(selectedNodes) => {
            setFilters({
              selectedCollectionIds: selectedNodes.map((v) => v.collection?.id).filter(Boolean) as string[],
              selectedDocumentIds: selectedNodes.map((v) => v.document?.id).filter(Boolean) as string[],
            });
            setShowFilterDialog(false);
          }}
        />

        <div className="grid gap-4">
          <div ref={containerRef}>
            {messages.length > 0 ? (
              <div className="rounded card grid gap-4" ref={chatContainerRef}>
                {messages.map((msg) => {
                  return (
                    <ChatMessageComponent key={msg.messageId} msg={msg} openRef={openRef} setOpenRef={setOpenRef} />
                  );
                })}
              </div>
            ) : (
              <div className="card" style={{ height: '28rem' }}>
                {chatState.isFetching ? (
                  <SpinnerBlock message="Loading chat session..." />
                ) : (
                  <div className="h-full flex flex-col justify-center items-center gap-4 py-16">
                    <div>
                      <img
                        src="/static/illustrations/conversation-started.svg"
                        alt="Start a conversation"
                        className="w-64 h-64"
                      />
                    </div>
                    <div className="font-medium">Ask a question to start a new conversation.</div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="h-24"></div>

          <div
            className="bg-gray-100 rounded fixed p-2"
            style={{
              left: containerSize.x,
              bottom: 15,
              width: containerSize.width,
            }}
          >
            <div className="flex justify-end items-end gap-2 mb-2">
              <div className="w-full">
                <BaseTextArea
                  placeholder={
                    chatState.isFetching
                      ? 'Loading...'
                      : messages.length > 1
                        ? 'Ask follow up...'
                        : 'Start a conversation...'
                  }
                  rows={1}
                  className={classNames('w-full overflow-hidden resize-none', {
                    'animate-pulse': chatState.isFetching,
                  })}
                  autoComplete="off"
                  disabled={chatState.isFetching}
                  value={pendingMessage}
                  onChange={(evt) => {
                    setPendingMessage(evt.currentTarget.value);
                  }}
                  onInput={(evt) => {
                    // @ts-ignore
                    evt.target.style.height = 'auto';

                    // @ts-ignore
                    const scrollHeight: number = evt.target.scrollHeight;
                    if (scrollHeight <= MAX_INPUT_HEIGHT) {
                      // @ts-ignore
                      evt.target.style.height = scrollHeight + 'px';
                    } else {
                      // @ts-ignore
                      evt.target.style.height = MAX_INPUT_HEIGHT + 'px';
                      // @ts-ignore
                      evt.target.style.overflow = 'auto';
                    }
                  }}
                  onKeyDown={(evt) => {
                    if (evt.key === 'Enter' && evt.shiftKey === false) {
                      evt.preventDefault();
                      evt.stopPropagation();

                      handleMessageSendAction();
                    }
                  }}
                />
              </div>
              <div>
                <Button
                  onTrigger={() => {
                    setShowFilterDialog(true);
                  }}
                  variant="default"
                >
                  <div className="relative">
                    <FilterIcon className="button-icon" />
                    {filterNodeIds.length > 0 && (
                      <CircleDotIcon
                        className="w-2 h-2 text-red-800 fill-red-800 absolute"
                        style={{ right: -5, top: -4 }}
                      />
                    )}
                  </div>
                </Button>
              </div>
              <div>
                <Button
                  onTrigger={() => {
                    handleMessageSendAction();
                  }}
                  variant="primary"
                >
                  <SendIcon className="button-icon" />
                </Button>
              </div>
            </div>

            <div className="text-xs text-dark-08">The chat can make mistakes, make sure to verify answers.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ChatDocumentsPage = () => {
  const { chatId: _chatId } = useParams<{ chatId: string }>();
  const [searchParams] = useSearchParams();
  const initialCollectionFilters = useMemo(() => {
    return searchParams.get('collections')?.split(';') ?? [];
  }, [searchParams.get('collections')]);
  const initialDocumentFilters = useMemo(() => {
    return searchParams.get('documents')?.split(';') ?? [];
  }, [searchParams.get('documents')]);

  const isNewChat = searchParams.get('new') === 'true';
  const chatId = nullthrows(_chatId, 'chatId not defined');
  return (
    <ChatPage
      chatId={chatId}
      isNew={isNewChat}
      initialCollectionFilters={initialCollectionFilters}
      initialDocumentFilters={initialDocumentFilters}
    />
  );
};
