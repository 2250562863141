import React, { useState } from 'react';
import { CircleHelpIcon } from 'lucide-react';

import { BaseButton } from '../button/BaseButton';
import { Button, ButtonProps } from '../button/Button';
import { DialogContent, DialogRoot } from './Dialog';

export interface IConfirmDialogProps {
  triggerSize?: ButtonProps['size'];
  triggerText?: React.ReactNode;
  triggerVariant?: ButtonProps['variant'];
  title: string;
  children?: React.ReactNode;
  submitText?: string;
}

export const HelpDialog: React.FC<IConfirmDialogProps> = (props) => {
  const {
    triggerSize = 6,
    triggerText = <CircleHelpIcon className="w-4 h-4" />,
    triggerVariant = 'ghost',
    title,
    submitText = 'Ok',
    children,
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <DialogRoot open={open} onOpenChange={setOpen}>
      <Button
        size={triggerSize}
        variant={triggerVariant}
        title={title}
        onTrigger={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();

          setOpen(true);
        }}
      >
        {triggerText}
      </Button>

      <DialogContent className="dialog-content">
        <h1 className="heading-one mb-4">{title}</h1>

        <div className="mb-4">{children}</div>

        <div className="flex justify-end">
          <div>
            <BaseButton
              variant="primary"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                setOpen(false);
              }}
            >
              {submitText}
            </BaseButton>
          </div>
        </div>
      </DialogContent>
    </DialogRoot>
  );
};
