import useSWR from 'swr';

import { CartesianGrid, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, LineChart } from 'recharts';
import { CardDataField } from '../../../components/DataField';
import { formatBytes } from '../../../utils/text';
import { useTeam } from '@/app/team/context/TeamContext';
import { PieChartWithTable } from '../../../components/charts/PieChartWithTable';
import { SpinnerBlock } from '../../../components/Spinner';
import type { ResponseType as TeamDocumentInsightsResponse } from '../endpoints/TeamDocumentInsightsEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';

export const DocumentInsightsPage: React.FC = () => {
  const { team } = useTeam();
  const { data, isLoading } = useSWR<TeamDocumentInsightsResponse>(
    `/api/v1/insights/team-documents/${team.id}`,
    fetchEndpointData,
  );

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const insights = data?.stats;
  const sortedAddedDocuments = (
    insights?.addedDocuments.sort((a, b) => {
      if (a.year === b.year) {
        return a.month - b.month;
      }

      return a.year - b.year;
    }) ?? []
  ).map((v) => {
    return {
      count: v.count,
      name: `${v.month}/${v.year}`,
    };
  });
  const sortedDocumentsByDate = (
    insights?.documentsByDate.sort((a, b) => {
      if (a.year === b.year) {
        return a.month - b.month;
      }

      return a.year - b.year;
    }) ?? []
  ).map((v) => {
    return {
      count: v.count,
      name: `${v.month}/${v.year}`,
    };
  });
  const documentPerType =
    insights?.documentsByType.map((v) => {
      return {
        value: v.count,
        name: v.type,
      };
    }) ?? [];

  return (
    <div className="page-content">
      <div className="heading-one">Workspace Insights</div>

      {isLoading && !insights ? (
        <SpinnerBlock message="Loading insights..." />
      ) : (
        <>
          <div className="grid grid-cols-4 mt-6 mb-4 gap-4">
            <CardDataField title="Total documents">{insights?.totalDocuments ?? '0'}</CardDataField>
            <CardDataField title="Unverified documents">{insights?.unverified ?? '0'}</CardDataField>
            <CardDataField title="Total storage">{formatBytes(insights?.totalStorage ?? 0)}</CardDataField>
            <CardDataField title="New documents this month">
              {insights?.addedDocuments.find((v) => v.year === currentYear && v.month === currentMonth)?.count ?? '0'}
            </CardDataField>
          </div>

          <div className="grid gap-4">
            {sortedAddedDocuments.length > 0 && (
              <div className="w-full card">
                <div className="text-center font-medium mb-4">Documents added by month</div>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={sortedAddedDocuments}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="count" stroke="#8884d8" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}

            {sortedDocumentsByDate.length > 0 && (
              <div className="w-full card">
                <div className="text-center font-medium mb-4">Documents by month</div>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={sortedDocumentsByDate}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="count" stroke="#8884d8" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}

            {documentPerType.length > 0 && (
              <div className="card">
                <div className="text-center font-medium mb-4">Documents by type</div>
                <PieChartWithTable height={300} data={documentPerType} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
