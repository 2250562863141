import { PencilIcon, PlusIcon, TrashIcon } from 'lucide-react';
import toast from 'react-hot-toast';
import React, { useMemo } from 'react';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { ICategoryNode, useCategories } from '../contexts/CategoriesContext';
import { CategoryFormDialog } from './CategoryFormDialog';
import { getDisplayError } from '../../../utils/get-display-error';
import { useTeam } from '@/app/team/context/TeamContext';
import { Button } from '../../../components/button/Button';
import { CategoryDeleteDialog } from './CategoryDeleteDialog';
import { BodyType as CreateCategoryPayload } from '../endpoints/CreateCategoryEndpoint';
import { BodyType as UpdateCategoryPayload } from '../endpoints/UpdateCategoryEndpoint';
import { BodyType as DeleteCategoryPayload } from '../endpoints/DeleteCategoryEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';

const EMPTY_ARR: string[] = [];

export interface ICategoryNodeProps {
  idx: string;
  parentIdx: string;
  node: ICategoryNode;
  depth: number;
}

const CategoryNode: React.FC<ICategoryNodeProps> = (props) => {
  const { idx, parentIdx, node, depth } = props;

  const { team } = useTeam();
  const { refetch: refetchCategories } = useCategories();

  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [showEditDialog, setShowEditDialog] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const excludedCategoryIds = useMemo(() => {
    return [node.id];
  }, [node.id]);

  const fullIdx = parentIdx ? `${parentIdx}.${idx}` : idx;
  return (
    <div>
      <div className="flex justify-between items-center my-1 border-b border-gray-100 py-1">
        <div>{`${fullIdx} ${node.name}`}</div>
        <div className="flex gap-1">
          <Button
            size={6}
            shape="square"
            onTrigger={() => {
              setShowEditDialog(true);
            }}
          >
            <PencilIcon className="w-4 h-4" />
          </Button>
          <Button
            size={6}
            shape="square"
            onTrigger={() => {
              setShowDeleteDialog(true);
            }}
          >
            <TrashIcon className="w-4 h-4" />
          </Button>
          <Button
            size={6}
            shape="square"
            onTrigger={() => {
              setShowCreateDialog(true);
            }}
          >
            <PlusIcon className="w-4 h-4" />
          </Button>
          <CategoryFormDialog
            key={`${node.id}-${node.name}-${node.parentCategoryId}-create`}
            isOpen={showCreateDialog}
            setIsOpen={setShowCreateDialog}
            title="Create category"
            submitText="Create"
            excludedCategoryIds={EMPTY_ARR}
            initialValues={{
              name: '',
              parentCategoryId: node.id,
            }}
            onSubmit={async (values) => {
              try {
                const payload: CreateCategoryPayload = {
                  teamId: team.id,
                  data: {
                    name: values.name,
                    parentCategoryId: values.parentCategoryId,
                  },
                };
                await fetchEndpointData('/api/v1/category/create', {
                  method: 'POST',
                  body: payload,
                });
                refetchCategories();
                toast.success('Category created');
              } catch (err) {
                toast.error(`Failed to create category: ${getDisplayError(err)}`);
                throw err;
              }
            }}
          />
          <CategoryFormDialog
            key={`${node.id}-${node.name}-${node.parentCategoryId}-edit`}
            excludedCategoryIds={excludedCategoryIds}
            initialValues={{
              name: node.name,
              parentCategoryId: node.parentCategoryId,
            }}
            isOpen={showEditDialog}
            setIsOpen={setShowEditDialog}
            title="Edit category"
            submitText="Save"
            onSubmit={async (values) => {
              try {
                const payload: UpdateCategoryPayload = {
                  categoryId: node.id,
                  teamId: team.id,
                  data: {
                    name: values.name,
                    parentCategoryId: values.parentCategoryId,
                  },
                };
                await fetchEndpointData('/api/v1/category/update', {
                  method: 'POST',
                  body: payload,
                });
                refetchCategories();
                toast.success('Category updated');
              } catch (err) {
                toast.error(`Failed to update category: ${getDisplayError(err)}`);
                throw err;
              }
            }}
          />
          <CategoryDeleteDialog
            key={`${node.id}-${node.name}-${node.parentCategoryId}-delete`}
            excludedCategoryIds={excludedCategoryIds}
            initialValues={{
              newCategoryId: null,
            }}
            isOpen={showDeleteDialog}
            setIsOpen={setShowDeleteDialog}
            categoryName={node.name}
            onSubmit={async (values) => {
              try {
                const payload: DeleteCategoryPayload = {
                  teamId: team.id,
                  categoryId: node.id,
                  newCategoryId: values.newCategoryId,
                };
                await fetchEndpointData('/api/v1/category/delete', {
                  method: 'DELETE',
                  body: payload,
                });
                refetchCategories();
                toast.success('Category removed');
              } catch (err) {
                toast.error(`Failed to delete category: ${getDisplayError(err)}`);
                throw err;
              }
            }}
          />
        </div>
      </div>

      <div>
        {node.children.map((v, idx) => {
          return <CategoryNode key={v.id} node={v} depth={depth + 1} parentIdx={fullIdx} idx={`${idx + 1}`} />;
        })}
      </div>
    </div>
  );
};

export const CategoriesPage = () => {
  const { team } = useTeam();
  const { categories, categoryTree, refetch: refetchCategories } = useCategories();
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [createKey, setCreateKey] = React.useState(0);

  return (
    <div className="page-content">
      <PageHeader title="Categories" />

      <div>
        <div className="mb-4">
          <Breadcrumb
            items={[
              {
                name: 'Categories',
              },
            ]}
          />
        </div>

        <div>
          <div className="flex justify-between items-center border-b border-gray-100 py-1">
            <div className="font-medium">Name</div>
            <div>
              <Button
                size={6}
                shape="square"
                onTrigger={() => {
                  setShowCreateDialog(true);
                }}
              >
                <PlusIcon className="w-4 h-4" />
              </Button>
              <CategoryFormDialog
                key={`category-create-${createKey}`}
                isOpen={showCreateDialog}
                setIsOpen={setShowCreateDialog}
                title="Create category"
                submitText="Create"
                onSubmit={async (values) => {
                  try {
                    const payload: CreateCategoryPayload = {
                      teamId: team.id,
                      data: {
                        name: values.name,
                        parentCategoryId: values.parentCategoryId,
                      },
                    };
                    await fetchEndpointData('/api/v1/category/create', {
                      method: 'POST',
                      body: payload,
                    });
                    refetchCategories();
                    setCreateKey(Date.now());
                    toast.success('Category created');
                  } catch (err) {
                    toast.error(`Failed to create category: ${getDisplayError(err)}`);
                    throw err;
                  }
                }}
              />
            </div>
          </div>

          <div>
            {!categories.length && <div>No categories found</div>}
            <div>
              {categoryTree.map((v, idx) => {
                return <CategoryNode key={v.id} node={v} depth={0} parentIdx="" idx={`${idx + 1}`} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
