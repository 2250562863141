import { Formik } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { Button } from '../../../components/button/Button';
import { InputField } from '../../../components/input/InputField';
import { PageHeader } from '../../../components/PageHeader';
import { getDisplayError } from '../../../utils/get-display-error';
import { useAuth } from '../../../contexts/auth-context';
import { LanguageSelectField } from '../../../components/LanguageSelect';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { BodyType as UpdateCurrentUserPayload } from '../endpoints/UpdateCurrentUserEndpoint';

const addUserSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  language: Yup.string().required('Vereist'),
});

export const MyProfilePage = () => {
  const { me, logout } = useAuth();

  return (
    <div className="page-content">
      <PageHeader title="My Profile" />

      <div>
        <div className="mb-4 flex justify-between items-center">
          <Breadcrumb
            items={[
              {
                name: 'My Profile',
              },
            ]}
          />

          <div>
            <ConfirmDialog
              triggerText="Log out from all devices"
              title="Log out from all devices"
              submitText="Log out"
              triggerVariant="destructive"
              description={`Are you sure you want to log out from all devices?`}
              onSubmit={async () => {
                try {
                  await fetchEndpointData('/v1/auth/destroy-my-user-sessions');
                  await logout();
                  toast.success('Logged out from all devices');
                } catch (err) {
                  toast.error('Could not log out from all devices: ' + getDisplayError(err));
                }
              }}
            />
          </div>
        </div>

        <div className="card">
          <Formik
            initialValues={{
              name: me.name,
              language: me.language,
            }}
            validationSchema={addUserSchema}
            onSubmit={async (values) => {
              try {
                const payload: UpdateCurrentUserPayload = {
                  data: {
                    name: values.name,
                    language: values.language,
                  },
                };
                await fetchEndpointData('/api/v1/user/me/update', {
                  method: 'POST',
                  body: payload,
                });
                toast.success('Profile has been updated');
              } catch (err: any) {
                toast.error('Could not update user profile: ' + getDisplayError(err));
              }
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <InputField labelText="Name" type="text" name="name" />

                <LanguageSelectField labelText="Language" name="language" />

                <Button variant="primary" type="submit" isDisabled={isSubmitting} isLoading={isSubmitting}>
                  Update Profile
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
