import { useEffect, useMemo, useState } from 'react';
import { BugIcon, ExternalLinkIcon } from 'lucide-react';
import useSWR from 'swr';

import { Button, LinkButton } from '../../../../../../components/button/Button';
import { useAuth } from '../../../../../../contexts/auth-context';
import classNames from '../../../../../../utils/classnames';
import { DialogContent, DialogRoot } from '../../../../../../components/dialog/Dialog';
import { useTeam } from '../../../../../team/context/TeamContext';
import { getDisplayError } from '../../../../../../utils/get-display-error';
import { getHighlightedText } from './highlight-match';
import type { ResponseType as DocumentMatchesResponseType } from '../../../../endpoints/WorkspacePresetRunDocumentMatchesEndpoint';
import { fetchEndpointData } from '../../../../../../utils/fetch.client';

interface IReferenceDialogContentProps {
  documentAnswer: string;
  documentAnswerId: string;
  setSearchParams: (newParams: string) => void;
}

const ReferenceDialogContent: React.FC<IReferenceDialogContentProps> = (props) => {
  const { documentAnswer, documentAnswerId, setSearchParams } = props;
  const { me } = useAuth();
  const { data, isLoading, error } = useSWR<DocumentMatchesResponseType>(
    `/api/v1/workspace/preset-run-document-matches/${documentAnswerId}`,
    fetchEndpointData,
  );

  const matches = useMemo(() => {
    return data?.documentMatches ?? [];
  }, [data]);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    const mostRelevant = [...matches].sort(
      (a, b) => (b.answerSimilarity ?? b.questionSimilarity) - (a.answerSimilarity ?? a.questionSimilarity),
    )[0];
    if (mostRelevant) {
      if (mostRelevant.documentChunk.page) {
        searchParams.set('pageNumber', mostRelevant.documentChunk.page.toString());
      }

      if (mostRelevant.documentChunk.pageRef) {
        searchParams.set('pageRef', mostRelevant.documentChunk.pageRef);
      }
    }
    setSearchParams(searchParams.toString());
  }, [matches]);

  const topSimilarity = useMemo(() => {
    return Math.max(
      ...matches.map((m) => {
        return m.answerSimilarity ?? 0;
      }),
    );
  }, [matches]);
  const similarityTreshold = Math.max(topSimilarity - 0.1, 0.35);

  useEffect(() => {
    if (similarityTreshold < 0.1) {
      return;
    }

    const topChunk = matches.find((v) => (v.answerSimilarity ?? 0) >= similarityTreshold);
    if (topChunk) {
      setTimeout(() => {
        const element = window.document.getElementById(`dialog-chunk-${topChunk.id}`);
        if (element) {
          element.scrollIntoView();
        }
      }, 50);
    }
  }, [matches]);

  if (!matches.length) {
    if (isLoading) {
      return <div className="text-gray-400">Loading...</div>;
    }

    if (error) {
      return <div className="text-red-500">Error loading data: {getDisplayError(error)}</div>;
    }

    return <div>No relevant data found in this document.</div>;
  }

  return (
    <div>
      {matches
        .sort((a, b) => a.documentChunk.chunkIdx - b.documentChunk.chunkIdx)
        .map((m) => {
          const showDebugInfo = me.isSuperUser && !m.isExpansion;
          const isRelevant = similarityTreshold <= (m.answerSimilarity ?? 0);

          const highlightPieces = getHighlightedText(m.documentChunk.content, documentAnswer);
          return (
            <div
              key={m.id}
              id={`dialog-chunk-${m.id}`}
              className={classNames('whitespace-pre-line my-2 border-l-2 pl-2', {
                'text-dark-500 border-blue-200': isRelevant,
                'border-transparent': !isRelevant,
              })}
            >
              <div>
                {highlightPieces.map((v, idx) => {
                  return (
                    <div key={`${m.id}-${idx}`}>
                      <span
                        className={classNames({
                          'bg-yellow-300': v.isHighlighted,
                        })}
                      >
                        {v.value}
                      </span>
                    </div>
                  );
                })}
              </div>
              {showDebugInfo && (
                <div className="flex gap-1 font-medium text-xs text-gray-600 mt-1">
                  <BugIcon className="w-4 h-4" />
                  <div>{`Original match, question similarity: ${Math.round(m.questionSimilarity * 100)}%, answer similarity: ${Math.round((m.answerSimilarity ?? 0) * 100)}%`}</div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export interface IReferenceDialogProps {
  documentAnswer: string;
  documentAnswerId: string;
  document: {
    id: string;
    name: string;
    collectionId: string;
  };
  isOpen: boolean;
  onOpenChange: (newOpen: boolean) => void;
}

export const ReferenceDialog: React.FC<IReferenceDialogProps> = (props) => {
  const { documentAnswer, documentAnswerId, document, isOpen, onOpenChange } = props;
  const [searchParams, setSearchParams] = useState('');
  const { team } = useTeam();

  return (
    <DialogRoot open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="dialog-content flex flex-col">
        <div className="overflow-y-auto">
          <h1 className="heading-one mb-4">{document.name}</h1>

          <ReferenceDialogContent
            documentAnswer={documentAnswer}
            documentAnswerId={documentAnswerId}
            setSearchParams={setSearchParams}
          />
        </div>

        <div className="flex justify-between mt-4">
          <LinkButton
            variant="primary"
            to={`/app/t/${team.id}/documents/${document.collectionId}/${document.id}?${searchParams}`}
            iconLeft={<ExternalLinkIcon className="button-icon" />}
          >
            Open Document
          </LinkButton>

          <Button
            onTrigger={() => {
              onOpenChange(false);
            }}
          >
            Close
          </Button>
        </div>
      </DialogContent>
    </DialogRoot>
  );
};
