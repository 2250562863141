// Core viewer
import { Viewer, Worker } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import workerUrl from 'pdfjs-dist/build/pdf.worker?url';

export interface IPdfPreviewProps {
  fileUrl: string;
  title: string;
  page?: number | null;
}

export const PdfPreview: React.FC<IPdfPreviewProps> = (props) => {
  const { fileUrl, page } = props;

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <Worker workerUrl={workerUrl}>
      <div
        style={{
          height: '100vh',
        }}
      >
        <Viewer initialPage={page ?? undefined} fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]} />
      </div>
    </Worker>
  );
};
