import classNames from '../utils/classnames';

export interface IProgressBarProps {
  color: 'green' | 'yellow' | 'red' | 'blue';
  height: 2;
  progress: number;
  total: number;
  pulse?: boolean;
}

export const ProgressBar: React.FC<IProgressBarProps> = (props) => {
  const { color, height, progress, total, pulse } = props;

  const percentage = progress > total ? 100 : Math.round((progress / total) * 100);
  return (
    <div className="bg-gray-100" style={{ height, width: '100%' }}>
      <div
        className={classNames('rounded-full', {
          'bg-green-500': color === 'green',
          'bg-yellow-500': color === 'yellow',
          'bg-red-500': color === 'red',
          'bg-blue-500': color === 'blue',
          'animate-pulse': pulse ?? percentage < 100,
        })}
        style={{ height, width: `${percentage}%` }}
      ></div>
    </div>
  );
};
