import * as React from 'react';
import { Navigate } from 'react-router-dom';
import useSWR from 'swr';

import createContext from './create-context';
import type { ResponseType as MyTeamsResponse } from '../app/team/endpoints/MyTeamsEndpoint';
import type { ResponseType as MeResponse } from '../app/user/endpoints/MeEndpoint';
import { fetchEndpointData } from '../utils/fetch.client';

interface ProviderValue {
  me: NonNullable<MeResponse['me']>;
  myTeams: NonNullable<MyTeamsResponse['teams']>;
  logout: () => Promise<void>;
  refreshMe: () => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface AuthProviderProps {
  children?: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const {
    data: meData,
    isLoading: isLoadingMe,
    mutate: mutateMe,
  } = useSWR<MeResponse>('/api/v1/user/me', fetchEndpointData);
  const {
    data: myTeamsData,
    isLoading: isLoadingTeams,
    mutate: mutateMyTeams,
  } = useSWR<MyTeamsResponse>('/api/v1/team/my-teams', fetchEndpointData);

  const refreshMe = React.useCallback(() => {
    console.log('refreshMe');
    mutateMe(undefined, {
      revalidate: true,
    });
    mutateMyTeams(undefined, {
      revalidate: true,
    });
  }, [mutateMe, mutateMyTeams]);

  const logout = React.useCallback(async () => {
    window.location.href = new URL('/api/auth/logout', window.location.origin).toString();
  }, []);

  const me = meData?.me;
  const myTeams = myTeamsData?.teams ?? [];

  if (isLoadingMe || isLoadingTeams) {
    return null;
  }

  if (!me) {
    return <Navigate to="/login" />;
  } else {
    return <ReactProvider value={{ me, myTeams, logout, refreshMe }}>{children}</ReactProvider>;
  }
};

export const useAuth = useContext;
export const Consumer = ReactConsumer;
