export interface IDataFieldProps {
  title: string;
  children: React.ReactNode;
}

export const DataField: React.FC<IDataFieldProps> = (props) => {
  const { title, children } = props;

  return (
    <div>
      <div className="label-text py-1 w-full">{title}</div>
      <div>{children}</div>
    </div>
  );
};

export const CardDataField: React.FC<IDataFieldProps> = (props) => {
  const { title, children } = props;

  return (
    <div className="card">
      <div className="text-md font-medium leading-none py-1 w-full">{title}</div>
      <div>{children}</div>
    </div>
  );
};
