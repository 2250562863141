import { Link } from 'react-router-dom';
import useSWR from 'swr';

import { useAuth } from '../../../contexts/auth-context';
import { formatDateTime } from '../../../utils/date';
import { useTeam } from '@/app/team/context/TeamContext';
import { LinkButton } from '../../../components/button/Button';
import { SpinnerBlock } from '../../../components/Spinner';
import type { ResponseType as ChatsResponseType } from '../../document/endpoints/ChatsEndpoint';

export const TeamHomePage = () => {
  const { me } = useAuth();
  const { team } = useTeam();
  const { data: previousChatsData, isLoading: isLoadingChats } = useSWR<ChatsResponseType>(
    `/api/v1/chat/list?teamId=${team.id}&take=10`,
  );

  const previousChats = previousChatsData?.chats ?? [];
  const isLoading = isLoadingChats && !previousChats.length;
  return (
    <div className="page-content">
      <div className="heading-one">{`Welcome back ${me.name}`}</div>

      <div className="mt-4 mb-6">Here is some of your last work to help you pick up where you left of.</div>

      {isLoading ? (
        <SpinnerBlock message="Loading..." className="h-screen" />
      ) : (
        <div className="grid gap-4 mb-8">
          <div className="card">
            <div className="flex justify-between items-center mb-2">
              <div className="heading-two">Recent Chats</div>
              <div>
                <LinkButton variant="default" to={`/app/t/${team.id}/chat/new`}>
                  New chat
                </LinkButton>
              </div>
            </div>

            {previousChats.length > 0 ? (
              <div>
                {previousChats.map((v) => {
                  return (
                    <Link
                      to={`/app/t/${team.id}/chat/${v.id}`}
                      className="flex justify-between hover:bg-gray-200 p-1 -ml-1 rounded cursor-pointer gap-4"
                      key={v.id}
                    >
                      <div>{v.title ?? v.id}</div>
                      <div className="whitespace-nowrap">{formatDateTime(v.createdAt)}</div>
                    </Link>
                  );
                })}
              </div>
            ) : (
              <div>
                <div className="my-2">No recent chats found</div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
