// !!! This is used on server and client side !!!

export enum ExplorerEntryType {
  Collection = 'Collection',
  Document = 'Document',
}

export function getExplorerEntryType(value: string): ExplorerEntryType {
  switch (value.trim().toLowerCase()) {
    case 'collection':
      return ExplorerEntryType.Collection;
    case 'document':
      return ExplorerEntryType.Document;
    default:
      throw new Error(`Unknown ExplorerEntryType: ${value}`);
  }
}
